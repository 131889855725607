//@flow
import * as React from "react";
import { connect } from "react-redux";
//import CurrencyInput from 'react-currency-input';
//import CurrencyInput from 'react-currency-input-field'
import { Label } from "reactstrap";
import { objectsConstants } from "../../constants/objects.constants";
import { ToolTipHelp } from "../Utils/ToolTipHelp";
import CurrencyInput from "./CurrencyInput";

type Props = {
  name: string,
  label: string,
  onChange: any,
  size?: number,
  required: boolean,
  messageValidate?: Array<any>,
  value?: any,
  messages?: any
};

type State = {
  value?: any
};

class MoneyInput extends React.PureComponent<Props, State> {
  static defaultProps = {
    required: false,
    value: null,
    placeholder: "0,00"
  };

  constructor(props: Props) {
    super(props);
    this.state = { value: props.value };
  }

  onlyMoney = valueWithPrefix => {
    if (valueWithPrefix) {
      return valueWithPrefix.replace("R$ ", "");
    }
    return valueWithPrefix;
  };

  render() {
    let colSize = "col";
    if (this.props.size) {
      colSize += "-" + this.props.size;
    }

    const { messageValidate, name, alertMensagem } = this.props;

    let mensagemAlert;
    if (alertMensagem) {
      let tipoGuia = objectsConstants.TIPOS_GUIA.find(element => { if (element.label === localStorage.getItem("tipoGuia")) return element; });

      mensagemAlert = alertMensagem.find(element =>
        this.props.name.toLowerCase().includes(element.campoAtendimentoTipoGuia.campoAtendimento.nomeCampo.toLowerCase())
        && this.props.label.toLowerCase().includes(element.campoAtendimentoTipoGuia.campoAtendimento.label.toLowerCase())
        && tipoGuia.id == element.tipoGuia.id);
    }

    let error;

    if (messageValidate && messageValidate.length > 0) {
      error = messageValidate.find(item => {
        return item.fieldName === name;
      });
    }
    let value =
      this.props.value === 0 || this.props.value === ""
        ? null
        : this.props.value;
    return (
      <div className={`${this.props.className}`}>
        {this.props.label && (
          <React.Fragment>
            <Label
              for={this.props.name}
              className="label-nowrap"
              title={this.props.label}
            >
              {this.props.label}
              {this.props.required && "*"}
            </Label>{" "}
            {mensagemAlert && (
              <React.Fragment>
                <i className="icon-exclamation" id={'help_' + mensagemAlert.id} />
                <ToolTipHelp
                  target={'help_' + mensagemAlert.id}
                  message={mensagemAlert.mensagemAlerta}
                />
              </React.Fragment>
            )}
            {this.props.helpText && (
              <React.Fragment>
                <i className="icon-duvida" id={"help_" + this.props.id} />
                <ToolTipHelp
                  target={"help_" + this.props.id}
                  message={this.props.helpText}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
        <CurrencyInput
          id={this.props.id}
          name={this.props.name}
          value={value}
          onChange={e => {
            const { name, type, value, checked } = e.currentTarget;
            this.props.onChange(name, this.onlyMoney(value), type, checked);
          }}
          required={this.props.required}
          placeholder={
            this.props.placeholder === "" ||
              this.props.placeholder === undefined
              ? this.props.label
              : this.props.placeholder
          }
          onBlur={this.props.onBlur}
          disabled={this.props.disabled ? this.props.disabled : false}
          className="form-control"
        >
          {this.props.children}
        </CurrencyInput>

        {error && (
          <div className="invalid-feedback">
            {this.props.messages
              ? this.props.messages[error.message.code]
              : "Erro."}
          </div>
        )}
      </div>
    );
  }
}

function number(v) {
  //Remove tudo o que não é dígito
  v = v.replace(/\D/g, "");
  return v;
}

function mapStateToProps(state) {
  const { messages } = state.fieldMessage;
  const { alert } = state;

  return {
    messages,
    alert
  };
}

export default connect(mapStateToProps)(MoneyInput);
