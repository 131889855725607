import { Can } from "@casl/react";
import { Formik } from "formik";
import React, { PureComponent } from "react";
import { objectsConstants } from "../../../constants/objects.constants";
import BtnLink from "../../../components/Buttons/BtnLink";
import _ from "lodash";
import { messages } from "../../../helpers/message.helper";
import { connect } from "react-redux";
import { Collapse } from "reactstrap";
import FormInput from "../../../components/Inputs/FormInput";
import InputViewEdit from "../../../components/Inputs/InputViewEdit";
import { FormGroup } from "../../../components/FormGroup";
import { ConfirmAcationService } from "../../../services/ConfirmAcationService";
import { convenioService } from "../../../services/convenio.service";
import SelectInput from "../../../components/Inputs/SelectInput";
import TipoIntegracaoSelectInput from "../../../sascComponents/inputs/TipoIntegracaoSelectInput";
import { userService } from "../../../services/user.service";

class IntegracaoConvenioForm extends PureComponent<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            collapse: true,
            viewMode: true,
        };
    }

    getIntegracaoObjectDefault = () => {
        return this.props.convenio &&
            this.props.convenio.listIntegracaoConvenio &&
            this.props.convenio.listIntegracaoConvenio.length > 0
            ? {
                listIntegracaoConvenio: this.props.convenio.listIntegracaoConvenio
            }
            : { listIntegracaoConvenio: [{}] }
    }

    toggle = () => {
        this.setState(state => ({ collapse: !state.collapse }));
    };

    cancelEditMode = (e, setValues) => {
        e.stopPropagation();
        ConfirmAcationService.confirmMsg(
            () => {
                this.confirmCancel(setValues);
            },
            `Cancelar Edição `,
            "Você confirma que deseja cancelar a edição das integrações?",
            null,
            "Não",
            "Sim",
            objectsConstants.TYPE_CANCEL
        );
    };

    confirmCancel = setValues => {
        this.setState({ viewMode: true, collapse: true }, () => {
            setValues(this.getIntegracaoObjectDefault());
        });
    };

    salvar = e => {
        e.stopPropagation();
        this.setState({ viewMode: true, collapse: true });
    };

    editMode = e => {
        this.setState({ viewMode: false, collapse: true });
    };

    addIntegracao = (setFieldValues, values) => {
        let listIntegracaoConvenio = _.cloneDeep(values.listIntegracaoConvenio);
        listIntegracaoConvenio.push({ idKey: new Date().getTime() });
        setFieldValues("listIntegracaoConvenio", listIntegracaoConvenio);
    };

    removeIntegracao = (setFieldValues, values, index) => {
        let listIntegracaoConvenio = _.cloneDeep(values.listIntegracaoConvenio);
        listIntegracaoConvenio.splice(index, 1);
        setFieldValues("listIntegracaoConvenio", listIntegracaoConvenio);
    };

    submitForm = (values, { setSubmitting, setValues }) => {
        this.props.loading(true);
        convenioService
            .saveIntegracoes(
                values.listIntegracaoConvenio,
                this.props.convenio.id
            ).then(
                response => {
                    this.props.success({
                        message: `Integrações foram salvas com sucesso!`
                    });
                    this.props.loading(false);
                    this.setState({ viewMode: true, collapse: true }, () => {
                        this.props.loadConvenio();
                        setValues({ listIntegracaoConvenio: response.data });
                    });
                },
                errors => {
                    this.props.error({
                        message:
                            "Não foi possível salvar as Integrações."
                    });
                    this.props.loading(false);
                }
            );
    }

    render() {
        const tipoPerfil = this.props.user.perfilAtual.perfil.tipoPerfil;
        return (
            <React.Fragment>
                {userService.isSudo(this.props.user) || tipoPerfil === objectsConstants.ADMINISTRADOR  &&              
                <Formik
                    validateOnBlur={false}
                    validateOnChange={false}
                    enableReinitialize={true}
                    initialValues={this.getIntegracaoObjectDefault()}
                    onSubmit={this.submitForm}
                    ref={form => {
                        this.formRef = form;
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        validateForm,
                        setValues
                    }) => (
                        <form>
                            <div
                                className={`mt-4 accordion ${this.state.collapse ? "open" : ""
                                    }`}
                            >
                                <div className="card content-form cardGuia cardCrud">
                                    <div
                                        className="card-header d-flex align-items-center flex-column flex-sm-row b-bottom"
                                        id="headingOne"
                                        onClick={this.toggle}
                                    >
                                        <div className="flex-grow-1 d-flex align-items-center">
                                            <div className="p-1 status-guia">Integração com o convênio</div>
                                        </div>
                                        <div className="buttons-right ml-auto">
                                            {this.state.viewMode && (
                                                <Can
                                                    I={objectsConstants.ALTERAR}
                                                    a={objectsConstants.EDITAR_CONVENIO}
                                                    ability={this.props.permissions}
                                                >
                                                    <BtnLink
                                                        type={"button"}
                                                        className={""}
                                                        icone={"icon-btn-editar"}
                                                        title={"Editar"}
                                                        onSubmit={e => {
                                                            e.stopPropagation();
                                                            this.editMode();
                                                        }}
                                                        disabled={false}
                                                    />
                                                </Can>
                                            )}
                                            {!this.state.viewMode && (
                                                <Can
                                                    I={objectsConstants.ALTERAR}
                                                    a={objectsConstants.EDITAR_CONVENIO}
                                                    ability={this.props.permissions}
                                                >
                                                    <button
                                                        type={"button"}
                                                        className={"btn btn-secondary btn-pequeno mr-3"}
                                                        onClick={e => {
                                                            this.cancelEditMode(e, setValues);
                                                        }}
                                                    >
                                                        Cancelar
                                                    </button>
                                                    <button
                                                        type={"button"}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            validateForm(values).then(errors => {
                                                                if (_.isEmpty(errors)) {
                                                                    handleSubmit(values, { setValues });
                                                                } else {
                                                                    this.props.error({
                                                                        message:
                                                                            messages.ERRO_CRIAR_CRONOGRAMA_CONVENIO
                                                                    });
                                                                }
                                                            });
                                                        }}
                                                        className={"btn btn-primary btn-pequeno mr-2"}
                                                        icone={"icon-btn-check-black"}
                                                    >
                                                        Salvar
                                                    </button>
                                                </Can>
                                            )}
                                        </div>
                                        <span
                                            className={`accordion ${this.state.collapse ? "icon-traco" : "icon-seta-baixo"
                                                }`}
                                        ></span>
                                    </div>
                                    <Collapse isOpen={this.state.collapse}>
                                        <div
                                            id="guiaUm"
                                            className="form-inside collapse show"
                                            aria-labelledby="headingOne"
                                            data-parent="#guiaUm"
                                        >
                                            <React.Fragment>
                                                <div className="row section-form">
                                                    <h6 className="w-100">Integração</h6>
                                                    {values &&
                                                        values.listIntegracaoConvenio.map((integracao, index) => {
                                                            return (
                                                                <React.Fragment>
                                                                    {index > 0 ? <div className="quebra"></div> : ""}
                                                                    <FormGroup className="">
                                                                        <InputViewEdit
                                                                            component={TipoIntegracaoSelectInput}
                                                                            label={"Tipo de Integração"}
                                                                            name={`listIntegracaoConvenio[${index}].tipoIntegracao`}
                                                                            id={`listIntegracaoConvenio[${index}].tipoIntegracao`}
                                                                            placeholder="Selecione as Integrações"
                                                                            onChange={(name, value) => {
                                                                                let possuiItem = false
                                                                                values.listIntegracaoConvenio.forEach(integracao => {
                                                                                    if (integracao.tipoIntegracao && integracao.tipoIntegracao.name == value.name) {
                                                                                        possuiItem = true;
                                                                                        this.props.warning({ message: "Esse tipo de integração já foi adicionado." })
                                                                                    }
                                                                                })
                                                                                if(!possuiItem){
                                                                                    setFieldValue(name, value);
                                                                                }
                                                                            }}
                                                                            returnFullObject={true}
                                                                            value={integracao.tipoIntegracao}
                                                                            tiposIntegracao={values.listIntegracaoConvenio}
                                                                            className="sasc-select"
                                                                            erroMensagem={errors.integracao}
                                                                            viewMode={this.state.viewMode}
                                                                            defaultValue={integracao.tipoIntegracao && integracao.tipoIntegracao.description}
                                                                        ></InputViewEdit>
                                                                    </FormGroup>
                                                                    <FormGroup className="">
                                                                        <InputViewEdit
                                                                            label={"Link da API"}
                                                                            component={FormInput}
                                                                            onChange={(name, date) => {
                                                                                setFieldValue(name, date);
                                                                            }}
                                                                            name={`listIntegracaoConvenio[${index}].urlRequisicao`}
                                                                            placeholder=""
                                                                            type={"text"}
                                                                            viewMode={this.state.viewMode}
                                                                            value={values.listIntegracaoConvenio[index].urlRequisicao}
                                                                            erroMensagem={""}
                                                                            required={false}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup className="">
                                                                        <InputViewEdit
                                                                            label={"Usuario"}
                                                                            component={FormInput}
                                                                            onChange={(name, date) => {
                                                                                setFieldValue(name, date);
                                                                            }}
                                                                            name={`listIntegracaoConvenio[${index}].usuario`}
                                                                            placeholder=""
                                                                            type={"text"}
                                                                            viewMode={this.state.viewMode}
                                                                            value={integracao.usuario}
                                                                            erroMensagem={""}
                                                                            required={false}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup className="">
                                                                        <InputViewEdit
                                                                            label={"Senha"}
                                                                            component={FormInput}
                                                                            onChange={(name, date) => {
                                                                                setFieldValue(name, date);
                                                                            }}
                                                                            name={`listIntegracaoConvenio[${index}].senha`}
                                                                            placeholder="Digite a senha"
                                                                            type={"text"}
                                                                            viewMode={this.state.viewMode}
                                                                            value={integracao.senha}
                                                                            defaultValue={"********"}
                                                                            erroMensagem={""}
                                                                            required={false}
                                                                        />
                                                                    </FormGroup>
                                                                    <FormGroup className="">
                                                                        <InputViewEdit
                                                                            label={"Status"}
                                                                            component={SelectInput}
                                                                            name={`listIntegracaoConvenio[${index}].status`}
                                                                            id={`listIntegracaoConvenio[${index}].status`}
                                                                            onChange={(name, date) => {
                                                                                setFieldValue(name, date);
                                                                            }}
                                                                            options={[
                                                                                { name: "ATIVO", description: "Ativo" },
                                                                                { name: "INATIVO", description: "Inativo" }
                                                                            ]}
                                                                            returnFullObject={true}
                                                                            labelKey={"description"}
                                                                            valueKey={"name"}
                                                                            placeholder="Selecione o status"
                                                                            viewMode={this.state.viewMode}
                                                                            value={integracao.status}
                                                                            defaultValue={integracao.status && integracao.status.description}
                                                                        />
                                                                    </FormGroup>
                                                                    {!this.state.viewMode && (
                                                                        <FormGroup className="central fixo">
                                                                            <BtnLink
                                                                                type={"button"}
                                                                                className={""}
                                                                                icone={"icon-btn-excluir"}
                                                                                title={"Excluir"}
                                                                                onSubmit={() => {
                                                                                    this.removeIntegracao(
                                                                                        setFieldValue,
                                                                                        values,
                                                                                        index
                                                                                    );
                                                                                }}
                                                                                disabled={false}
                                                                            />
                                                                        </FormGroup>
                                                                    )}
                                                                </React.Fragment>
                                                            )
                                                        })}
                                                </div>
                                                {!this.state.viewMode && (
                                                    <Can
                                                        I={objectsConstants.ALTERAR}
                                                        a={objectsConstants.CRUD_ATENDIMENTOS_CRIAR}
                                                        ability={this.props.permissions}
                                                    >
                                                        <div className="d-flex justify-content-end align-items-center mb-2  mt-2">
                                                            <button
                                                                type="button"
                                                                className="btn btn-light"
                                                                onClick={() => {
                                                                    this.addIntegracao(setFieldValue, values);
                                                                }}
                                                            >
                                                                <span className="icon-btn-add"></span>
                                                                Adicionar
                                                            </button>
                                                        </div>
                                                    </Can>
                                                )}
                                            </React.Fragment>
                                        </div>
                                    </Collapse>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
                }
            </React.Fragment>


        )
    }
}


const mapDispatch = ({
    menu: { showMenu, hideMenu },
    load: { loading },
    alert: { success, error, clear, warning }
}) => ({
    showMenu: () => showMenu(),
    hideMenu: () => hideMenu(),
    success: msg => success(msg),
    loading: (load: boolean) => loading({ load }),
    error: msg => error(msg),
    clear: () => clear(),
    warning: msg => warning(msg),
});

function mapStateToProps(state) {
    const { loggedIn, user, permissions } = state.authentication;
    const { alert } = state;

    return {
        loggedIn,
        alert,
        user,
        permissions
    };
}
export default connect(
    mapStateToProps,
    mapDispatch
)(IntegracaoConvenioForm);