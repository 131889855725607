// @flow

import React from "react";
import { connect } from "react-redux";
import { FormGroup } from "../../components/FormGroup";
import InputViewEdit from "../../components/Inputs/InputViewEdit";
import { pendenciaService } from "../../services/pendencia.service";
import { objectsConstants } from "../../constants/objects.constants";
import _ from "lodash";

type Props = {
  label: string,
  name: string,
  value: any,
  className: any
};

class SelectableInput extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  dipatchCampoAtual = () => {
    const {
      campoSelecionado,
      pendenciaOpen,
      idAtendimentoAtual,
      atendimento,
      name,
      permissions,
      edicaoEmMassaOpen,
      idAtendimentoParaEditar
    } = this.props;
    let atendimentoPendencia = this.getPendenciaInAtendimento(
      atendimento,
      name
    );

    //está em modo de criação de pendência
    if (this.props.viewMode) {
      if (
        pendenciaOpen &&
        idAtendimentoAtual === atendimento.id &&
        campoSelecionado.nomeCampo !== this.props.name &&
        !atendimentoPendencia
        && permissions.can(objectsConstants.ALTERAR, objectsConstants.CRUD_PENDENCIA_CRIAR)
      ) {

        this.props.campoAtual({
          campoSelecionado: {
            nomeCampo: this.props.name,
            value: this.props.value,
            label: this.props.label,
            myComponent: this.getChildrenInput()
          }
        });
      } //pendencia já existente no campo
      else if (atendimentoPendencia && !atendimentoPendencia.atendimentoPendenciaAnexos) {
        //carrega os dados completos de pendencias com anexos e notificaçoes
        this.props.loading(true);
        pendenciaService.doGet(atendimentoPendencia.id).then(
          response => {
            //coloca informaçoes do campo no objeto atual
            atendimentoPendencia.label = this.props.label;
            atendimentoPendencia.value = this.props.value;
            atendimentoPendencia.myComponent = this.getChildrenInput();
            if (!pendenciaOpen) {
              this.props.togglePendencia();
            }
            this.props.loading(false);
            let pendenciaDB = response.data.data;
            //add anexos e notificacoes na pendencia
            atendimentoPendencia.atendimentoPendenciaNotificacao =
              pendenciaDB.atendimentoPendenciaNotificacao;
            atendimentoPendencia.atendimentoPendenciaAnexos =
              pendenciaDB.atendimentoPendenciaAnexos;
            this.props.campoAtual({
              campoSelecionado: atendimentoPendencia
            });
            this.props.atendimentoAtual(atendimento);
          },
          error => {
            console.error(error);
            this.props.loading(false);
            this.props.error({
              message: "Não foi possível buscar pendência desse campo."
            });
          }
        );

      }

      //edição em massa
      if (
        edicaoEmMassaOpen &&
        idAtendimentoParaEditar === atendimento.id &&
        campoSelecionado.nomeCampo !== this.props.name
      ) {
        this.props.campoAtual({
          campoSelecionado: {
            nomeCampo: this.props.name,
            value: this.props.value,
            label: this.props.label,
            myComponent: this.getChildrenInput()
          }
        });

      }
    }
  };


  getChildrenInput() {
    const childrenWithProps = React.Children.map(this.props.children, child => {
      if (child.type === InputViewEdit) {
        let newchild = _.omit(child, '_owner');
        return React.cloneElement(newchild);
      }
      return null;
    });

    return childrenWithProps[0] || null;
  }


  getPendenciaInAtendimento(atendimento, name) {
    if (atendimento && atendimento.atendimentoPendencias) {
      for (let i = 0; i < atendimento.atendimentoPendencias.length; i++) {
        if (atendimento.atendimentoPendencias[i].nomeCampo === name)
          return atendimento.atendimentoPendencias[i];
      }
    }
    return null;
  }

  getClassPendencia = () => {
    const { atendimento, name, campoSelecionado } = this.props;
    let classItem = "";
    let atendimentoPendencia = this.getPendenciaInAtendimento(
      atendimento,
      name
    );
    if (atendimentoPendencia || (campoSelecionado.nomeCampo === this.props.name &&
      (!campoSelecionado.hasOwnProperty('situacao') || !campoSelecionado.hasOwnProperty('descricaoPendencia')))) {
      classItem = "campoPendente action";
    }

    return classItem;
  };

  render() {
    const { pendenciaOpen } = this.props;
    return (

      <FormGroup
        className={`${this.props.className} ${this.getClassPendencia()} ${pendenciaOpen && "action"}`}
        name={this.props.name}
        onClick={this.dipatchCampoAtual}
      >
        {this.props.children}
      </FormGroup>
    );
  }
}

const mapDispatch = ({
  alert: { success, error },
  load: { loading },
  pendencia: { togglePendencia },
  selectableInput: { campoAtual, atendimentoAtual },
}) => ({
  success: msg => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: msg => error(msg),
  togglePendencia: () => togglePendencia(),
  atendimentoAtual: atendimento => atendimentoAtual(atendimento),
  campoAtual: campoSelecionado => campoAtual(campoSelecionado)
});

function mapStateToProps(state) {
  const { pendenciaOpen, atendimentoPendencias } = state.pendencia;
  const { permissions } = state.authentication;
  const { edicaoEmMassaOpen, idAtendimentoParaEditar } = state.edicaoEmMassa;// trazendo o do redux
  const { campoSelecionado, atendimentoAtual, idAtendimentoAtual } = state.selectableInput;


  return {
    pendenciaOpen,
    campoSelecionado,
    idAtendimentoAtual,
    atendimentoPendencias,
    permissions,
    edicaoEmMassaOpen, //retornando pra dentro do state
    idAtendimentoParaEditar,
    atendimentoAtual
  };
}

export default connect(mapStateToProps, mapDispatch)(SelectableInput);
