import _ from "lodash";
import moment from "moment/moment";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormGroup } from "../../../components/FormGroup";
import DatePickerInput from "../../../components/Inputs/DatePickerInput";
import DateTimePicker from "../../../components/Inputs/DateTimePicker";
import FormInput from "../../../components/Inputs/FormInput";
import FormSelectInput from "../../../components/Inputs/FormSelectInput";
import InputViewEdit from "../../../components/Inputs/InputViewEdit";
import MoneyInput from "../../../components/Inputs/MoneyInput";
import SelectInput from "../../../components/Inputs/SelectInput";
import { cooperativa, enumTipoAvisos, objectsConstants } from "../../../constants/objects.constants";
import { dateHelper } from "../../../helpers/date.helper";
import { formatterHelper } from "../../../helpers/formatter.helper";
import SelectableInput from "../../../sascComponents/inputs/SelectableInput";
import { atendimentoService } from "../../../services/atendimento.service";
import { convenioService } from "../../../services/convenio.service";
import { procedimentoService } from "../../../services/procedimento.service";
import { regimeService } from "../../../services/regime.service";
import { tecnicaService } from "../../../services/tecnica.service";
import { viaAcessoService } from "../../../services/viaAcesso.service";
import { regraProcedimnetoBloqueadosGuiaHospitalService } from "../../../services/RegrasProcedimentosBloqueadosGuiaHospital.service";

class DadosExecucaoGuiaForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }

  getValorTotal = indexExecucao => {
    const { values } = this.props;
    let valorTotal = 0;
    if (
        values.atendimentoExecucaos[indexExecucao].valorUnitario &&
        values.atendimentoExecucaos[indexExecucao].fator &&
        values.atendimentoExecucaos[indexExecucao].quantidade
    ) {
      valorTotal =
          values.atendimentoExecucaos[indexExecucao].valorUnitario *
          values.atendimentoExecucaos[indexExecucao].fator *
          values.atendimentoExecucaos[indexExecucao].quantidade;
    }
    return formatterHelper.formatMoney(valorTotal, 2, ",", ".");
  };
  getOptions = size => {
    let arrOptions = [];
    for (let index = 1; index <= size; index++) {
      arrOptions.push({ label: index, value: index });
    }
    return arrOptions;
  };

  addProcedimento = () => {
    const { values, setValues } = this.props;
    let newValues = _.cloneDeep(values);
    let numero = newValues.atendimentoProcedimentos.length;
    let procedimento = { numero: 1 };
    if (values.atendimentoProcedimentos && values.atendimentoProcedimentos.length > 0) {
      procedimento = values.atendimentoProcedimentos[numero - 1];
    }

    this.copiaHoraProcedimento(newValues, numero,procedimento.codigoProcedimento);
    setValues(newValues);
  };


  removeProcedimento = indexProcedimento => {
    const { values, setValues } = this.props;
    let newValues = _.cloneDeep(values);
    let procedimentoExecucao = newValues.atendimentoExecucaos[indexProcedimento];
    newValues.atendimentoExecucaos.splice(indexProcedimento, 1);
    //remove execucao relacionada
    _.remove(newValues.identificacaoAtendimentoExecucao, { numero: procedimentoExecucao.numero });
    //se remover todos os elementos add um padrão
    if (!newValues.identificacaoAtendimentoExecucao.length) {
      newValues.identificacaoAtendimentoExecucao = [{
        numero: 1,
        codigoCbo: null,
        grau: "",

      }]
    }
    //reordena lista de execucao para bater index
    //reordena lista de execucao para bater index
    newValues.identificacaoAtendimentoExecucao.map((identificacao, indexIdentificacao) => {
      if (indexProcedimento < identificacao.numero) {
        identificacao.numero = identificacao.numero - 1
      }
      //identificacao.numero = indexIdentificacao + 1;
    })
    //reordena lista de dados execucao para bater index
    newValues.atendimentoExecucaos.map((atendimentoExecucao, indexIdentificacao) => {
      if (indexProcedimento < atendimentoExecucao.numero) {
        atendimentoExecucao.numero = atendimentoExecucao.numero - 1
      }
      //atendimentoExecucao.numero = indexIdentificacao + 1;
    })
    newValues.atendimentoProcedimentos.map((atendimentoExecucao, indexIdentificacao) => {
      atendimentoExecucao.numero = indexIdentificacao + 1;
    })


    setValues(newValues);

    this.props.clear()
    this.props.info({
      message: `Procedimento removida com sucesso!`
    });
  };

  verificaRetorno = (name, date) => {
    if(this.props.values.type != objectsConstants.GUIASADT)
    this.props.verificaRetorno(`tipoConsulta`, name, date)
  };

  copiaHoraProcedimento(newValues, numero,codigoProcedimento) {
    return newValues.atendimentoExecucaos.push({
      idList: new Date().getTime(),
      numero,
      type: objectsConstants.ENUM_TIPOS_GUIA.SADT,
      horaInicial: newValues.atendimentoExecucaos[0].horaInicial,
      horaFinal: newValues.atendimentoExecucaos[0].horaFinal,
      quantidade: newValues.atendimentoExecucaos[0].quantidade,
      dataAtendimento: newValues.atendimentoExecucaos[0].dataAtendimento,
      codigoProcedimento,
      via: newValues.atendimentoExecucaos[0].via,
      tec: newValues.atendimentoExecucaos[0].tec,
      fator: newValues.atendimentoExecucaos[0].fator,
    });

  }

  alocarQuantidade = (values, date) => {
    let quant = null;
    values.convenio.listRegrasProcedimentoTipoGuia.forEach(r => {
      if(values.type.includes(r.tipoGuia.nome)) 
        if(r.procedimentos.some(p => p.codigo === date.codigo)) {
          if(quant === null || quant < r.quantidade)
            quant = r.quantidade;
        }
    });
    return quant;
  }

  validarProcedimento = (values, codigo) => {
    if(values.convenio && values.convenio.listRegrasProcedimentoTipoGuia) {
      let validacao = values.convenio.listRegrasProcedimentoTipoGuia.some(r => {
        if(values.type.includes(r.tipoGuia.nome))
          return r.procedimentos.some(p => p.codigo === codigo);
        return false;
      })
      return validacao;
    }
  }

  

  verificaDataAtendimento = (values, index) =>{
    if(!values.atendimentoExecucaos[index].dataAtendimento){
      _.set(values,"dataAtendimento",new Date());
        return values.atendimentoExecucaos[index].dataAtendimento = new Date();
    }else{
      _.set(values,"dataAtendimento",values.atendimentoExecucaos[index].dataAtendimento);
      return values.atendimentoExecucaos[index].dataAtendimento;
    }
  }
  verificaDataFinalAtendimento = (values, index) =>{
    if(!values.atendimentoExecucaos[index].dataFinalAtendimento && values.cooperativa.sigla != cooperativa.FELICOOP){
        return values.atendimentoExecucaos[index].dataFinalAtendimento = new Date();
    }else{
      return values.atendimentoExecucaos[index].dataFinalAtendimento;
    }
  }

  sendToStorange = (values) => {
    try {
      values.rascunho && localStorage.setItem('rascunho', JSON.stringify(values))
    } catch (error) {
      if (error instanceof DOMException && (
        error.code === 22 ||
        error.code === 1014 ||
        error.name === 'QuotaExceededError' ||
        error.name === 'NS_ERROR_DOM_QUOTA_REACHED'
      )) {
        console.error('Limite de armazenamento local atingido');
      } else {
        console.error('Erro ao salvar no armazenamento local:', error.message);
      }
    }
  }

  verificaBloqueioProcedimentoGuiaHospital = (codigo) =>{
      let codigoGuia;
      objectsConstants.TIPOS_GUIA.forEach(element => {
        if(this.props.values.type == element.label){
          codigoGuia = element.id;
        }
      });
      return regraProcedimnetoBloqueadosGuiaHospitalService.getRegraProcedimnetoBloqueadosGuiaHospital(this.props.values.convenio.id, 
        codigoGuia, this.props.values.hospitalLocal.id, codigo);
    }


  render() {
    const {
      permissions,
      setFieldValue,
      values,
      handleChange,
      viewMode,
      errors,
      setValues
    } = this.props;

    if(values.atendimentoExecucaos==null || values.atendimentoExecucaos.length==0){
      const { values, setFieldValue, setValues } = this.props;
      let newValues = _.cloneDeep(values);

      for (let listprocedimentos = 0; listprocedimentos < newValues.atendimentoProcedimentos.length; listprocedimentos++) {
          newValues.atendimentoExecucaos.push({ 
          codigoProcedimento: newValues.atendimentoProcedimentos[listprocedimentos].codigoProcedimento,
          dataAtendimento: new Date(),
          dataFinalAtendimento: new Date(),
          descricao: null,
          fator: 1,
          horaFinal: null,
          horaInicial: null,
          id: new Date().getTime(),
          numero: newValues.atendimentoProcedimentos[listprocedimentos].numero,
          quantidade: null,
          tabela: 22,
          tec: null,
          type: "guiaSADT",
          valorTotal: 0,
          valorUnitario: 0,
          via: null
          })

    }
      setValues(newValues)
    }
  

    return (
        <React.Fragment>
          <div className="row section-form">
            <h6 className="w-100">
              Dados da Execução / Procedimento e Exames Realizados
            </h6>

            {values &&
            values.atendimentoExecucaos &&
            values.atendimentoExecucaos.map((atendimentoExecucao, indexExecucao) => {
              let numeroAtendimentoExecucao = _.get(atendimentoExecucao, `numero`);
              numeroAtendimentoExecucao = numeroAtendimentoExecucao ? numeroAtendimentoExecucao : 1;
              let procedimento = { numero: 1 };
              if (values.atendimentoProcedimentos && values.atendimentoProcedimentos.length > 0) {
                procedimento = values.atendimentoProcedimentos[numeroAtendimentoExecucao - 1];
              }
              //pega o index do procedimento selecionado
              if ((!procedimento || !procedimento.codigoProcedimento) && _.get(values, 'convenio.procedimentoPadraoGuiaSadt') != null) {
                var date = _.get(values, 'convenio.procedimentoPadraoGuiaSadt');
                setFieldValue(
                    `atendimentoExecucaos[${indexExecucao}].procedimento`,
                    date
                );
                setFieldValue(
                    `atendimentoExecucaos[${indexExecucao}].codigoProcedimento`,
                    date.codigo
                );
                setFieldValue(
                    `atendimentoExecucaos[${indexExecucao}].descricao`,
                    date.descricao
                );
                setFieldValue(`atendimentoProcedimentos[${indexExecucao}].procedimento`, date);
                setFieldValue(`atendimentoProcedimentos[${indexExecucao}].codigoProcedimento`, date.codigo);
                setFieldValue(`atendimentoProcedimentos[${indexExecucao}].descricao`, date.descricao);
              }
              let valueSelect = {
                label:
                    _.get(values, `atendimentoExecucaos[${indexExecucao}].numero`, 1)
                ,
                value: _.get(values, `atendimentoExecucaos[${indexExecucao}].numero`, 1)
              };

              return (
                  <React.Fragment key={indexExecucao + 1}>
                    {indexExecucao > 0 ? <div className="quebra"></div> : ""}
                    <FormGroup className="central fixo">
                      <InputViewEdit
                          label="Nº"
                          component={SelectInput}
                          onChange={(name, date) => {
                            let indeChanged = date.value - 1;
                            let atendimentoProcedimento =
                                values.atendimentoProcedimentos[indeChanged];
                            setFieldValue(
                                `atendimentoExecucaos[${indeChanged}].atendimentoProcedimento`,
                                atendimentoProcedimento
                            );
                            setFieldValue(
                                name,
                                date.value
                            );
                          }}
                          onBlur={() => this.sendToStorange(values)}
                          options={this.getOptions(values.atendimentoProcedimentos ? values.atendimentoProcedimentos.length : 0)}
                          id={`atendimentoExecucaos${indexExecucao}numero`}
                          name={`atendimentoExecucaos[${indexExecucao}].numero`}
                          erroMensagem={_.get(errors, `atendimentoExecucaos[${indexExecucao}].numero`)}
                          placeholder="Selecione o procedimento"
                          viewMode={viewMode}
                          value={valueSelect}
                          defaultValue={atendimentoExecucao.numero}
                          labelKey={"label"}
                          valueKey={"value"}
                          returnFullObject={true}
                          required
                      />
                    </FormGroup>
                    <SelectableInput viewMode={viewMode} className="fixo"
                                     atendimento={values}
                                     name={`atendimentoExecucaos[${indexExecucao}].dataAtendimento`}
                                     label={"Data atendimento"}
                                     value={
                                       values.atendimentoExecucaos[indexExecucao] &&
                                       dateHelper.format(
                                           values.atendimentoExecucaos[indexExecucao]
                                               .dataAtendimento,
                                           { mode: "DATE" }
                                       )
                                     }
                    >
                      <InputViewEdit
                          label="Data atendimento"
                          component={DatePickerInput}
                          onChange={(name, date) => {
                            setFieldValue(name, date);
                            this.verificaRetorno(name, date);
                          }}
                          defaultValue={
                            values.atendimentoExecucaos[indexExecucao]
                                ? dateHelper.format(
                                values.atendimentoExecucaos[indexExecucao]
                                    .dataAtendimento,
                                { mode: "DATE" }
                                )
                                : "--"
                          }
                          id={`atendimentoExecucaos${indexExecucao}dataAtendimento`}
                          mask={"99/99/9999"}
                          name={`atendimentoExecucaos[${indexExecucao}].dataAtendimento`}
                          placeholder=""
                          viewMode={viewMode}
                          type={"text"}
                          value={this.verificaDataAtendimento(values, indexExecucao)}
                          erroMensagem={
                            _.get(errors, `atendimentoExecucaos[${indexExecucao}].dataAtendimento`)
                          }
                          required={true}
                          onBlur={this.verificaRetorno}
                          alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
                      />
                    </SelectableInput>

                    <SelectableInput viewMode={viewMode} className="fixo"
                                     atendimento={values}
                                     name={`atendimentoExecucaos[${indexExecucao}].dataFinalAtendimento`}
                                     label={"Data Final do Atendimento"}
                                     value={
                                       values.atendimentoExecucaos[indexExecucao] &&
                                       dateHelper.format(
                                           values.atendimentoExecucaos[indexExecucao]
                                               .dataFinalAtendimento,
                                           { mode: "DATE" }
                                       )
                                     }
                    >
                      <InputViewEdit
                          label="Data Final do Atendimento"
                          component={DatePickerInput}
                          onChange={(name, date) => {
                            setFieldValue(name, date);
                            this.verificaRetorno(name, date);
                          }}
                          defaultValue={
                            values.atendimentoExecucaos[indexExecucao]
                                ? dateHelper.format(
                                values.atendimentoExecucaos[indexExecucao]
                                    .dataFinalAtendimento,
                                { mode: "DATE" }
                                )
                                : "--"
                          }
                          id={`atendimentoExecucaos${indexExecucao}dataFinalAtendimento`}
                          mask={"99/99/9999"}
                          name={`atendimentoExecucaos[${indexExecucao}].dataFinalAtendimento`}
                          placeholder=""
                          viewMode={viewMode}
                          type={"text"}
                          value={
                            this.verificaDataFinalAtendimento(values, indexExecucao)
                          }
                          erroMensagem={
                            _.get(errors, `atendimentoExecucaos[${indexExecucao}].dataFinalAtendimento`)
                          }
                          required={false}
                          onBlur={this.verificaRetorno}
                          alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
                      />
                    </SelectableInput>

                    <SelectableInput viewMode={viewMode} className="central fixo6"
                                     atendimento={values}
                                     name={`atendimentoExecucaos[${indexExecucao}].horaInicial`}
                                     label={"Hora início"}
                                     value={values.atendimentoExecucaos[indexExecucao]
                                      && values.atendimentoExecucaos[indexExecucao].horaInicial
                                      && dateHelper.format(values.atendimentoExecucaos[indexExecucao].horaInicial, { mode: "TIME" }) 
                                    }
                    >
                      <InputViewEdit
                          label="Hora início"
                          component={DateTimePicker}
                          defaultValue={values.atendimentoExecucaos[indexExecucao].horaInicial ? moment(values.atendimentoExecucaos[indexExecucao].horaInicial).format("HH:mm") : "--"
                          }
                          name={`atendimentoExecucaos[${indexExecucao}].horaInicial`}
                          id={`atendimentoExecucaos${indexExecucao}horaInicial`}
                          erroMensagem={
                            _.get(errors, `atendimentoExecucaos[${indexExecucao}].horaInicial`)
                          }
                          value={
                            values.atendimentoExecucaos[indexExecucao].horaInicial && moment(values.atendimentoExecucaos[indexExecucao].horaInicial)
                          }
                          onChange={(name, value) => setFieldValue(name, moment(value).format("YYYY-MM-DDTHH:mm:ss"))}
                          noSize={true}
                          className={"destaque"}
                          viewMode={viewMode}
                          showTimeSelect={true}
                          showTimeSelectOnly={true}
                          disabled={values&&values.hospitalLocal&&values.hospitalLocal.disableHoraInicioFimExecucao}
                          required={!(values&&values.hospitalLocal&&values.hospitalLocal.disableHoraInicioFimExecucao)}
                          onBlur={this.verificaRetorno}
                          alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
                      />
                    </SelectableInput>
                    <SelectableInput viewMode={viewMode} className="central fixo6"
                                     atendimento={values}
                                     name={`atendimentoExecucaos[${indexExecucao}].horaFinal`}
                                     label={"Hora final"}
                                     value={values.atendimentoExecucaos[indexExecucao]
                                        && values.atendimentoExecucaos[indexExecucao].horaFinal
                                        && dateHelper.format(values.atendimentoExecucaos[indexExecucao].horaFinal, { mode: "TIME" }) 
                                      }
                    >
                      <InputViewEdit
                          label="Hora final"
                          component={DateTimePicker}
                          name={`atendimentoExecucaos[${indexExecucao}].horaFinal`}
                          value={
                            values.atendimentoExecucaos[indexExecucao]
                            && values.atendimentoExecucaos[indexExecucao].horaFinal 
                            && moment(values.atendimentoExecucaos[indexExecucao].horaFinal)
                          }
                          onChange={(name, value) => setFieldValue(name, moment(value).format("YYYY-MM-DDTHH:mm:ss"))}
                          noSize={true}
                          defaultValue={
                            values.atendimentoExecucaos[indexExecucao].horaFinal
                                ? moment(values.atendimentoExecucaos[indexExecucao].horaFinal).format("HH:mm")
                                : "--"
                          }
                          onBlur={() => this.sendToStorange(values)}
                          viewMode={viewMode}
                          showTimeSelect={true}
                          showTimeSelectOnly={true}
                          disabled={values&&values.hospitalLocal&&values.hospitalLocal.disableHoraInicioFimExecucao}
                          required={!(values&&values.hospitalLocal&&values.hospitalLocal.disableHoraInicioFimExecucao)}
                          id={`atendimentoExecucaos${indexExecucao}horaFinal`}
                          erroMensagem={_.get(errors, `atendimentoExecucaos[${indexExecucao}].horaFinal`)}
                          alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
                      />
                    </SelectableInput>
                    <SelectableInput viewMode={viewMode} className="central fixo"
                                     atendimento={values}
                                     name={`atendimentoExecucaos[${indexExecucao}].tabela`}
                                     label={"Tabela"}
                                     value={
                                       _.get(procedimento, "tabela")
                                     }
                    >
                      <InputViewEdit
                          label="Tabela"
                          component={FormInput}
                          onChange={(name, date) => {
                            setFieldValue(name, date);
                          }}
                          onBlur={() => this.sendToStorange(values)}
                          name={`atendimentoExecucaos[${indexExecucao}].tabela`}
                          placeholder=""
                          viewMode={viewMode}
                          type={"text"}
                          value={
                            _.get(procedimento, "tabela")
                          }
                          alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
                      />
                    </SelectableInput>
                    <SelectableInput viewMode={viewMode} className="large"
                                     atendimento={values}
                                     name={`atendimentoExecucaos[${indexExecucao}].codigoProcedimento`}
                                     label={"Código do procedimento"}
                                     value={
                                       {
                                         codigo:
                                             _.get(procedimento, 'codigoProcedimento')
                                       }
                                     }
                    >
                      <InputViewEdit
                          label="Código do procedimento"
                          component={SelectInput}
                          onChange={async (name, date) => {
                            if(values.hospitalLocal){
                              await this.verificaBloqueioProcedimentoGuiaHospital(date.codigo).then((response) => {
                                if(response.data > 0){
                                  this.props.warning({ message: enumTipoAvisos.PROCEDIMENTO_BLOQUEADO_GUIA_HOSPITAL });
                                }})
                            }
                            let newValue = _.cloneDeep(values);
                            const {
                                 type,
                                 convenio,
                                 cooperado,
                                 identificacaoAtendimentoExecucao,
                                 cooperativa,
                                 atendimentoExecucaos
                            } = values;
                            if (type && convenio && ((cooperado && cooperado.id) || (identificacaoAtendimentoExecucao[0].cooperado && identificacaoAtendimentoExecucao[0].cooperado.id))) {
                              const listProcedimentos = [];
                              if (date && date.codigo) {
                                listProcedimentos.push(date.codigo);
                              }
                              const idCooperado = cooperado && cooperado.id ? cooperado.id : identificacaoAtendimentoExecucao[0].cooperado.id;
                             await convenioService.findRegraEspecialidadeProcedimentoPorTipoGuia(type, convenio, idCooperado, listProcedimentos)
                                .then(response => {
                                  const { viaAcesso, grau } = response.data;                                    
                                  viaAcesso && _.set(newValue,`atendimentoExecucaos[${numeroAtendimentoExecucao - 1}].via`, viaAcesso.descricao);
                                  grau && _.set(newValue, `identificacaoAtendimentoExecucao[${numeroAtendimentoExecucao - 1}].grau`, grau.grau);
                                 
                                })
                                .catch(error => {
                                  console.error("Error:", error);
                                });
                            }
                            setFieldValue(`atendimentoProcedimentos[${numeroAtendimentoExecucao-1}].procedimento`, date);
                            setFieldValue(`atendimentoProcedimentos[${numeroAtendimentoExecucao-1}].codigoProcedimento`, date.codigo);
                            setFieldValue(`atendimentoProcedimentos[${numeroAtendimentoExecucao-1}].descricao`, date.descricao);

                            if( type != null && date.codigo != null){
                              _.map(newValue.atendimentoExecucaos,(ae,index)=>{
                                if((ae.numero===(numeroAtendimentoExecucao))||(!ae.numero&&numeroAtendimentoExecucao===1)){
                                  if(date && date.procedimentoCooperativaList&&date.procedimentoCooperativaList.length>0&&date.procedimentoCooperativaList[0].quantidadePadrao){
                                    _.set(newValue,`atendimentoExecucaos[${index}].quantidade`, date.procedimentoCooperativaList[0].quantidadePadrao);
                                  }
                                  _.set(newValue,`atendimentoExecucaos[${index}].procedimento`,date);
                                  _.set(newValue,`atendimentoExecucaos[${index}].codigoProcedimento`,date.codigo);
                                  _.set(newValue,`atendimentoExecucaos[${index}].descricao`, date.descricao);
                                  if(values.cooperativa && values.cooperativa.sufixProcedimentoObrigatoriedadeGrau12) {
                                    if (date.codigo.startsWith(values.cooperativa.sufixProcedimentoObrigatoriedadeGrau12)) {
                                      _.set(newValue,`atendimentoExecucaos[${index}].via`, null);
                                    }
                                  }
                                }
                              })
                              _.map(newValue.identificacaoAtendimentoExecucao,(iae,index)=>{
                                if((iae.numero===(numeroAtendimentoExecucao))||(!iae.numero&&numeroAtendimentoExecucao===1)){
                                  _.set(newValue, `identificacaoAtendimentoExecucao[${index}].codigoProcedimento`,date.codigo);

                                if(cooperativa && cooperativa.sufixProcedimentoObrigatoriedadeGrau12){
                                  if(date.codigo.startsWith(cooperativa.sufixProcedimentoObrigatoriedadeGrau12)) {
                                    _.set(newValue, `identificacaoAtendimentoExecucao[${index}].grau`, '12');
                                    _.set(newValue, `identificacaoAtendimentoExecucao[${index}].disabled`, true);
                                  }else{
                                    _.set(newValue,`identificacaoAtendimentoExecucao[${index}].grau`, null);
                                    _.set(newValue,`identificacaoAtendimentoExecucao[${index}].disabled`, false);
                                  }
                                }
                                }
                              })
                              if(date && date.procedimentoCooperativaList&&date.procedimentoCooperativaList.length>0&&date.procedimentoCooperativaList[0].quantidadePadrao){
                                _.set(newValue,`atendimentoProcedimentos[${numeroAtendimentoExecucao-1}].quantAutorizada`, date.procedimentoCooperativaList[0].quantidadePadrao);
                                _.set(newValue,`atendimentoProcedimentos[${numeroAtendimentoExecucao-1}].quantSolicitada`, date.procedimentoCooperativaList[0].quantidadePadrao);
                              }
                              _.set(newValue,`atendimentoProcedimentos[${numeroAtendimentoExecucao-1}].procedimento`,date);
                              _.set(newValue,`atendimentoProcedimentos[${numeroAtendimentoExecucao-1}].codigoProcedimento`,date.codigo);
                              _.set(newValue,`atendimentoProcedimentos[${numeroAtendimentoExecucao-1}].descricao`, date.descricao);

                             
                              if(this.validarProcedimento(values, date.codigo)){
                                const quant = this.alocarQuantidade(values, date);
                                _.set(newValue,`atendimentoExecucaos[${indexExecucao}].quantidade`, quant);
                                _.set(newValue,`atendimentoProcedimentos[${indexExecucao}].quantSolicitada`, quant);
                                _.set(newValue,`atendimentoProcedimentos[${indexExecucao}].quantAutorizada`, quant);

                              }else{
                                _.set(newValue,`atendimentoProcedimentos[${indexExecucao}].quantSolicitada`, "");
                                _.set(newValue,`atendimentoProcedimentos[${indexExecucao}].quantAutorizada`, "");
                                _.set(newValue,`atendimentoExecucaos[${indexExecucao}].quantidade`, "");
                              }

                              if (date.codigo === '10101039' && values.cooperativa.preenchimentoAutomaticoTipoRegimeAtendimento) {
                                await regimeService.findByCodigo(4).then(response => {
                                  if (response.data) {
                                    _.set(newValue, `regimeAtendimento`, response.data);
                                  }
                                })
                                _.set(newValue, `tipoAtendimento`, { "description": "Consulta", "name": "CONSULTA" });
                              }

                              await atendimentoService.findGuiaPadrao(type, convenio, date.codigo).then(response => {
                                const guiaPadrao = response.data;
                                _.forOwn(guiaPadrao, function(value, key) {
                                  if(value) {
                                    if(_.isArray(value)) {
                                      _.forOwn(value[0],function(valueArray, keyArray) {
                                        if(valueArray&&!_.get(newValue, `${key}[${indexExecucao}].${keyArray}`)) {
                                          _.set(newValue, `${key}[${indexExecucao}].${keyArray}`, valueArray);
                                        }
                                      })
                                    }else{
                                      if(!newValue[key]){
                                        _.set(newValue,key, value);
                                      }
                                    }
                                  }
                                });
                                setValues(newValue);
                              }).catch(error => {
                                console.error(error);
                                setValues(newValue);
                              })
                            }else {
                              _.map(atendimentoExecucaos,(ae,index)=>{
                                if((ae.numero===(numeroAtendimentoExecucao))||(!ae.numero&&numeroAtendimentoExecucao===1)){
                                  setFieldValue(`atendimentoExecucaos[${index}].procedimento`,null);
                                  setFieldValue(`atendimentoExecucaos[${index}].codigoProcedimento`,null);
                                  setFieldValue(`atendimentoExecucaos[${index}].descricao`,null);
                                }
                              })
                              _.map(identificacaoAtendimentoExecucao,(iae,index)=>{
                                if((iae.numero===(numeroAtendimentoExecucao))||(!iae.numero&&numeroAtendimentoExecucao===1)){
                                  setFieldValue( `identificacaoAtendimentoExecucao[${index}].codigoProcedimento`,null);
                                }
                              })
                            }
                          }}
                          onBlur={() => this.sendToStorange(values)}
                          name={`atendimentoExecucaos[${indexExecucao}].codigoProcedimento`}
                          placeholder="Digite o código"
                          viewMode={viewMode}
                          value={{
                            codigo:
                                _.get(procedimento, 'codigoProcedimento')
                          }}
                          defaultValue={_.get(procedimento, 'codigoProcedimento', ' ')}
                          //defaultValue={(_.get(values,`atendimentoExecucaos[${indexExecucao}].codigoProcedimento`)?_.get(values,`atendimentoExecucaos[${indexExecucao}].codigoProcedimento`):"--") }
                          labelKey={"codigo"}
                          valueKey={"codigo"}
                          returnFullObject={true}
                          parent={{ convenio: values.convenio, tipoGuia: values.type }}
                          onFetchData={procedimentoService.findByCodigoConvenioTipoGuia}
                          disabled={_.get(values, 'convenio.procedimentoPadraoGuiaSadt') != null}
                          alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
                          noResultsText={"Cooperativa não possui credenciamento para cobrança. Convênio possui locais próprios."}

                      />
                    </SelectableInput>
                    <SelectableInput viewMode={viewMode} className="large"
                                     atendimento={values}
                                     name={`atendimentoExecucaos[${indexExecucao}].descricao`}
                                     label={"Descrição"}
                                     value={
                                       values.atendimentoExecucaos[indexExecucao].descricao
                                     }
                    >
                      <InputViewEdit
                          label="Descrição"
                          component={FormInput}
                          onChange={(name, date) => {
                            setFieldValue(name, date);
                          }}
                          onBlur={() => this.sendToStorange(values)}
                          alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
                          name={`atendimentoExecucaos[${indexExecucao}].descricao`}
                          placeholder=""
                          type={"text"}
                          viewMode={viewMode}
                          value={
                            _.get(procedimento, 'descricao', "")
                          }
                          disabled={
                            _.get(procedimento, 'descricao') &&
                            true
                          }
                      />
                    </SelectableInput>
                    <SelectableInput viewMode={viewMode} className="central fixo"
                                     atendimento={values}
                                     name={`atendimentoExecucaos[${indexExecucao}].quantidade`}
                                     label={"Qtde."}
                                     value={
                                       values.atendimentoExecucaos[indexExecucao]
                                           .quantidade
                                     }
                    >
                      <InputViewEdit
                          label="Qtde."
                          component={FormInput}
                          onChange={(name, date) => {
                            setFieldValue(name, date);
                          }}
                          onBlur={() => this.sendToStorange(values)}
                          id={`atendimentoExecucaos${indexExecucao}quantidade`}
                          name={`atendimentoExecucaos[${indexExecucao}].quantidade`}
                          placeholder=""
                          type={"number"}
                          viewMode={viewMode}
                          value={
                            values.atendimentoExecucaos[indexExecucao]
                                .quantidade
                          }
                          disabled={
                            atendimentoExecucao &&
                            atendimentoExecucao.quantidade &&
                            values.convenio !== undefined &&                            
                            this.validarProcedimento(values, procedimento.codigoProcedimento) &&
                            true
                          }
                          required
                          erroMensagem={
                            _.get(errors, `atendimentoExecucaos[${indexExecucao}].quantidade`)
                          }
                          alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
                      />
                    </SelectableInput>
                    <SelectableInput viewMode={viewMode} className="fixo"
                                     atendimento={values}
                                     name={`atendimentoExecucaos[${indexExecucao}].via`}
                                     label={"Via"}
                                     value={values.atendimentoExecucaos[indexExecucao].via}
                    >

                      <InputViewEdit
                          label="Via"
                          component={FormSelectInput}
                          alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
                          service={viaAcessoService.findAll}
                          returnFullObject={false}
                          searchable={true}
                          labelKey={"descricao"}
                          valueKey={"descricao"}
                          onChange={(name, date) => {
                            setFieldValue(name, date);
                          }}
                          onBlur={() => this.sendToStorange(values)}
                          required={values.cooperativa&&values.cooperativa.viaTecnicaObrigatoria&&!(values.cooperativa.sufixProcedimentoObrigatoriedadeGrau12 && _.filter(values.atendimentoExecucaos,(a)=>{ return a.numero==numeroAtendimentoExecucao&&a.codigoProcedimento}).length>0  &&_.filter(values.atendimentoExecucaos,(a)=>{ return a.numero==numeroAtendimentoExecucao&&a.codigoProcedimento})[0].codigoProcedimento.startsWith(values.cooperativa.sufixProcedimentoObrigatoriedadeGrau12))}
                          //disabled={!(values.cooperativa&&values.cooperativa.viaTecnicaObrigatoria&&!(values.cooperativa.sufixProcedimentoObrigatoriedadeGrau12 && _.filter(values.atendimentoExecucaos,(a)=>{ return a.numero==numeroAtendimentoExecucao&&a.codigoProcedimento}).length>0  &&_.filter(values.atendimentoExecucaos,(a)=>{ return a.numero==numeroAtendimentoExecucao&&a.codigoProcedimento})[0].codigoProcedimento.startsWith(values.cooperativa.sufixProcedimentoObrigatoriedadeGrau12)))}
                          erroMensagem={
                            _.get(errors, `atendimentoExecucaos[${indexExecucao}].via`)
                          }
                          name={`atendimentoExecucaos[${indexExecucao}].via`}
                          id={`atendimentoExecucaos${indexExecucao}via`}
                          placeholder=""

                          viewMode={viewMode}
                          value={values.atendimentoExecucaos[indexExecucao].via}
                      />
                    </SelectableInput>
                    <SelectableInput viewMode={viewMode} className="fixo"
                                     atendimento={values}
                                     name={`atendimentoExecucaos[${indexExecucao}].tec`}
                                     label={"Tec."}
                                     value={values.atendimentoExecucaos[indexExecucao].tec}
                    >
                      <InputViewEdit
                          label="Tec."
                          component={FormSelectInput}
                          service={tecnicaService.findAll}
                          alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
                          returnFullObject={false}
                          searchable={true}
                          labelKey={"descricao"}
                          valueKey={"descricao"}
                          onChange={(name, date) => {
                            setFieldValue(name, date);
                          }}
                          onBlur={() => this.sendToStorange(values)}
                          required={values.cooperativa && values.cooperativa.viaTecnicaObrigatoria}
                          erroMensagem={
                            _.get(errors, `atendimentoExecucaos[${indexExecucao}].tec`)
                          }
                          name={`atendimentoExecucaos[${indexExecucao}].tec`}
                          id={`atendimentoExecucaos${indexExecucao}tec`}
                          placeholder=""
                          type={"text"}
                          viewMode={viewMode}
                          value={values.atendimentoExecucaos[indexExecucao].tec}
                      />
                    </SelectableInput>
                    <SelectableInput viewMode={viewMode} className="central fixo"
                                     atendimento={values}
                                     name={`atendimentoExecucaos[${indexExecucao}].fator`}
                                     label={"Fator"}
                                     value={values.atendimentoExecucaos[indexExecucao].fator}
                    >
                      <InputViewEdit
                          label="Fator"
                          component={FormInput}
                          onChange={(name, date) => {
                            setFieldValue(name, date);
                          }}
                          onBlur={() => this.sendToStorange(values)}
                          name={`atendimentoExecucaos[${indexExecucao}].fator`}
                          placeholder=""
                          type={"number"}
                          viewMode={viewMode}
                          value={
                            values.atendimentoExecucaos[indexExecucao].fator
                          }
                          alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
                      />
                    </SelectableInput>
                    <SelectableInput viewMode={viewMode} className=""
                                     atendimento={values}
                                     name={`atendimentoExecucaos[${indexExecucao}].valorUnitario`}
                                     label={"Valor unit."}
                                     value={
                                       values.atendimentoExecucaos[indexExecucao]
                                           .valorUnitario
                                     }
                                     placeholder="R$0,00"
                    >
                      <InputViewEdit
                          label="Valor unit."
                          component={MoneyInput}
                          onChange={(name, date) => {
                            setFieldValue(name, date);
                          }}
                          onBlur={() => this.sendToStorange(values)}
                          name={`atendimentoExecucaos[${indexExecucao}].valorUnitario`}
                          placeholder="0,00"
                          viewMode={viewMode}
                          value={values.atendimentoExecucaos[indexExecucao]
                              .valorUnitario}
                          defaultValue={formatterHelper.money(
                              values.atendimentoExecucaos[indexExecucao]
                                  .valorUnitario
                          )}
                          alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
                      />
                    </SelectableInput>
                    <FormGroup className="">
                      <label>Valor total</label>
                      <p className="form-view">
                        {this.getValorTotal(indexExecucao)}
                      </p>
                    </FormGroup>
                    {!viewMode && (
                        <React.Fragment>
                          <FormGroup className="central fixo mobile-100">
                            {indexExecucao + 1 !==
                            values.atendimentoExecucaos.length && (
                                <React.Fragment>

                                  <div className="mt-25 btn-acoes d-none d-sm-flex flex-column align-self-center pl-0 pl-sm-3">
                                    <button
                                        className="btn-menos"
                                        onClick={() => {
                                          this.removeProcedimento(indexExecucao);
                                        }}
                                    ></button>
                                  </div>
                                  <div className="bg-branco d-block d-sm-none col-12 p-2 text-center">
                                    <button
                                        className="btn-menos"
                                        onClick={() => {
                                          this.removeProcedimento(indexExecucao);
                                        }}
                                    ></button>
                                  </div>
                                </React.Fragment>
                            )}
                            {indexExecucao + 1 ===
                            values.atendimentoExecucaos.length && (
                                <React.Fragment>
                                  <div className="mt-25 btn-acoes d-none d-sm-flex flex-column align-self-center pl-0 pl-sm-3">
                                    {values.atendimentoExecucaos.length > 1 && <button
                                        className="btn-menos"
                                        onClick={() => {
                                          this.removeProcedimento(indexExecucao);
                                        }}
                                    ></button>}
                                    <button
                                        className="btn-mais"
                                        onClick={this.addProcedimento}
                                    ></button>
                                  </div>
                                  <div className="bg-branco d-block d-sm-none col-12 p-2 text-center">
                                    <button
                                        className="btn-mais"
                                        onClick={this.addProcedimento}
                                    ></button>
                                  </div>
                                </React.Fragment>
                            )}
                          </FormGroup>
                        </React.Fragment>
                    )}


                  </React.Fragment>
              );
            })}
          </div>
        </React.Fragment>
    );
  }
}

const mapDispatch = ({ menu: { showMenu, hideMenu }, alert: { info, clear }, }) => ({
  info: msg => info(msg),
  clear: () => clear(),
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
    mapStateToProps,
    mapDispatch
)(withRouter(DadosExecucaoGuiaForm));
