import { Formik } from 'formik';
import _ from 'lodash';
import React, { PureComponent } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { objectsConstants } from '../../../constants/objects.constants';
import { translate } from '../../../helpers/message.helper';
import iconAtendimento from '../../../img/icon_atendimentos.svg';
import iconeEditar from '../../../img/icon_editar.svg';
import { atendimentoService } from '../../../services/atendimento.service';
import { beneficiarioService } from '../../../services/beneficiario.service';
let download = require('downloadjs');

class EdicaoEmMassaTopo extends PureComponent<Props, State> {
    constructor(props) {
        super(props);
        let atendimento = {
            id: props.idAtendimentoAtual,
            type: objectsConstants.ENUM_TIPOS_GUIA.SADT,
        };
        this.state = {
            filter: {},
            viewMode: false,
            atendimento,
            bloqueioSalvar: false,
            bloqueioSalvarErrors: [],
        };
    }

    toggleAnexos = () => {
        this.setState(({ openAnexo }) => ({ openAnexo: !openAnexo }));
    };

    validarProcedimento = (values, codigo) => {
        if (values.convenio && values.convenio.listRegrasProcedimentoTipoGuia) {
            let validacao = values.convenio.listRegrasProcedimentoTipoGuia.some(
                (r) => {
                    if (values.type.includes(r.tipoGuia.nome))
                        return r.procedimentos.some((p) => p.codigo === codigo);
                    return false;
                }
            );
            return validacao;
        }
    };

    alocarQuantidade = (values, codigo) => {
        let quant = null;
        values.convenio.listRegrasProcedimentoTipoGuia.forEach((r) => {
            if (values.type.includes(r.tipoGuia.nome))
                if (r.procedimentos.some((p) => p.codigo === codigo))
                    if (quant === null || quant < r.quantidade) quant = r.quantidade;
        });
        return quant;
    };

    alocarAcomodacao = (values, codigo) => {
        let acomodacao = null;
        values.convenio.listRegrasProcedimentoTipoGuia.forEach((r) => {
            if (values.type.includes(r.tipoGuia.nome))
                if (r.procedimentos.some((p) => p.codigo === codigo)) {
                    if (acomodacao === null || acomodacao !== r.acomodacao)
                        acomodacao = r.acomodacao;
                }
        });
        return acomodacao;
    };

    confirmEdicao = (onConfirm, campoSelecionado, setFieldValue) => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="confirm-ui css-alert-sasc">
                        <img src={iconeEditar} alt="icon" />
                        <h2>{'CONFIMAR EDIÇÃO'}</h2>
                        <p>
                            <b>{campoSelecionado.label}</b>
                        </p>

                        {this.props.listaAtendimentos.length > 0 && (
                            <p>
                                Ao confirmar a edição, as alterações feitas serão aplicadas para
                                todos os itens selecionados
                            </p>
                        )}
                        {this.props.listaAtendimentos.length === 0 && (
                            <p>
                                Ao confirmar a edição, as alterações feitas seram aplicadas para
                                todos os itens deste lote
                            </p>
                        )}

                        <p className="font-bold pt-2">
                            Você tem certeza que deseja fazer esta edição?
                        </p>
                        <button
                            className="btn btn-secondary white mt-3 mb-4 mx-2"
                            onClick={() => {
                                onClose();
                            }}
                        >
                            Cancelar
                        </button>
                        {
                            <button
                                className="btn btn-primary white mt-3 mb-4 mx-2"
                                onClick={() => {
                                    onConfirm();
                                    onClose();
                                }}
                            >
                                Sim
                            </button>
                        }
                    </div>
                );
            },
        });
    };

    handleChange = (name: string, value: any) => {
        if (value) {
            if (name.includes('.codigoProcedimento')) {
                this.setState({ name: name, value: value.codigo });
            } else {
                this.setState({ name: name, value: value });
            }
        } else if (_.get(name, 'currentTarget')) {
            const { name, value } = name.currentTarget;
            this.setState({ name: name, value: value });
        }
    };

    render() {
        const { campoSelecionado } = this.props;
        let { atendimento, value, errorMessage } = this.state;
        let _this = this;

        if (_.get(campoSelecionado, 'nomeCampo')) {
            if (campoSelecionado.nomeCampo.includes('.codigoProcedimento')) {
                value = { codigo: value };
            }
            let InputEdicaoEmMassa;
            if (campoSelecionado.myComponent) {
                InputEdicaoEmMassa = React.cloneElement(
                    campoSelecionado.myComponent,
                    {
                        viewMode: false,
                        label: null,
                        onChange: this.handleChange,
                        value,
                        erroMensagem: errorMessage,
                    }
                );
            }
            let _this = this;
            return (
                <React.Fragment>
                    <div className="barra-topo pb-3 p-sm-0 bg-branco rounded d-flex flex-column flex-sm-row align-content-stretch flex-wrap">
                        <React.Fragment>
                            <Formik
                                validateOnBlur={false}
                                validateOnChange={false}
                                enableReinitialize={true}
                                initialValues={atendimento}
                                onSubmit={(values, actions) => {
                                    _this.props.loading(true);

                                    //let requestVO = campoSelecionado;
                                    const { name, value } = this.state;

                                    if (_this.props.listaAtendimentos.length > 0) {
                                        let atendimentos = _.cloneDeep(
                                            _this.props.listaAtendimentos
                                        );
                                        for (let i = 0; i < atendimentos.length; i++) {
                                            let atendimento = atendimentos[i];
                                            if (
                                                name != undefined &&
                                                name.includes('.codigoProcedimento')
                                            ) {
                                                //DEMANDA 0622-000226 -> 0722-000265
                                                if (this.validarProcedimento(atendimento, value)) {
                                                    const posicaoAtendimento = name.slice(
                                                        0,
                                                        name.indexOf('.')
                                                    );
                                                    const posicaoAtendProced =
                                                        'atendimentoProcedimentos' +
                                                        posicaoAtendimento.slice(name.indexOf('['));
                                                    const posicaoAtendExecucao =
                                                        'atendimentoExecucaos' +
                                                        posicaoAtendimento.slice(name.indexOf('['));
                                                    const campoEdicao = name.slice(0, name.indexOf('['));
                                                    let quant = this.alocarQuantidade(atendimento, value);
                                                    let acomodacao = this.alocarAcomodacao(
                                                        atendimento,
                                                        value
                                                    );
                                                    if (campoEdicao === 'atendimentoProcedimentos') {
                                                        if (
                                                            _.get(atendimento, posicaoAtendimento) !==
                                                            undefined
                                                        ) {
                                                            _.set(atendimento, name, value);
                                                            _.set(
                                                                atendimento,
                                                                posicaoAtendimento + '.quantSolicitada',
                                                                quant
                                                            );
                                                            _.set(
                                                                atendimento,
                                                                posicaoAtendimento + '.quantAutorizada',
                                                                quant
                                                            );
                                                            _.set(
                                                                atendimento,
                                                                posicaoAtendExecucao + '.quantidade',
                                                                quant
                                                            );
                                                            if (acomodacao) {
                                                                _.set(atendimento, 'acomodacao', acomodacao);
                                                            }
                                                        }
                                                    } else if (campoEdicao === 'atendimentoExecucaos') {
                                                        if (
                                                            _.get(atendimento, posicaoAtendimento) !==
                                                            undefined
                                                        ) {
                                                            _.set(atendimento, name, value);
                                                            _.set(
                                                                atendimento,
                                                                posicaoAtendProced + '.quantSolicitada',
                                                                quant
                                                            );
                                                            _.set(
                                                                atendimento,
                                                                posicaoAtendProced + '.quantAutorizada',
                                                                quant
                                                            );
                                                            _.set(
                                                                atendimento,
                                                                posicaoAtendExecucao + '.quantidade',
                                                                quant
                                                            );
                                                            if (acomodacao) {
                                                                _.set(atendimento, 'acomodacao', acomodacao);
                                                            }
                                                        }
                                                    }
                                                } else {
                                                    const posicaoAtendimento = name.slice(
                                                        0,
                                                        name.indexOf('.')
                                                    );
                                                    const posicaoAtendExecucao =
                                                        'atendimentoExecucaos' +
                                                        posicaoAtendimento.slice(name.indexOf('['));
                                                    const campoEdicao = name.slice(0, name.indexOf('['));
                                                    if (campoEdicao === 'atendimentoProcedimentos') {
                                                        if (
                                                            _.get(atendimento, posicaoAtendimento) !==
                                                            undefined
                                                        ) {
                                                            _.set(atendimento, name, value);
                                                        }
                                                    } else if (campoEdicao === 'atendimentoExecucaos') {
                                                        if (
                                                            _.get(atendimento, posicaoAtendExecucao) !==
                                                            undefined
                                                        ) {
                                                            _.set(atendimento, name, value);
                                                        }
                                                    }
                                                }

                                                if (
                                                    name != undefined &&
                                                    name.includes('atendimentoExecucaos')
                                                ) {
                                                    const novoNome = name.replace(
                                                        'atendimentoExecucaos',
                                                        'atendimentoProcedimentos'
                                                    );
                                                    const posicaoAtendimento = novoNome.slice(
                                                        0,
                                                        novoNome.indexOf('.')
                                                    );
                                                    if (
                                                        _.get(atendimento, posicaoAtendimento) !== undefined
                                                    ) {
                                                        _.set(atendimento, novoNome, value);
                                                    }
                                                }
                                            } else if (
                                                name != undefined &&
                                                name.includes('.quantSolicitada')
                                            ) {
                                                //DEMANDA 0622-000226 -> 0722-000265
                                                const posicaoAtendimento = name.slice(
                                                    0,
                                                    name.indexOf('.')
                                                );
                                                if (
                                                    _.get(atendimento, posicaoAtendimento) !== undefined
                                                ) {
                                                    if (
                                                        this.validarProcedimento(
                                                            atendimento,
                                                            _.get(
                                                                atendimento,
                                                                posicaoAtendimento + '.codigoProcedimento'
                                                            )
                                                        )
                                                    ) {
                                                        if (
                                                            _.get(atendimento, posicaoAtendimento) !==
                                                            undefined
                                                        ) {
                                                            let quant = this.alocarQuantidade(
                                                                atendimento,
                                                                _.get(
                                                                    atendimento,
                                                                    posicaoAtendimento + '.codigoProcedimento'
                                                                )
                                                            );
                                                            _.set(atendimento, name, value);
                                                            _.set(
                                                                atendimento,
                                                                posicaoAtendimento + '.quantSolicitada',
                                                                quant
                                                            );
                                                            _.set(
                                                                atendimento,
                                                                posicaoAtendimento + '.quantAutorizada',
                                                                quant
                                                            );
                                                        }
                                                    } else {
                                                        _.set(atendimento, name, value);
                                                    }
                                                }
                                            } else if (
                                                name != undefined &&
                                                name.includes('.quantAutorizada')
                                            ) {
                                                //DEMANDA 0622-000226 -> 0722-000265
                                                const posicaoAtendimento = name.slice(
                                                    0,
                                                    name.indexOf('.')
                                                );
                                                if (
                                                    _.get(atendimento, posicaoAtendimento) !== undefined
                                                ) {
                                                    if (
                                                        this.validarProcedimento(
                                                            atendimento,
                                                            _.get(
                                                                atendimento,
                                                                posicaoAtendimento + '.codigoProcedimento'
                                                            )
                                                        )
                                                    ) {
                                                        if (
                                                            _.get(atendimento, posicaoAtendimento) !==
                                                            undefined
                                                        ) {
                                                            let quant = this.alocarQuantidade(
                                                                atendimento,
                                                                _.get(
                                                                    atendimento,
                                                                    posicaoAtendimento + '.codigoProcedimento'
                                                                )
                                                            );
                                                            _.set(atendimento, name, value);
                                                            _.set(
                                                                atendimento,
                                                                posicaoAtendimento + '.quantSolicitada',
                                                                quant
                                                            );
                                                            _.set(
                                                                atendimento,
                                                                posicaoAtendimento + '.quantAutorizada',
                                                                quant
                                                            );
                                                        }
                                                    } else {
                                                        _.set(atendimento, name, value);
                                                    }
                                                }
                                            } else if (
                                                name != undefined &&
                                                name.includes('.quantidade')
                                            ) {
                                                //DEMANDA 0622-000226 -> 0722-000265
                                                const posicaoAtendimento = name.slice(
                                                    0,
                                                    name.indexOf('.')
                                                );
                                                if (
                                                    _.get(atendimento, posicaoAtendimento) !== undefined
                                                ) {
                                                    if (
                                                        this.validarProcedimento(
                                                            atendimento,
                                                            _.get(
                                                                atendimento,
                                                                posicaoAtendimento + '.codigoProcedimento'
                                                            )
                                                        )
                                                    ) {
                                                        if (
                                                            _.get(atendimento, posicaoAtendimento) !==
                                                            undefined
                                                        ) {
                                                            let quant = this.alocarQuantidade(
                                                                atendimento,
                                                                _.get(
                                                                    atendimento,
                                                                    posicaoAtendimento + '.codigoProcedimento'
                                                                )
                                                            );
                                                            _.set(atendimento, name, value);
                                                            _.set(
                                                                atendimento,
                                                                posicaoAtendimento + '.quantidade',
                                                                quant
                                                            );
                                                        }
                                                    } else {
                                                        _.set(atendimento, name, value);
                                                    }
                                                }
                                            } else {
                                                _.set(atendimento, name, value);
                                            }
                                        }
                                        if (!this.state.bloqueioSalvar) {
                                            atendimentoService.saveAll(atendimentos).then(
                                                (response) => {
                                                    this.props.onSaved(atendimentos);
                                                    this.props.success({
                                                        message: `Campo ${campoSelecionado.label} alterado com sucesso!`,
                                                    });

                                                    actions.setSubmitting(false);

                                                    this.props.closeEdicao();
                                                    this.props.campoAtual({ campoSelecionado: {} });
                                                    this.props.loading(false);
                                                },
                                                (errosResponse) => {
                                                    this.props.error({
                                                        message:
                                                            'Não foi possível criar atendimento, verifique os valores digitados.',
                                                    });
                                                    let erro = '';
                                                    for (
                                                        let index = 0;
                                                        index < errosResponse.length;
                                                        index++
                                                    ) {
                                                        let erros = errosResponse[index].erros;
                                                        let atendimentoErro =
                                                            errosResponse[index].atendimento;

                                                        try {
                                                            let response = erros.response.data;
                                                            if (erros.response.status === 500) {
                                                                erro +=
                                                                    'Atendimento ' +
                                                                    atendimentoErro.id +
                                                                    ': Erro interno do servidor, contate o Administrador do sistema.' +
                                                                    '\n';
                                                            }
                                                            if (response && response.messages) {
                                                                let errorMessage = '';
                                                                for (
                                                                    var i = 0;
                                                                    i < response.messages.length;
                                                                    i++
                                                                ) {
                                                                    let erroItem = response.messages[i];
                                                                    if (
                                                                        erroItem.fieldName &&
                                                                        translate(erroItem.message.code)
                                                                    ) {
                                                                        errorMessage += translate(
                                                                            erroItem.message.code
                                                                        );
                                                                    }
                                                                    if (erroItem.target === 'GLOBAL') {
                                                                        errorMessage += erroItem.message.code;
                                                                    }
                                                                }
                                                                if (errorMessage) {
                                                                    erro +=
                                                                        'Atendimento ' +
                                                                        atendimentoErro.id +
                                                                        ': ' +
                                                                        errorMessage +
                                                                        '\n';
                                                                }
                                                            }
                                                        } catch (error) {
                                                            console.error(error);
                                                        }
                                                    }
                                                    var blob = new Blob([erro], {
                                                        type: 'text/plain;charset=utf-8',
                                                    });
                                                    download(erro, 'erros' + '.txt', 'text/plain');
                                                    this.props.loading(false);
                                                }
                                            );
                                        } else {
                                            if (this.state.bloqueioSalvarErrors.length > 0) {
                                                for (
                                                    let i = 0;
                                                    i < this.state.bloqueioSalvarErrors.length;
                                                    i++
                                                ) {
                                                    this.props.error({
                                                        message: this.state.bloqueioSalvarErrors[i],
                                                    });
                                                }
                                            }
                                            this.props.closeEdicao();
                                            this.props.campoAtual({ campoSelecionado: {} });
                                            this.props.loading(false);
                                        }
                                    }
                                }}
                                ref={(form) => {
                                    this.formRef = form;
                                }}
                            >
                                {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleSubmit,
                                    isSubmitting,
                                    setFieldValue,
                                    validateForm,
                                    setValues,
                                }) => (
                                    <React.Fragment>
                                        <div className="icone-pendencia-left py-4 py-sm-4 px-sm-5 d-flex align-items-center justify-content-center">
                                            <div className="pr-sm-5 d-flex align-items-center">
                                                <img
                                                    src={iconeEditar}
                                                    alt="Editar"
                                                    className="iconMedio"
                                                />
                                                <div>
                                                    <h2 className="status-txt branco pl-2 mb-0">
                                                        <span> Editar campo </span>
                                                    </h2>
                                                    <p className="mb-0 branco pl-2">
                                                        {campoSelecionado.label}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="px-2 py-4 py-sm-4 px-sm-5 d-flex flex-column justify-content-center align-items-center borda-separacao input-pendencia">
                                            <label>{campoSelecionado.label}</label>
                                            {InputEdicaoEmMassa}
                                        </div>

                                        {!this.state.viewMode && (
                                            <React.Fragment>
                                                <div className="py-2 py-sm-4  px-3 d-flex w-25 flex-row align-items-center justify-content-center">
                                                    <button
                                                        className="btn btn-secondary white mt-3 mb-4 mx-2"
                                                        onClick={() => {
                                                            this.props.closeEdicao();
                                                            this.props.campoAtual({ campoSelecionado: {} });
                                                        }}
                                                        disabled={isSubmitting}
                                                    >
                                                        Cancelar
                                                    </button>
                                                    <button
                                                        className="btn btn-primary white mt-3 mb-4 mx-2"
                                                        onClick={() => {
                                                            validateForm(values).then((erros) => {
                                                                if (_.isEmpty(erros)) {
                                                                    if (
                                                                        _this.props.listaAtendimentos.length > 0
                                                                    ) {
                                                                        let atendimentos = _.cloneDeep(
                                                                            _this.props.listaAtendimentos
                                                                        );
                                                                        let numeroCarteiras = atendimentos.map(
                                                                            (obj) => obj.numeroCarteira
                                                                        );
                                                                        beneficiarioService
                                                                            .getBloqueioAll(
                                                                                numeroCarteiras,
                                                                                atendimentos[0].convenio.id
                                                                            )
                                                                            .then((response) => {
                                                                                if (response.data.length > 0) {
                                                                                    let beneficiarios = response.data;
                                                                                    let atendimentosBloqueio =
                                                                                        atendimentos.filter(
                                                                                            (atendimento) => {
                                                                                                return (
                                                                                                    beneficiarios.find(
                                                                                                        (beneficiario) =>
                                                                                                            beneficiario ===
                                                                                                            atendimento.numeroCarteira
                                                                                                    ) !== undefined
                                                                                                );
                                                                                            }
                                                                                        );
                                                                                    for (
                                                                                        let i = 0;
                                                                                        i < atendimentosBloqueio.length;
                                                                                        i++
                                                                                    ) {
                                                                                        let atendimento = atendimentos[i];
                                                                                        let error =
                                                                                            atendimento.numeroAtendimento +
                                                                                            ' - Paciente sem cobertura para atendimento, favor realizar a elegibilidade ou entrar em contato com a operadora';
                                                                                        let errors =
                                                                                            this.state.bloqueioSalvarErrors;
                                                                                        errors.push(error);
                                                                                        this.setState({
                                                                                            bloqueioSalvarErrors: errors,
                                                                                        });
                                                                                        this.setState({
                                                                                            bloqueioSalvar: true,
                                                                                        });
                                                                                    }
                                                                                }
                                                                            })
                                                                            .catch((error) => {
                                                                                console.error(error);
                                                                            });
                                                                    }
                                                                    _this.confirmEdicao(
                                                                        handleSubmit,
                                                                        values,
                                                                        setFieldValue
                                                                    );
                                                                }
                                                            });
                                                        }}
                                                        disabled={isSubmitting}
                                                    >
                                                        Salvar
                                                    </button>
                                                </div>
                                            </React.Fragment>
                                        )}
                                    </React.Fragment>
                                )}
                            </Formik>
                        </React.Fragment>
                    </div>
                </React.Fragment>
            );
        } else {
            return (
                <div className="barra-topo pb-0 bg-branco rounded d-flex flex-column flex-sm-row align-content-stretch flex-wrap">
                    <div className="px-sm-5 py-2 py-sm-4  d-flex align-items-center justify-content-center">
                        <img
                            src={iconAtendimento}
                            alt="Atendimento"
                            className="iconMedio"
                        />

                        <div>
                            <p className="mb-0"> Atendimento </p>
                            <h2 className={`status-txt`}>
                                <span>{this.props.numeroAtendimento}</span>
                            </h2>
                        </div>
                    </div>
                    <div className="barra-pendencia d-sm-flex flex-fill align-items-stretch">
                        <div
                            className={`pl-sm-4 animation-pendencia-right ${campoSelecionado.label && 'recolher'
                                } d-flex flex-sm-row flex-column align-items-stretch align-items-center justify-content-center`}
                        >
                            <div className="px-sm-5 py-5 py-sm-4  d-flex align-items-center justify-content-center">
                                <img src={iconeEditar} alt="Editar" className="iconMedio" />
                                <div>
                                    <p className="mb-0"> Atendimento </p>
                                    <h2 className={`status-txt`}>
                                        <span>{this.props.numeroAtendimento}</span>
                                    </h2>
                                </div>
                            </div>
                            <div className="px-sm-5 py-4 d-flex align-items-center justify-content-center flex-sm-fill">
                                <div className="info-texto px-5 px-sm-3 mt-2 mt-sm-0">
                                    <span className="font-weight-bold font-italic verde-destaque">
                                        Quer ajuda?
                                    </span>
                                    <p className="font-italic">
                                        Clique em um dos campos do atendimento abaixo para editar
                                    </p>
                                </div>
                            </div>
                            <div className="d-none d-sm-flex align-items-start justify-content-end">
                                <button
                                    className="btn btn-icon-only"
                                    onClick={this.props.toggleEdicaoEmMassa}
                                >
                                    <span className="icon-btn-fechar"></span>
                                </button>
                            </div>
                            <div className="d-flex d-sm-none  align-items-center justify-content-center">
                                <button
                                    className="btn btn-secondary white mt-3 mb-4 mx-2"
                                    onClick={this.props.toggleEdicaoEmMassa}
                                >
                                    <span className="icon-btn-fechar"></span>
                                    Fechar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

const mapDispatch = ({
    alert: { error, clear, success },
    load: { loading },
    edicaoEmMassa: { toggleEdicaoEmMassa, atendimentoParaEditar, closeEdicao },
    selectableInput: { campoAtual },
}) => ({
    loading: (load: boolean) => loading({ load }),
    error: (msg) => error(msg),
    clear: () => clear(),
    success: (msg) => success(msg),
    toggleEdicaoEmMassa: () => toggleEdicaoEmMassa(),
    atendimentoParaEditar: (atendimento) => atendimentoParaEditar(atendimento),
    campoAtual: (campoSelecionado) => campoAtual(campoSelecionado),
    closeEdicao: () => closeEdicao(),
});

function mapStateToProps(state) {
    const { edicaoEmMassaOpen, idAtendimentoParaEditar, numeroAtendimento } =
        state.edicaoEmMassa;
    const { user, permissions } = state.authentication;
    const { campoSelecionado } = state.selectableInput;

    return {
        edicaoEmMassaOpen,
        idAtendimentoParaEditar,
        numeroAtendimento,
        campoSelecionado,
        user,
        permissions,
    };
}

export default connect(
    mapStateToProps,
    mapDispatch
)(withRouter(EdicaoEmMassaTopo));

//campo selecionado não limpa (redux)
//fexha
