//@flow
import { urlsConstants } from "../constants/url.constant";
import { objectsConstants } from "../constants/objects.constants";
import { defaultService } from "./defaultService";
import { func } from "prop-types";

export const atendimentoService = {
  findByFilter,
  findTableColumns,
  exportToExcel,
  findSearchableColumns,
  countStatus,
  save,
  doDelete,
  findByIds,
  encaminharAtendimentos,
  find,
  encaminharParaFaturamento,
  findByPendencia,
  findNumeroGuiaByFilterAtendimento,
  findNumeroAtendimentoByFilterAtendimento,
  historico,
  getItenByfilter,
  findSumario,
  findCaraterAtendimento,
  findIndicacaoAcidente,
  alterarConvenioLote,
  verificaRetorno,
  getNumeroGuia,
  getNumeroGuiaConvenio,
  bloquearDataAtendimento,
  findGuiaPadrao,
  totalAtendimentoPorStatus,
  imprimirProtocolo,
  saveAll,
  encaminharAtendimentosProtocol,
  findByAtendimentosAtrasados,
  exportAtendimentosAtrasados,
  statusAtendimentosEmAtraso,
  findByRecebimentoProtocol,
  alterarTipoGuia,
  removerAtendimentoFaturamento,
  findGlosaSeraRepresentada,
  salvarJustificativaGlosa,
  removerProtocolo,
  incluirNoProtocolo,
  separarProcedimentos,
  findByAtendimentosAtrasadosCooperativa,
  findByAtendimentosRmovidosLoteCooperativa,
  exportAtendimentosAtrasadosCooperativa,
  findCriacaoLoteConvenioPorTipoGuia,
  getDataAtendimentoValidacao,
  findByEncaminhamentoEmMassa,
  exportMonitoramentoMovimentacaoGuia,
  monitoramentoMovimentacaoGuiaListaConvenios,
  findAllAtendimentosPrazoGeracaoProtocolo,
  validarPermisaoJustificarGlosa,
  getAtendimentoByCarteiraAndCpfAndNomeBeneficiario,
  imprimirExames,
  enviarExames,
  enviarWpp,
  enviarPendenciaWpp,
  imprimirGuia,
  isRegraUrgencia,
  getEvolucaoClinicaAtendimento,
    imprimirEvolucaoClinica,
    validarPermisaoJustificarGlosa,
    validarAuxiliares,
  exportAtendimentos,
  findByNumeroAtendimento,
  validarPermissaoNovoAtendimento,
  validarAtendimentoDuplicados,
};

function separarProcedimentos(procedimentos, atendimento) {
  atendimento.atendimentoProcedimentos = procedimentos;
  return defaultService.doPost(
    `${urlsConstants.ATENDIMENTO}separarProcedimentos/${atendimento.id}`,
    atendimento
  );
}

function alterarTipoGuia(id, tipoGuia) {
  return defaultService.doGet(
    `${urlsConstants.ATENDIMENTO}alterarTipoGuia/${id}/${tipoGuia}`
  );
}

function removerAtendimentoFaturamento(id) {
  return defaultService.doGet(
    `${urlsConstants.ATENDIMENTO}removerAtendimentoFaturamento/${id}`
  );
}

function salvarJustificativaGlosa(id, justificativa) {
  return defaultService.doPost(
    `${urlsConstants.ATENDIMENTO}salvarJustificativaGlosa/${id}`,
    { justificativaGlosa: justificativa }
  );
}

function findGlosaSeraRepresentada(id) {
  return defaultService.doGet(
    `${urlsConstants.ATENDIMENTO}findGlosaSeraRepresentada/${id}`
  );
}

function imprimirProtocolo(numeroProtocolo) {
  return defaultService.doGet(
    `${urlsConstants.ATENDIMENTO}imprimirProtocolo/${numeroProtocolo}`
  );
}
function removerProtocolo(ids) {
  return defaultService.doPost(
    `${urlsConstants.ATENDIMENTO}removerProtocolo`,
    ids
  );
}

function incluirNoProtocolo(ids, numeroProtocolo) {
  return defaultService.doPost(
    `${urlsConstants.ATENDIMENTO}incluirNoProtocolo/${numeroProtocolo}`,
    ids
  );
}

function statusAtendimentosEmAtraso() {
  return defaultService.doGet(
    `${urlsConstants.ATENDIMENTO}statusAtendimentosEmAtraso`
  );
}
function findByAtendimentosAtrasadosCooperativa(page, pageSize) {
  return defaultService.doPost(
    `${urlsConstants.ATENDIMENTO}atendimentosEmAtrasoCooperativa`,
    {
      pageSize: pageSize,
      page: page,
      order: "",
      direction: "",
    }
  );
}

function findByAtendimentosRmovidosLoteCooperativa(page, pageSize) {
  return defaultService.doPost(
    `${urlsConstants.ATENDIMENTO}atendimentosRemovidosLoteCooperativa`,
    {
      pageSize: pageSize,
      page: page,
      order: "",
      direction: "",
    }
  );
}

function findByAtendimentosAtrasados(page, pageSize) {
  return defaultService.doPost(
    `${urlsConstants.ATENDIMENTO}atendimentosEmAtraso`,
    {
      pageSize: pageSize,
      page: page,
      order: "",
      direction: "",
    }
  );
}
function exportAtendimentosAtrasadosCooperativa(fields, labels) {
  return defaultService.doPost(
    `${urlsConstants.ATENDIMENTO}exportAtendimentosEmAtrasoCooperativa`,
    { fields, labels }
  );
}
function exportAtendimentosAtrasados(fields, labels) {
  return defaultService.doPost(
    `${urlsConstants.ATENDIMENTO}exportAtendimentosEmAtraso`,
    { fields, labels }
  );
}

function exportAtendimentos(filter, fields, labels) {
  return defaultService.doPost(
    `${urlsConstants.ATENDIMENTO}exportAtendimentosSelecionados`,
    filter, fields, labels
  );
}

function totalAtendimentoPorStatus(filter, situacao) {
  return defaultService.findByFiltro(
    `${urlsConstants.ATENDIMENTO}getTotalPorSituacao/${situacao}`,
    {},
    filter,
    {}
  );
}
function findByRecebimentoProtocol(input) {
  return defaultService.doGet(
    `${urlsConstants.ATENDIMENTO}findByNumeroAtendimentoProtocol/${input}`
  );
}

function findByEncaminhamentoEmMassa(status, input) {
  if (status && status.id) {
    return defaultService.doGet(
      `${urlsConstants.ATENDIMENTO}findByEncaminhamentoEmMassa/${input}/${status.id}`
    );
  } else {
    return defaultService.doGet(
      `${urlsConstants.ATENDIMENTO}findByGerarLoteEmMassa/${input}`
    );
  }
}

function getNumeroGuia() {
  return defaultService.doGet(`${urlsConstants.ATENDIMENTO}getNumeroGuia`);
}

function getNumeroGuiaConvenio(convenio) {
  return defaultService.doGet(
    `${urlsConstants.ATENDIMENTO}getNumeroGuiaConvenio/${convenio}`
  );
}

function alterarConvenioLote(convenio, idLote) {
  return defaultService.doPost(
    `${urlsConstants.LOTE}alterarConvenioLote/${convenio.id}`,
    { id: idLote }
  );
}

function verificaRetorno(atendimento) {
  return defaultService.doPost(
    `${urlsConstants.ATENDIMENTO}verificaRetorno`,
    atendimento
  );
}

function findSumario(filter) {
  let baseUrl = `${urlsConstants.ATENDIMENTO}sumarioRepasse`;
  return defaultService.findByFiltro(baseUrl, {}, filter, {});
}

function findIndicacaoAcidente() {
  return defaultService.doGet(`${urlsConstants.ATENDIMENTO}indicacaoAcidente`);
}
function findCaraterAtendimento() {
  return defaultService.doGet(`${urlsConstants.ATENDIMENTO}caraterAtendimento`);
}
function historico(id) {
  return defaultService.doGet(`${urlsConstants.ATENDIMENTO}historico/${id}`);
}
function findNumeroGuiaByFilterAtendimento(filter, input) {
  filter.descricaoFiltro = input;
  return defaultService.doPost(
    `${urlsConstants.ATENDIMENTO}getNumeroGuiaByFiltroAtendimento`,
    filter
  );
}

function findNumeroAtendimentoByFilterAtendimento(filter, input) {
  filter.descricaoFiltro = input;
  return defaultService.doPost(
    `${urlsConstants.ATENDIMENTO}getNumeroAtendimentoByFiltroAtendimento`,
    filter
  );
}

function findByPendencia(idPendencia) {
  return defaultService.doGet(
    `${urlsConstants.ATENDIMENTO}findByPendencia/${idPendencia}`
  );
}
function find(idlote, input) {
  return defaultService.doGet(
    `${urlsConstants.ATENDIMENTO}find/${idlote}/${input}`
  );
}

function encaminharAtendimentos(idStatus, idsAtendimentosPorOrdemDeSelecao, justificativa) {
  if (!justificativa) {
    return defaultService.doPost(
      `${urlsConstants.ATENDIMENTO}encaminharAtendimento/${idStatus}`,
      idsAtendimentosPorOrdemDeSelecao
    );
  } else {
    const idsAtendimentos = idsAtendimentosPorOrdemDeSelecao.map((atendimento) => atendimento.idAtendimento);
    return defaultService.doPost(
      `${urlsConstants.ATENDIMENTO}cancelarAtendimento/${idStatus}`,
      { idsAtendimentos: idsAtendimentos, justificativa: justificativa }
    );
  }
}
function encaminharAtendimentosProtocol(idsAtendimentos) {
  return defaultService.doPost(
    `${urlsConstants.ATENDIMENTO}encaminharAtendimentoProtocol/`,
    idsAtendimentos
  );
}
function encaminharParaFaturamento(idStatus, idLote) {
  return defaultService.doPost(
    `${urlsConstants.LOTE}encaminharParaFaturamento/${idLote}/${idStatus}`,
    { id: idLote }
  );
}
function doDelete(atendimento, justificativa) {
  return defaultService.doPost(
    `${urlsConstants.ATENDIMENTO}deletar/${atendimento.id}`,
    { justificativaExclusao: justificativa }
  );
}

function findByIds(ids) {
  return defaultService.doPost(`${urlsConstants.ATENDIMENTO}getByIds`, ids);
}

function save(values) {
  return defaultService.doSave(`${urlsConstants.ATENDIMENTO}`, values);
}
function saveAll(atendimentos) {
  return defaultService.doPut(
    `${urlsConstants.ATENDIMENTO}edicaoEmMassa`,
    atendimentos
  );
}
function countStatus(filtro) {
  return defaultService.doPost(
    `${urlsConstants.ATENDIMENTO}getAtedimentosPorSituacao`,
    filtro
  );
}

function exportToExcel(filter, fields, labels, filterDefault) {
  return defaultService.exportToExcel(
    "atendimento",
    filter,
    fields,
    labels,
    filterDefault
  );
}

function findTableColumns() {
  return defaultService.doGet(`${urlsConstants.ATENDIMENTO}list/fields`);
}
function findSearchableColumns() {
  return defaultService.doGet(`${urlsConstants.ATENDIMENTO}search/fields`);
}
function bloquearDataAtendimento(id) {
  return defaultService.doGet(
    `${urlsConstants.ATENDIMENTO}bloquearDataAtendimento/${id}`
  );
}
function findGuiaPadrao(tipoGuia, convenio, procedimento) {
  const value = {
    tipoGuia: tipoGuia,
    convenio: convenio,
    codigoProcedimento: procedimento,
  };
  return defaultService.doPost(
    `${urlsConstants.ATENDIMENTO}findGuiaPadrao`,
    value
  );
}

function findByFilter(
  options: Options,
  filter: any,
  dafaultFilter
): Promise<Array<>> {
  return defaultService.findByFiltro(
    `${urlsConstants.ATENDIMENTO}list`,
    options,
    filter,
    dafaultFilter
  );
}

function getItenByfilter(url, filter: any, options): Promise<Array<>> {
  return defaultService.findByFiltro(url, options, filter, null);
}

function findCriacaoLoteConvenioPorTipoGuia(atendimento) {
  let type;
  for (let i = 0; i < objectsConstants.TIPOS_GUIA_REGRA_CONVENIO.length; i++) {
    if (
      objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[i].label === atendimento.type
    ) {
      type = objectsConstants.TIPOS_GUIA_REGRA_CONVENIO[i].id;
    }
  }

  if (atendimento && atendimento.convenio && atendimento.type) {
    return defaultService.doGet(
      `${urlsConstants.ATENDIMENTO}findByRegraConvenioCriarLotePorTipoGuia/${atendimento.convenio.id}/${type}`
    );
  }
}

function getDataAtendimentoValidacao() {
  return defaultService.doGet(`${urlsConstants.ATENDIMENTO}getDataValidacao`);
}

function exportMonitoramentoMovimentacaoGuia(fields, labels) {
  return defaultService.doPost(
    `${urlsConstants.ATENDIMENTO}monitoramentoMovimentacaoGuia`,
    { fields, labels }
  );
}

function monitoramentoMovimentacaoGuiaListaConvenios(fields, labels) {
  return defaultService.doPost(
    `${urlsConstants.ATENDIMENTO}monitoramentoMovimentacaoGuiaListaConvenios`,
    { fields, labels }
  );
}

function findAllAtendimentosPrazoGeracaoProtocolo(idsAtendimentos) {
  return defaultService.doPost(
    `${urlsConstants.ATENDIMENTO}atendimentosPrazoGeracaoProtocolo`,
    idsAtendimentos
  );
}

function validarPermisaoJustificarGlosa(idAtendimento) {
  return defaultService.doGet(
    `${urlsConstants.ATENDIMENTO}validarPermisaoJustificarGlosa/${idAtendimento}`
  );
}

function getAtendimentoByCarteiraAndCpfAndNomeBeneficiario(filtro) {
  return defaultService.doPost(
    `${urlsConstants.ATENDIMENTO}getAtendimentoByCarteiraAndCpfAndNomeBeneficiario`,
    filtro
  );
}

function imprimirExames(numeroAtendimento) {
  return defaultService.doGet(
    `${urlsConstants.ATENDIMENTO}imprimirExames/${numeroAtendimento}`
  );
}

function enviarExames(numeroAtendimento, mensagem) {
  return defaultService.doGet(
    `${urlsConstants.ATENDIMENTO}enviarExames/${numeroAtendimento}/${mensagem}`
  );
}

function enviarWpp(numeroAtendimento, mensagem) {
  return defaultService.doGet(
    `${urlsConstants.ATENDIMENTO}enviarWpp/${numeroAtendimento}/${mensagem}`
  );
}

function enviarPendenciaWpp(idAtendimento, envioConsultorio, cooperados) {
  return defaultService.doPost(
    `${urlsConstants.ATENDIMENTO}enviarPendenciaWpp/${idAtendimento}/${envioConsultorio}`, cooperados
  );
}

function imprimirGuia(idAtendimento) {
  return defaultService.doGet(
    `${urlsConstants.ATENDIMENTO}imprimirGuia/${idAtendimento}`
  );
}

function isRegraUrgencia(execucoes) {
  return defaultService.doPost(
    `${urlsConstants.ATENDIMENTO}isRegraUrgencia`,
    execucoes
  );
}

function getEvolucaoClinicaAtendimento(numeroAtendimento, idCooperativa) {
  return defaultService.doGet(
    `${urlsConstants.ATENDIMENTO}getEvolucaoClinicaAtendimento/${numeroAtendimento}/${idCooperativa}`
  );
}

function imprimirEvolucaoClinica(numeroAtendimento, idCooperativa) {
  return defaultService.doGet(
    `${urlsConstants.ATENDIMENTO}imprimirEvolucaoClinica/${numeroAtendimento}/${idCooperativa}`
  );
}

function validarAuxiliares(idAtendimento) {
    return defaultService.doPost(`${urlsConstants.ATENDIMENTO}auxiliares`, idAtendimento);
};

function findByNumeroAtendimento(params) {
  return defaultService.doGet(`${urlsConstants.ATENDIMENTO}findByGerarLoteEmMassa/${params}`);
}

function validarPermissaoNovoAtendimento(params) {
  const queryString = new URLSearchParams(params).toString();
  return defaultService.doGet(`${urlsConstants.ATENDIMENTO}validarPermissaoNovoAtendimento?${queryString}`);
}


async function validarAtendimentoDuplicados(values) {
  return defaultService.doPost(`${urlsConstants.ATENDIMENTO}validarAtendimentoDuplicados`, values);
}