import * as _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormGroup } from "reactstrap";
import CpfInput from "../../../components/Inputs/CpfInput";
import DatePickerInput from "../../../components/Inputs/DatePickerInput";
import FormInput from "../../../components/Inputs/FormInput";
import FormSelectInput from "../../../components/Inputs/FormSelectInput";
import InputViewEdit from "../../../components/Inputs/InputViewEdit";
import SelectInput from "../../../components/Inputs/SelectInput";
import { enumTipoErrors } from "../../../constants/objects.constants"
import { objectsConstants } from "../../../constants/objects.constants";
import { dateHelper } from "../../../helpers/date.helper";
import { formatterHelper } from "../../../helpers/formatter.helper";
import SelectableInput from "../../../sascComponents/inputs/SelectableInput";
import { beneficiarioService } from "../../../services/beneficiario.service";
import { convenioService } from "../../../services/convenio.service";
import { convenioPlanoService } from "../../../services/convenioPlano.service";

class DadosBeneficiarioGuiaForm extends PureComponent<Props, State> {
  constructor(props) {
    if(props.changeGuia){
      beneficiarioService.resetBeneficiario(props);
    }
    super(props);
  }

  findDadosBeneficiario = async () => {
    if(this.props.values.convenio){
    await beneficiarioService
      .getBloqueioAll([this.props.values.numeroCarteira], this.props.values.convenio.id)
      .then(async (bloqueio) => {
        if (bloqueio.data.length == 0) {
          await beneficiarioService
            .findByCarteirinha(this.props.values.numeroCarteira)
            .then(response => {
              let newValues = _.cloneDeep(this.props.values);
              if (newValues.cpf == undefined || newValues.cpf === "") {
                newValues.dataValidadeCarteira = response.data.dataValidadeCarteira;
                newValues.dataNascimento = response.data.dataNascimento;
                newValues.nomeBeneficiario = response.data.nomeBeneficiario;
                newValues.cartaoNacionalSaude = response.data.cartaoNacionalSaude;
                if (response.data.cpf) {
                  newValues.cpf = response.data.cpf;
                } else {
                  newValues.cpf = '';
                }
              }
              this.props.setValues(newValues);
            },
              error => {
                this.props.infoBlack({
                  message: enumTipoErrors.PACIENTE_NAO_CADASTRADO
                });
              }

            );
        } else {
          this.props.error({
            message: enumTipoErrors.CARTEIRA_PACIENTE_BLOQUEADA});
        }
      })
    }
      if (this.props.values.rascunho) {
        this.sendToStorange(this.props.values)
      }
  };


  findDadosBeneficiarioByCpf = () => {
    beneficiarioService
      .findByCpf(this.props.values.cpf)
      .then(response => {
        let newValues = _.cloneDeep(this.props.values);
        if (newValues.numeroCarteira == undefined || newValues.numeroCarteira === "") {
          newValues.dataValidadeCarteira = response.data.dataValidadeCarteira;
          newValues.dataNascimento = response.data.dataNascimento;
          newValues.nomeBeneficiario = response.data.nomeBeneficiario;
          newValues.cartaoNacionalSaude = response.data.cartaoNacionalSaude;
          newValues.numeroCarteira = response.data.numeroCarteira;
        }
        this.props.setValues(newValues);
      },
        error => {
          this.props.infoBlack({
            message: `Este beneficiário ainda não foi cadastrado. Solicite e preencha todas as informações do beneficiário.`
          });

        }
      );
      if (this.props.values.rascunho) {
        this.sendToStorange(this.props.values)
      }
  };

  sendToStorange = (values) => {
    try {
      values.rascunho && localStorage.setItem('rascunho', JSON.stringify(values))
    } catch (error) {
      if (error instanceof DOMException && (
        error.code === 22 ||
        error.code === 1014 ||
        error.name === 'QuotaExceededError' ||
        error.name === 'NS_ERROR_DOM_QUOTA_REACHED'
      )) {
        console.error('Limite de armazenamento local atingido');
      } else {
        console.error('Erro ao salvar no armazenamento local:', error.message);
      }
    }
  }

  verificaElegibilidadePacientePortalConvenio = async () => {
    const { values } = this.props;
    if(!values.id){
      if(values.convenio && values.numeroCarteira){
              await convenioService.isPacienteElegivelConvenio(values.convenio, values.numeroCarteira).then((response) =>{
                if(response.data){
                  if(!response.data.success){
                    this.props.warning({message: response.data.message })
                  }else{
                    this.props.success({message: response.data.message })
                  }
                }
              })    
      }
    }
  }

  onBlurNumeroCarteirinha = async ()  => {
    this.props.loading(true);
    await this.findDadosBeneficiario();
    await this.verificaElegibilidadePacientePortalConvenio();
    this.props.loading(false); 
  }


  render() {
    const { setFieldValue, values, errors, viewMode } = this.props;

    return (
      <React.Fragment>
        <div className={`row ${this.props.className}`}>
          <h6 className="w-100">Dados do Beneficiário</h6>
          <SelectableInput viewMode={viewMode} atendimento={values}
            className="fixo2"
            name={"numeroCarteira"}
            label={"Número da carteira"}
            value={values.numeroCarteira}
          >
            {/* <p>478621</p> */}
            <InputViewEdit
              label="Número da carteira"
              component={FormInput}
              required={true}
              alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
              onChange={(name, date) => {
                  let value="";

                  value = beneficiarioService.validaTipoCarteirinha(date,values.convenio)
                  setFieldValue(name, value);
              }}
              onBlur={this.onBlurNumeroCarteirinha}
              className={"destaque"}
              name={"numeroCarteira"}
              placeholder="Número da carteira"
              viewMode={viewMode}
              erroMensagem={errors.numeroCarteira}
              value={values.numeroCarteira}
              minLength = {_.get(values, 'convenio.quantMinDigitosCarteirinha')}
              maxLength={_.get(values,'convenio.quantMaxDigitosCarteirinha') }
            />
          </SelectableInput>
          <SelectableInput viewMode={viewMode} atendimento={values} className="fixo2"
            name={"dataValidadeCarteira"}
            label={"Data de validade da carteira"}
            value={values.dataValidadeCarteira}
          >
            {/* <p>31/12/2019</p> */}
            <InputViewEdit
              label="Data de validade da carteira"
              component={DatePickerInput}
              onChange={(name, date) => {
                setFieldValue(name, date);
              }}
              onBlur={() => this.sendToStorange(values)}
              defaultValue={
                values.dataValidadeCarteira
                  ? dateHelper.format(values.dataValidadeCarteira, {
                    mode: "DATE"
                  })
                  : "--"
              }
              mask={"99/99/9999"}
              name={"dataValidadeCarteira"}
              id={"dataValidadeCarteira"}
              placeholder="Validade da carteira"
              viewMode={viewMode}
              type={"text"}
              value={values.dataValidadeCarteira}
              erroMensagem={errors.dataValidadeCarteira}
              //required={_.get(values,'convenio.validadeCarteira',false)}
              required={values.convenio && values.convenio.validadeCarteira}
              alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
            />
          </SelectableInput>
          {/* PLANO */}
          <FormGroup className="fixo mobile-100">
            <InputViewEdit
              label="Plano"
              component={FormSelectInput}
              service={convenioPlanoService.findByConvenio}
              returnFullObject={true}
              parent={values.convenio && values.convenio.id}
              required={values.convenio && values.convenio.planoObrigatorio}
              searchable={true}
              labelKey={"label"}
              valueKey={"plano"}
              onChange={(name, date) => {
                setFieldValue(name, date);
              }}
              erroMensagem={
                _.get(errors, `plano`)
              }
              name={`plano`}
              defaultValue={values.plano ? values.plano.label : "--"}
              placeholder=""
              viewMode={viewMode}
              value={values.plano}
              clearable={true}
            />
          </FormGroup>
          {/* CPF */}
          <SelectableInput viewMode={viewMode} atendimento={values} className="fixo2 mobile-100"
            name={"cpf"}
            label={"CPF"}
            value={values.cpf}
          >
            <InputViewEdit
              component={CpfInput}
              label="CPF"
              id="cpf"
              name="cpf"
              onChange={(name, value) => {
                setFieldValue(name, value);
              }}
              value={values.cpf}
              erroMensagem={errors.cpf}
              onBlur={this.findDadosBeneficiarioByCpf}
              placeholder="CPF"
              noSize={true}
              viewMode={viewMode}
              required={convenioService.isCPFObrigatoria(values.type,_.get(values, 'convenio.listaRegrasSenhaConvenio', false))}
              alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
            />
          </SelectableInput>
          <SelectableInput viewMode={viewMode} atendimento={values} className="fixo2"
            name={"dataNascimento"}
            label={"Data de nascimento"}
            value={values.dataNascimento}
          >
            <InputViewEdit
              label="Data de nascimento"
              component={DatePickerInput}
              onChange={(name, date) => {
                setFieldValue(name, date);
              }}
              onBlur={() => this.sendToStorange(values)}
              defaultValue={
                values.dataNascimento
                  ? dateHelper.format(values.dataNascimento, {
                    mode: "DATE"
                  })
                  : "--"
              }
              mask={"99/99/9999"}
              alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
              name={"dataNascimento"}
              id={"dataNascimento"}
              placeholder="Data de nascimento"
              viewMode={viewMode}
              type={"text"}
              value={values.dataNascimento}
              erroMensagem={errors.dataNascimento}
              required={convenioService.isDataNascimentoObrigatoria(values.type,_.get(values, 'convenio.listaRegrasSenhaConvenio', false))}
            />
          </SelectableInput>

          <SelectableInput viewMode={viewMode} atendimento={values} className=""
            name={"nomeBeneficiario"}
            label={"Nome do beneficiário"}
            value={values.nomeBeneficiario}>

            {/* <p>Astolfo Soares Pereira</p> */}
            <InputViewEdit
              label="Nome do beneficiário"
              component={FormInput}
              required={true}
              onChange={(name, date) => {
                  if(date && date!=null){
                      date = date.toUpperCase()
                  }
                setFieldValue(name, date);
              }}
              onBlur={() => this.sendToStorange(values)}
              className={"destaque"}
              name={"nomeBeneficiario"}
              placeholder="Nome do beneficiário"
              type={"text"}
              viewMode={viewMode}
              erroMensagem={errors.nomeBeneficiario}
              value={values.nomeBeneficiario}
              alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
            />
          </SelectableInput>
          <SelectableInput viewMode={viewMode} atendimento={values} className="fixo2"
            name={"cartaoNacionalSaude"}
            label={"Cartão nacional de saúde"}
            value={values.cartaoNacionalSaude}>
            <InputViewEdit
              label="Cartão nacional de saúde"
              component={FormInput}
              onChange={(name, date) => {
                setFieldValue(name, date);
              }}
              onBlur={() => this.sendToStorange(values)}
              viewMode={viewMode}
              name={"cartaoNacionalSaude"}
              placeholder="Número do cartão"
              type={"text"}
              value={values.cartaoNacionalSaude}
              alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
            />
          </SelectableInput>
          <SelectableInput viewMode={viewMode} atendimento={values} className="fixo2"
            name={"atendimentoRn"}
            label={"Atendimento a RN"}
            value={values.atendimentoRn &&
              values.atendimentoRn.value === false ? 'Não'
              : formatterHelper.getLabelEnum(
                values.atendimentoRn &&
                  values.atendimentoRn.value
                  ? values.atendimentoRn.value
                  : values.atendimentoRn,
                objectsConstants.SIM_NAO
              )}>
            {/* <p className="font-weight-bold">N</p> */}
            <InputViewEdit
              label="Atendimento a RN"
              component={SelectInput}
              options={objectsConstants.SIM_NAO}
              name={"atendimentoRn"}
              searchable={true}
              returnFullObject={true}
              defaultValue={values.atendimentoRn &&
                values.atendimentoRn.value === false ? 'Não'
                : formatterHelper.getLabelEnum(
                  values.atendimentoRn &&
                    values.atendimentoRn.value
                    ? values.atendimentoRn.value
                    : values.atendimentoRn,
                  objectsConstants.SIM_NAO
                )}
              value={_.isBoolean(values.atendimentoRn) ? formatterHelper.getEnum(values.atendimentoRn, objectsConstants.SIM_NAO) : values.atendimentoRn}
              labelKey={"label"}
              valueKey={"value"}
              viewMode={viewMode}
              onChange={(name, value) => {
                setFieldValue(name, value.value);
              }}
              onBlur={() => this.sendToStorange(values)}
              alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
            />
          </SelectableInput>
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  menu: { showMenu, hideMenu },
  alert: { infoBlack, error, clear }
}) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu(),
  infoBlack: msg => infoBlack(msg),
  error: msg => error(msg)
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(DadosBeneficiarioGuiaForm));
