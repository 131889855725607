import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormGroup } from "reactstrap";
import FormTextArea from "../../../../components/Inputs/FormTextArea";
import InputViewEdit from "../../../../components/Inputs/InputViewEdit";
import SelectableInput from "../../../../sascComponents/inputs/SelectableInput";
import AtendimentoAnexos from "../../../../components/Inputs/AtendimentoAnexos";
import SelectInput from "../../../../components/Inputs/SelectInput";
import { justificativaService } from "../../../../services/justificativa.service";
import { objectsConstants } from "../../../../constants/objects.constants";
import { formatterHelper } from "../../../../helpers/formatter.helper";
import * as _ from "lodash";
import CheckBoxInput from "../../../../components/Inputs/CheckBoxInput";

class DadosObservacaoAnexosForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      openAnexo: false,
    };
  }


  toggleAnexos = () => {
    this.setState(({ openAnexo }) => ({ openAnexo: !openAnexo }));
  };

  //Verifica se a data é antes da implementacao do chamado de tipo do anexo.
  verificaDataAntesImplTipoAnexo = () => {
    const { values } = this.props;    
    if(values.id){
      const dataCadastro = new Date(values.dataCadastro);
      dataCadastro.setHours(0, 0, 0, 0);
      if(dataCadastro < objectsConstants.DATE_IMPL_GUIA_HONORARIO_ENVIO_ELETRONICO_AUTOMATICO){
        return true;
      }else{
        return false;
      }
    }else{
      return false;
    }
  }

  render() {
    const {
      setFieldValue,
      values,
      handleChange,
      viewMode,
      errors
    } = this.props;
    const dataAntesImplTipoAnexo = this.verificaDataAntesImplTipoAnexo();

    return (
      <React.Fragment>
        <div className="row section-form">
          {!viewMode && <SelectInput
            className="min-width"
            name={"observacao"}
            id={"observacao"}
            label={"Buscar justificativa"}
            value={this.state.justificativa}
            onChange={(name, value) => {
              let justificativa = value.texto;
              if (values.observacao) {
                setFieldValue(name, justificativa.concat(" ", values.observacao));
              } else {
                setFieldValue(name, justificativa);
              }
            }}
            returnFullObject={true}
            labelKey={"texto"}
            valueKey={"id"}
            placeholder="Selecione a justificativa"
            onFetchData={justificativaService.findByTexto}
          />}
          
        </div>
        <div className="row section-form">
          <SelectableInput viewMode={viewMode} atendimento={values} className="anexoObservacaoConsulta"
            name={"observacao"}
            label={"Observação/Justificativa"}
            value={values.observacao}>
            <InputViewEdit
              label={"Observação/Justificativa"}
              component={FormTextArea}
              id={'observacao'}
              className={'textArea'}
              type={'textarea'}
              disabled={false}

              onChange={handleChange}
              name={"observacao"}
              erroMensagem={errors.observacao}
              placeholder=""
              viewMode={viewMode}
              value={values.observacao}
              alertMensagem={values.convenio ? values.convenio.listRegraMensagemTipoGuia : null}
            />
          </SelectableInput>

          <FormGroup className="anexoFixo">
            <div className="anexoSimNao">
                <InputViewEdit
                  label="Anexo encaminhado fisicamente para a cooperativa?"
                  component={SelectInput}
                  options={objectsConstants.SIM_NAO}
                  name={"anexoEncaminhado"}
                  erroMensagem={errors.anexoEncaminhado}
                  searchable={true}
                  returnFullObject={true}
                  defaultValue={values.anexoEncaminhado &&
                    values.anexoEncaminhado.value === false ? 'Não'
                    : formatterHelper.getLabelEnum(
                      values.anexoEncaminhado &&
                        values.anexoEncaminhado.value
                        ? values.anexoEncaminhado.value
                        : values.anexoEncaminhado,
                      objectsConstants.SIM_NAO
                    )}
                  value={_.isBoolean(values.anexoEncaminhado) ? formatterHelper.getEnum(values.anexoEncaminhado, objectsConstants.SIM_NAO) : values.anexoEncaminhado}
                  labelKey={"label"}
                  valueKey={"value"}
                  viewMode={viewMode}
                  onChange={(name, value) => {
                    setFieldValue(name, value.value);
                  }}
                  alertMensagem={values.convenio ? values.convenio.listRegraMensagemTipoGuia : null}
                />
            
            <label>Anexos - Guia física</label>
            <SelectableInput viewMode={viewMode} atendimento={values} className=""
              name={"atendimentoAnexos"}
              label={"Anexos - Guia física"}
              value={
                values.atendimentoAnexos
              }
            >

              <InputViewEdit
                component={AtendimentoAnexos}
                toggleAnexos={this.toggleAnexos}
                errors={errors}
                values={values}
                atualizaAnexos={this.atualizaAnexos}
                onChange={(name, anexos) => {

                  setFieldValue(name, anexos);
                }}
                openAnexo={this.state.openAnexo}
                user={this.props.user}
                anexosInputRefCallback={anexos => (this._anexosInput = anexos)}
                alertMensagem={values.convenio ? values.convenio.listRegraMensagemTipoGuia : null}
                dataAntesImplTipoAnexo={dataAntesImplTipoAnexo}

              />
            </SelectableInput>
            <div><label>{values.atendimentoAnexos
                         && values.atendimentoAnexos.length > 0
                         && values.atendimentoAnexos[0].midia.dataCadastro
                         ? "Data de inclusão do anexo: " + formatterHelper.converterFormatoData(values.atendimentoAnexos[0].midia.dataCadastro):""}
                 </label></div>
            </div>
            {values.cooperativa && values.cooperativa.permiteEnvioEletronico && 
            <div className="row section-form">
              <CheckBoxInput
                label={"Anexada guia com assinaturas do médico, paciente ou responsável e comprovantes"}
                labelClass={"font-13px verde-destaque font-bold"}
                name={"envioEletronico"}
                id={"envioEletronico"}
                disabled={viewMode}
                checked={values.envioEletronico}
                errors={errors}
                erroMensagem={errors.envioEletronico}
                onChange={(name, value) => {
                  setFieldValue(name, value);
                }}
              /></div>}
          </FormGroup>

        </div>
        <div className="row section-form">
          {values.observacaoSeparacaoGuia && viewMode && (<FormGroup className="mobile-100">
            <label>Observação guia com procedimento separado</label>


            <p>{values.observacaoSeparacaoGuia}</p>
          </FormGroup>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(DadosObservacaoAnexosForm));
