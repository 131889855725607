import * as _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import FormInput from "../../../../components/Inputs/FormInput";
import InputViewEdit from "../../../../components/Inputs/InputViewEdit";
import SelectableInput from "../../../../sascComponents/inputs/SelectableInput";
import { FormGroup } from "../../../../components/FormGroup";
import SelectInput from "../../../../components/Inputs/SelectInput";
import { procedimentoService } from "../../../../services/procedimento.service";
import { objectsConstants } from "../../../../constants/objects.constants";
import { atendimentoService } from "../../../../services/atendimento.service";
import { Modal, ModalBody } from "reactstrap";
import fechar from "../../../../img/icon_fechar_BLACK.svg";
import { convenioService } from "../../../../services/convenio.service";

class DadosSolicitacaoProcedimentoGuiaForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      toogleModalMedicoPrincipal: false,
      exibirMedicoPrincipal: true,
      copyMedico: false,
    };
  }

  toogleModalMedicoPrincipal = () => {
    if (
      this.state.toogleModalMedicoPrincipal ||
      this.state.exibirMedicoPrincipal
    ) {
      this.setState({
        toogleModalMedicoPrincipal: !this.state.toogleModalMedicoPrincipal,
        exibirMedicoPrincipal: false,
      });
    } else {
      this.addProcedimento();
    }
  };

  addProcedimento = () => {
    const { values, setValues } = this.props;
    let newValues = _.cloneDeep(values);
    const medicoPrincipal = this.state.copyMedico;
    let numero = newValues.atendimentoProcedimentos.length + 1;
    newValues.atendimentoProcedimentos.push({
      idList: new Date().getTime(),
      numero,
    });
    let enviarPj = values.estabelecimento.enviarClinica;
    // newValues.atendimentoExecucaos.push({ idList: new Date().getTime(), numero, type: objectsConstants.ENUM_TIPOS_GUIA.SADT });
    if (!medicoPrincipal) {
      newValues.identificacaoAtendimentoExecucao.push({
        idList: new Date().getTime(),
        numero,
        type: objectsConstants.ENUM_TIPOS_GUIA.INTERNACAO,
        enviarPj,
      });
    } else {
      newValues.identificacaoAtendimentoExecucao.push({
        idList: new Date().getTime(),
        numero,
        type: objectsConstants.ENUM_TIPOS_GUIA.INTERNACAO,
        cooperado: values.cooperado,
        enviarPj,
      });
    }
    setValues(newValues);
  };

  removeProcedimento = (indexProcedimento) => {
    const { values, setValues } = this.props;
    let newValues = _.cloneDeep(values);
    let procedimentoExecucao =
      newValues.atendimentoProcedimentos[indexProcedimento];
    newValues.atendimentoProcedimentos.splice(indexProcedimento, 1);
    //remove execucao relacionada
    if (newValues.atendimentoExecucaos[indexProcedimento])
      _.remove(newValues.atendimentoExecucaos, {
        numero: procedimentoExecucao.numero,
      });
    newValues.identificacaoAtendimentoExecucao.splice(indexProcedimento, 1);
    //se remover todos os elementos add um padrão
    if (!newValues.identificacaoAtendimentoExecucao.length) {
      newValues.identificacaoAtendimentoExecucao = [
        {
          numero: 1,
          codigoCbo: null,
          grau: "",
        },
      ];
    }
    //reordena lista de execucao para bater index
    newValues.identificacaoAtendimentoExecucao.map(
      (identificacao, indexIdentificacao) => {
        if (indexProcedimento < identificacao.numero) {
          identificacao.numero =
            identificacao.numero > 1 ? identificacao.numero - 1 : 1;
        }
      }
    );
    //reordena lista de dados execucao para bater index
    newValues.atendimentoExecucaos.map(
      (atendimentoExecucao, indexIdentificacao) => {
        if (indexProcedimento < atendimentoExecucao.numero) {
          atendimentoExecucao.numero =
            atendimentoExecucao.numero > 1 ? atendimentoExecucao.numero - 1 : 1;
        }
      }
    );
    newValues.atendimentoProcedimentos.map(
      (atendimentoExecucao, indexIdentificacao) => {
        atendimentoExecucao.numero = indexIdentificacao + 1;
      }
    );
    setValues(newValues);
    this.props.clear();
    this.props.info({
      message: `Procedimento removido com sucesso!`,
    });
  };

  alocarQuantidade = (values, date) => {
    let quant = null;
    values.convenio.listRegrasProcedimentoTipoGuia.forEach((r) => {
      if (values.type.includes(r.tipoGuia.nome))
        if (r.procedimentos.some((p) => p.codigo === date.codigo)) {
          if (quant === null || quant < r.quantidade) quant = r.quantidade;
        }
    });
    return quant;
  };

  alocarAcomodacao = (values, date) => {
    let acomodacao = null;
    values.convenio.listRegrasProcedimentoTipoGuia.forEach((r) => {
      if (values.type.includes(r.tipoGuia.nome))
        if (r.procedimentos.some((p) => p.codigo === date.codigo)) {
          if (acomodacao === null || acomodacao !== r.acomodacao)
            acomodacao = r.acomodacao;
        }
    });
    return acomodacao;
  };

  validarProcedimento = (values, codigo) => {
    if (values.convenio && values.convenio.listRegrasProcedimentoTipoGuia) {
      let validacao = values.convenio.listRegrasProcedimentoTipoGuia.some(
        (r) => {
          if (values.type.includes(r.tipoGuia.nome))
            return r.procedimentos.some((p) => p.codigo === codigo);
          return false;
        }
      );
      return validacao;
    }
  };

  render() {
    const { setFieldValue, values, viewMode, errors, setValues } = this.props;
    if (
      values.atendimentoProcedimentos == null ||
      values.atendimentoProcedimentos.length == 0
    ) {
      setFieldValue(
        "atendimentoProcedimentos",
        objectsConstants.INIT_ATENDIMENTO_PROCEDIMENTO
      );
    }
    return (
      <React.Fragment>
        <Modal
          isOpen={this.state.toogleModalMedicoPrincipal}
          toggle={this.toggle}
          backdrop="static"
          modalClassName=""
          className=""
          centered={true}
        >
          <ModalBody>
            <div className="modalCooperado">
              <div className="row">
                <div className="col-10 text-center my-2">
                  <h2 className="font-weight-bold">Dados Profissional</h2>
                </div>
                <div className="col-2">
                  <a
                    onClick={() => {
                      this.toogleModalMedicoPrincipal();
                    }}
                    className="btn-fechar d-flex justify-content-end"
                  >
                    <img src={fechar} alt="fechar" className="pointer" />
                  </a>
                </div>
              </div>
              <div className="row">
                <div className="flex-grow px-3 m-auto justify-content-center">
                  <label className="font-12px">
                    Deseja que os dados do profissional selecionado
                    anteriormente sejam os mesmos para o próximo procedimento?
                  </label>
                </div>
                <div className="flex-grow px-3 m-auto  pt-3 justify-content-center">
                  <button
                    type="submit"
                    className="btn btn-primary mr-2"
                    disabled={false}
                    onClick={() => {
                      this.setState({ copyMedico: true }, () => {
                        this.toogleModalMedicoPrincipal();
                        this.addProcedimento();
                      });
                    }}
                  >
                    {"SIM"}
                  </button>
                  <button
                    type="submit"
                    className="btn btn-danger "
                    disabled={false}
                    onClick={() => {
                      this.setState({ copyMedico: false }, () => {
                        this.toogleModalMedicoPrincipal();
                        this.addProcedimento();
                      });
                    }}
                  >
                    {"NAO"}
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <div className="row section-form">
          <h6 className="w-100">Procedimentos solicitados</h6>
          {values &&
            values.atendimentoProcedimentos &&
            values.atendimentoProcedimentos.map(
              (procedimento, indexProcedimento) => {
                return (
                  <React.Fragment>
                    <div
                      className="quebra"
                      key={procedimento.codigoProcedimento}
                    ></div>
                    <FormGroup className="central fixo">
                      <label>Nº</label>
                      <p>{indexProcedimento + 1}</p>
                    </FormGroup>
                    <SelectableInput
                      viewMode={viewMode}
                      className="central fixo6"
                      name={`atendimentoProcedimentos[${indexProcedimento}].tabela`}
                      label={"Tabela"}
                      value={procedimento.tabela}
                      id={values.id}
                    >
                      <InputViewEdit
                        label={"Tabela"}
                        required={true}
                        component={FormInput}
                        onChange={(name, date) => {
                          setFieldValue(name, date);
                        }}
                        viewMode={viewMode}
                        name={`atendimentoProcedimentos[${indexProcedimento}].tabela`}
                        id={`atendimentoProcedimentos${indexProcedimento}tabela`}
                        erroMensagem={_.get(
                          errors,
                          `atendimentoProcedimentos[${indexProcedimento}].tabela`
                        )}
                        placeholder=""
                        type={"text"}
                        value={procedimento.tabela}
                        alertMensagem={
                          values.convenio
                            ? values.convenio.listRegraMensagemTipoGuia
                            : null
                        }
                      />
                    </SelectableInput>
                    <SelectableInput
                      viewMode={viewMode}
                      className="fixo2 mobile-100"
                      name={`atendimentoProcedimentos[${indexProcedimento}].codigoProcedimento`}
                      label={"Código do procedimento"}
                      value={procedimento.codigoProcedimento}
                      id={values.id}
                    >
                      <InputViewEdit
                        label={"Código do procedimento"}
                        component={SelectInput}
                        onChange={async (name, date) => {
                          let grauRegra = "";
                          if (
                            values.type &&
                            values.convenio &&
                              values.identificacaoAtendimentoExecucao[indexProcedimento]
                                .cooperado &&
                                values.identificacaoAtendimentoExecucao[indexProcedimento]
                                  .cooperado.id
                          ) {
                            let listProcedimentos = [];
                            listProcedimentos.push(date.codigo);
                            let idCooperado = values.identificacaoAtendimentoExecucao[indexProcedimento].cooperado &&
                            values.identificacaoAtendimentoExecucao[indexProcedimento].cooperado.id;
                            
                            console.log(values)
                            await convenioService
                              .findRegraEspecialidadeProcedimentoPorTipoGuia(
                                values.type,
                                values.convenio,
                                idCooperado,
                                listProcedimentos
                              )
                              
                              .then((response) => {
                                if (
                                  response.data.viaAcesso ||
                                  response.data.grau
                                ) {
                                  grauRegra = response.data.grau
                                    ? response.data.grau.grau
                                    : "";
                                } else {
                                  grauRegra = "";
                                }
                              });
                          }
                          if (values.type != null && date.codigo != null) {
                            let newValue = _.cloneDeep(values);

                            _.map(
                              newValue.atendimentoExecucaos,
                              (ae, index) => {
                                if (
                                  ae.numero == indexProcedimento + 1 ||
                                  (!ae.numero && indexProcedimento == 0)
                                ) {
                                  if (
                                    date &&
                                    date.procedimentoCooperativaList &&
                                    date.procedimentoCooperativaList.length >
                                      0 &&
                                    date.procedimentoCooperativaList[0]
                                      .quantidadePadrao
                                  ) {
                                    _.set(
                                      newValue,
                                      `atendimentoExecucaos[${index}].quantidade`,
                                      date.procedimentoCooperativaList[0]
                                        .quantidadePadrao
                                    );
                                  }
                                  _.set(
                                    newValue,
                                    `atendimentoExecucaos[${index}].procedimento`,
                                    date
                                  );
                                  _.set(
                                    newValue,
                                    `atendimentoExecucaos[${index}].codigoProcedimento`,
                                    date.codigo
                                  );
                                  _.set(
                                    newValue,
                                    `atendimentoExecucaos[${index}].descricao`,
                                    date.descricao
                                  );
                                  if (
                                    values.cooperativa &&
                                    values.cooperativa
                                      .sufixProcedimentoObrigatoriedadeGrau12
                                  ) {
                                    if (
                                      date.codigo.startsWith(
                                        values.cooperativa
                                          .sufixProcedimentoObrigatoriedadeGrau12
                                      )
                                    ) {
                                      _.set(
                                        newValue,
                                        `atendimentoExecucaos[${index}].via`,
                                        null
                                      );
                                    }
                                  }
                                }
                              }
                            );
                            _.map(
                              newValue.identificacaoAtendimentoExecucao,
                              (iae, index) => {
                                if (
                                  iae.numero == indexProcedimento + 1 ||
                                  (!iae.numero && indexProcedimento == 0)
                                ) {
                                  _.set(
                                    newValue,
                                    `identificacaoAtendimentoExecucao[${index}].codigoProcedimento`,
                                    date.codigo
                                  );

                                  if (
                                    values.cooperativa &&
                                    values.cooperativa
                                      .sufixProcedimentoObrigatoriedadeGrau12
                                  ) {
                                    if (
                                      date.codigo.startsWith(
                                        values.cooperativa
                                          .sufixProcedimentoObrigatoriedadeGrau12
                                      )
                                    ) {
                                      _.set(
                                        newValue,
                                        `identificacaoAtendimentoExecucao[${index}].grau`,
                                        null
                                      );
                                      _.set(
                                        newValue,
                                        `identificacaoAtendimentoExecucao[${index}].disabled`,
                                        true
                                      );
                                    } else {
                                      _.set(
                                        newValue,
                                        `identificacaoAtendimentoExecucao[${index}].grau`,
                                        null
                                      );
                                      _.set(
                                        newValue,
                                        `identificacaoAtendimentoExecucao[${index}].disabled`,
                                        false
                                      );
                                    }
                                  }
                                }
                              }
                            );
                            if (
                              date &&
                              date.procedimentoCooperativaList &&
                              date.procedimentoCooperativaList.length > 0 &&
                              date.procedimentoCooperativaList[0]
                                .quantidadePadrao
                            ) {
                              _.set(
                                newValue,
                                `atendimentoProcedimentos[${indexProcedimento}].quantAutorizada`,
                                date.procedimentoCooperativaList[0]
                                  .quantidadePadrao
                              );
                              _.set(
                                newValue,
                                `atendimentoProcedimentos[${indexProcedimento}].quantSolicitada`,
                                date.procedimentoCooperativaList[0]
                                  .quantidadePadrao
                              );
                            }

                            _.set(
                              newValue,
                              `atendimentoProcedimentos[${indexProcedimento}].procedimento`,
                              date
                            );
                            _.set(
                              newValue,
                              `atendimentoProcedimentos[${indexProcedimento}].codigoProcedimento`,
                              date.codigo
                            );
                            _.set(
                              newValue,
                              `atendimentoProcedimentos[${indexProcedimento}].descricao`,
                              date.descricao
                            );

                            if (this.validarProcedimento(values, date.codigo)) {
                              const quant = this.alocarQuantidade(values, date);
                              const acomodacao = this.alocarAcomodacao(
                                values,
                                date
                              );

                              _.set(
                                newValue,
                                `guiaInternacaoOpms[${indexProcedimento}].codigoProcedimento`,
                                date.codigo
                              );

                              _.set(
                                newValue,
                                `guiaInternacaoOpms[${indexProcedimento}].quantidade`,
                                quant
                              );
                              _.set(
                                newValue,
                                `atendimentoProcedimentos[${indexProcedimento}].quantSolicitada`,
                                quant
                              );
                              _.set(
                                newValue,
                                `atendimentoProcedimentos[${indexProcedimento}].quantAutorizada`,
                                quant
                              );
                              if (acomodacao) {
                                _.set(newValue, `acomodacao`, acomodacao);
                              }
                            } else {
                              _.set(
                                newValue,
                                `guiaInternacaoOpms[${indexProcedimento}].quantidade`,
                                ""
                              );
                              _.set(
                                newValue,
                                `atendimentoProcedimentos[${indexProcedimento}].quantSolicitada`,
                                ""
                              );
                              _.set(
                                newValue,
                                `atendimentoProcedimentos[${indexProcedimento}].quantAutorizada`,
                                ""
                              );
                            }

                            //DEMANDA 1021-000246
                            if (values.convenio) {
                              atendimentoService
                                .findGuiaPadrao(
                                  values.type,
                                  values.convenio,
                                  date.codigo
                                )
                                .then((response) => {
                                  const guiaPadrao = response.data;
                                  _.forOwn(guiaPadrao, function (value, key) {
                                    if (value) {
                                      if (_.isArray(value)) {
                                        _.forOwn(
                                          value[0],
                                          function (valueArray, keyArray) {
                                            _.set(
                                              newValue,
                                              `${key}[${indexProcedimento}].${keyArray}`,
                                              valueArray
                                            );
                                          }
                                        );
                                      } else {
                                        if (!newValue[key]) {
                                          _.set(newValue, key, value);
                                        }
                                      }
                                    }
                                  });
                                  setValues(newValue);
                                  setFieldValue(
                                    `identificacaoAtendimentoExecucao[${indexProcedimento}].grau`,
                                    grauRegra
                                  );
                                })
                                .catch((response) => {
                                  setValues(newValue);
                                  setFieldValue(
                                    `identificacaoAtendimentoExecucao[${indexProcedimento}].grau`,
                                    grauRegra
                                  );
                                });
                            } else {
                              setValues(newValue);
                              setFieldValue(
                                `identificacaoAtendimentoExecucao[${indexProcedimento}].grau`,
                                grauRegra
                              );
                            }
                          } else {
                            setFieldValue(
                              `atendimentoProcedimentos[${indexProcedimento}].procedimento`,
                              date
                            );
                            setFieldValue(
                              `atendimentoProcedimentos[${indexProcedimento}].codigoProcedimento`,
                              date.codigo
                            );
                            setFieldValue(
                              `atendimentoProcedimentos[${indexProcedimento}].descricao`,
                              date.descricao
                            );
                          }
                        }}
                        viewMode={viewMode}
                        required={true}
                        name={`atendimentoProcedimentos[${indexProcedimento}].codigoProcedimento`}
                        placeholder="Digite o código"
                        type={"text"}
                        value={{
                          codigo:
                            values.atendimentoProcedimentos[indexProcedimento]
                              .codigoProcedimento,
                        }}
                        defaultValue={
                          values.atendimentoProcedimentos[indexProcedimento]
                            .codigoProcedimento
                        }
                        id={`atendimentoProcedimentos${indexProcedimento}codigoProcedimento`}
                        erroMensagem={_.get(
                          errors,
                          `atendimentoProcedimentos[${indexProcedimento}].codigoProcedimento`
                        )}
                        labelKey={"codigo"}
                        valueKey={"codigo"}
                        returnFullObject={true}
                        parent={{
                          convenio: values.convenio,
                          tipoGuia: values.type,
                        }}
                        onFetchData={
                          procedimentoService.findByCodigoConvenioTipoGuia
                        }
                        alertMensagem={
                          values.convenio
                            ? values.convenio.listRegraMensagemTipoGuia
                            : null
                        }
                        noResultsText={
                          "Cooperativa não possui credenciamento para cobrança. Convênio possui locais próprios."
                        }
                      />
                    </SelectableInput>
                    <SelectableInput
                      viewMode={viewMode}
                      className=""
                      name={`atendimentoProcedimentos[${indexProcedimento}].descricao`}
                      label={"Descrição"}
                      value={procedimento.descricao}
                      id={values.id}
                    >
                      <InputViewEdit
                        label={"Descrição"}
                        required={true}
                        component={FormInput}
                        onChange={(name, date) => {
                          setFieldValue(name, date);
                        }}
                        viewMode={viewMode}
                        name={`atendimentoProcedimentos[${indexProcedimento}].descricao`}
                        id={`atendimentoProcedimentos${indexProcedimento}descricao`}
                        erroMensagem={_.get(
                          errors,
                          `atendimentoProcedimentos[${indexProcedimento}].descricao`
                        )}
                        placeholder=""
                        type={"text"}
                        value={_.get(
                          values,
                          `atendimentoProcedimentos[${indexProcedimento}].descricao`
                        )}
                        disabled={
                          procedimento &&
                          procedimento.procedimento &&
                          procedimento.descricao &&
                          true
                        }
                        alertMensagem={
                          values.convenio
                            ? values.convenio.listRegraMensagemTipoGuia
                            : null
                        }
                      />
                    </SelectableInput>
                    <SelectableInput
                      viewMode={viewMode}
                      className="central"
                      name={`atendimentoProcedimentos[${indexProcedimento}].quantSolicitada`}
                      label={"Qtde. Solic."}
                      value={procedimento.quantSolicitada}
                      id={values.id}
                    >
                      <InputViewEdit
                        label={"Qtde. Solic."}
                        required={true}
                        component={FormInput}
                        className={"destaque"}
                        onChange={(name, date) => {
                          setFieldValue(name, date);
                        }}
                        viewMode={viewMode}
                        type={"number"}
                        value={procedimento.quantSolicitada}
                        disabled={
                          procedimento &&
                          procedimento.quantSolicitada &&
                          values.convenio !== undefined &&
                          this.validarProcedimento(
                            values,
                            procedimento.codigoProcedimento
                          ) &&
                          true
                        }
                        name={`atendimentoProcedimentos[${indexProcedimento}].quantSolicitada`}
                        id={`atendimentoProcedimentos${indexProcedimento}quantSolicitada`}
                        placeholder=""
                        erroMensagem={_.get(
                          errors,
                          `atendimentoProcedimentos[${indexProcedimento}].quantSolicitada`
                        )}
                        alertMensagem={
                          values.convenio
                            ? values.convenio.listRegraMensagemTipoGuia
                            : null
                        }
                      />
                    </SelectableInput>
                    <SelectableInput
                      viewMode={viewMode}
                      className="central"
                      name={`atendimentoProcedimentos[${indexProcedimento}].quantAutorizada`}
                      label={"Qtde. Aut."}
                      value={procedimento.quantAutorizada}
                      id={values.id}
                    >
                      <InputViewEdit
                        label={"Qtde. Aut."}
                        required={true}
                        component={FormInput}
                        className={"destaque"}
                        onChange={(name, date) => {
                          setFieldValue(name, date);
                        }}
                        viewMode={viewMode}
                        name={`atendimentoProcedimentos[${indexProcedimento}].quantAutorizada`}
                        id={`atendimentoProcedimentos${indexProcedimento}quantAutorizada`}
                        disabled={
                          procedimento &&
                          procedimento.quantAutorizada &&
                          values.convenio !== undefined &&
                          this.validarProcedimento(
                            values,
                            procedimento.codigoProcedimento
                          ) &&
                          true
                        }
                        erroMensagem={_.get(
                          errors,
                          `atendimentoProcedimentos[${indexProcedimento}].quantAutorizada`
                        )}
                        placeholder=""
                        type={"number"}
                        value={procedimento.quantAutorizada}
                        alertMensagem={
                          values.convenio
                            ? values.convenio.listRegraMensagemTipoGuia
                            : null
                        }
                      />
                    </SelectableInput>
                    {!viewMode && (
                      <React.Fragment>
                        <FormGroup className="central fixo mobile-100">
                          {indexProcedimento + 1 !==
                            values.atendimentoProcedimentos.length && (
                            <React.Fragment>
                              <div className="mt-25 btn-acoes d-none d-sm-flex flex-column align-self-center pl-0 pl-sm-3">
                                <button
                                  className="btn-menos"
                                  onClick={() => {
                                    this.removeProcedimento(indexProcedimento);
                                  }}
                                ></button>
                              </div>
                              <div className="bg-branco d-block d-sm-none col-12 p-2 text-center">
                                <button
                                  className="btn-menos"
                                  onClick={() => {
                                    this.removeProcedimento(indexProcedimento);
                                  }}
                                ></button>
                              </div>
                            </React.Fragment>
                          )}
                          {indexProcedimento + 1 ===
                            values.atendimentoProcedimentos.length && (
                            <React.Fragment>
                              <div className="mt-25 btn-acoes d-none d-sm-flex flex-column align-self-center pl-0 pl-sm-3">
                                {values.atendimentoProcedimentos.length > 1 && (
                                  <button
                                    className="btn-menos"
                                    onClick={() => {
                                      this.removeProcedimento(
                                        indexProcedimento
                                      );
                                    }}
                                  ></button>
                                )}
                                <button
                                  className="btn-mais"
                                  onClick={this.toogleModalMedicoPrincipal}
                                ></button>
                              </div>
                              <div className="bg-branco d-block d-sm-none col-12 p-2 text-center">
                                <button
                                  className="btn-mais"
                                  onClick={this.toogleModalMedicoPrincipal}
                                ></button>
                              </div>
                            </React.Fragment>
                          )}
                        </FormGroup>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                );
              }
            )}
        </div>
      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  menu: { showMenu, hideMenu },
  alert: { info, clear },
}) => ({
  info: (msg) => info(msg),
  clear: () => clear(),
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu(),
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions,
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(DadosSolicitacaoProcedimentoGuiaForm));
