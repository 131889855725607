import * as Yup from "yup";
import { convenioService } from "../services/convenio.service";
import _ from "lodash";
import moment from "moment";
import { conselhoService } from "../services/conselho.service";
import { regraProcedimnetoBloqueadosGuiaHospitalService } from "../services/RegrasProcedimentosBloqueadosGuiaHospital.service";

const ENUM_TIPOS_GUIA = {
  CONSULTA: "guiaConsulta",
  HONORARIO: "guiaHonorarios",
  INTERNACAO: "guiaInternacao",
  SADT: "guiaSADT"
}
export const enumTipoErrors = {
  ATENDIMENTO_CONVENIO_NAO_ENCONTRADO: "Convenio não encontrado.",
  ATENDIMENTO_ESTABELECIMENTO_NAO_ENCONTRADO: "Consultório não encontrado.",
  ATENDIMENTO_COOPERADO_NAO_ENCONTRADO: "Cooperado não encontrado.",
  ATENDIMENTO_NAO_PODE_ENCAMINHAR_PROTOCOLO: "Os atendimentos não podem ser encaminhados para Protocolo, pois existem pendências nos atendimentos. Atendimentos: ",
  ATENDIMENTO_NAO_PODE_ENCAMINHAR_DUPLICADO: "Existe atendimentos já encaminhados para recebido",
  CARTEIRA_PACIENTE_BLOQUEADA: "Paciente sem cobertura para atendimento, favor realizar a elegibilidade ou entrar em contato com a operadora",
  PACIENTE_NAO_CADASTRADO: "Este beneficiário ainda não foi cadastrado. Solicite e preencha todas as informações do beneficiário.",
  PACIENTE_NAO_ELEGIVEL: "Paciente sem cobertura para atendimento, favor realizar a elegibilidade ou entrar em contato com a operadora."
};

export const enumTipoAvisos = {
  PROCEDIMENTO_BLOQUEADO_GUIA_HOSPITAL: "Atenção, esse código de procedimento não é válido para o hospital e convênio selecionados."
}

export const enumTipoMsgSucesso = {
  ATENDIMENTOS_ENCAMINHADOS: "Alteração realizada com sucesso"
};

export const enumTipoMsgAviso = {
  GUIA_AUTOMATICAMENTE_ENCAMINHADA_COOPERATIVA :"Este tipo de guia é encaminhado automaticamente para a cooperativa, portanto não é necessário encaminhar a guia física para a cooperativa."
}

export const objectsConstantsCodigoProcedimento = {
  CODIGO_PROCEDIMENTO_CONSULTA1: "10101012",
  CODIGO_PROCEDIMENTO_CONSULTA2: "10101039",
  ID_COOPMEDRS: 2,
  TIPO_GUIA: "guiaSADT"
};

export const cooperativa = {
  FELICOOP: 'Felicoop',
  COOPMEDRS: 'COOPMED-RS',
  COOPANESTRIO: 'COOPANESTRIO',
  COOPIMIMG: 'COOPIMIMG',
  SCCOOPER: 'SCCOOPER',
  SANTACOOP: 'SANTACOOP',
  COOPANESTTM: 'COOPANESTTM',
  COOPANESTAL: 'COOPANESTAL',
  COOPANESTMT: 'COOPANESTMT',
  SINGULIER: 'SINGULIER'
}

export const cooperativaID = {
  SANTACOOP: { nome: 'SANTACOOP', id: 6 }
}


const AJUDA_AVANCAR_DEFAULT =
  "Insira abaixo todas as informações obrigatórias(*) e clique em avançar";
const SALVAR_TEXT = "Salvar";
const BENEFICIARIO_NAO_CADASTRADO =
  "Este beneficiário ainda não foi cadastrado. Solicite e preencha todas as informações do beneficiário.";
const HELPER_AVANCAR_SALVAR =
  "Para finalizar o cadastro do atendimento, clique em salvar.";

const buildRequiredForObervacao = requiredText => ({
  is: true,
  then: Yup.string().required(requiredText)
});

const TIPO_RETORNO = "RETORNO";

const ENUM_SEXO = [{ label: 'Masculino', value: 'MASCULINO' }, { label: 'Feminino', value: 'FEMININO' }];

const verificarNumeroCarteirinha = (numeroCarteira, convenio) => {
  const listaPrefixos = convenio.listCaracteresInicioCarteirinha.map(prefixo => prefixo.caracteres);
  if (listaPrefixos && listaPrefixos.length > 0) {
    const inciaCom = listaPrefixos.some(prefixo => numeroCarteira.startsWith(prefixo.toString()));
    return inciaCom;
  }
  return true;
}

export const objectsConstants = {
  TIPO_ATENDIMENTO_CONSULTA: { name: 'CONSULTA', description: 'Consulta', codigo: 4 },
  SEPARAR_PROCEDIMENTOS: 'SEPARAR_PROCEDIMENTOS',
  GLOBAL: 'GLOBAL',
  CODIGO_PROCEDIMENTO_CONSULTA: "10101012",
  RECEBIMENTO_PROTOCOLO: 'RECEBIMENTO_PROTOCOLO',
  ID_AMIL_COOPMEDRS: 261,
  ALTERAR_TIPO_GUIA: "ALTERAR_TIPO_GUIA",
  N_GUIA_FILTER: "numeroGuia",
  MODELO_VISUALIZACAO_PADRAO: "Modelo Padrão",
  N_ATENDIMENTO_FILTER: "numeroAtendimento",
  BENEFICIARIO_FILTER: "beneficiario",
  STATUS_ENCAMINHAMENTO_FILTER: "statusEncaminhamento",
  CONVENIO_FILTER: "convenios",
  CONSULTORIO_FILTER: "estabelecimento",
  COOPERADO_FILTER: "cooperado",
  GUIA_RECEBIDA: "Guia recebida",
  ENCAMINHADO_GUIA_FISICA_PARA_COOPERATIVA: "Encaminhado guia física para cooperativa",
  PENDENTE: "Pendente",
  DUPLICIDADE: "Atendimento em duplicidade",
  ATENDIMENTO_DUPLICADO_SALVAR: 'ATENDIMENTO_DUPLICADO_SALVAR',
  ATENDIMENTO_DUPLICADO_NAO_SALVAR: 'ATENDIMENTO_DUPLICADO_NAO_SALVAR',
  ATENDIMENTO_SEM_DUPLICIDADE: 'ATENDIMENTO_SEM_DUPLICIDADE',
  INCONSISTENCIA_CARTEIRINHA: "Inconsistência de carteirinha",
  EVOLUCAO_CLINICA_FILTER: "possuiEvolucaoClinica",
  TIPOS_VALIDACAO: [
    { description: "Alfanumérico", name: "ALFANUMERICO" },
    { description: "Numérico", name: "NUMERICO" }
  ],
  GRAUS_CIRURGIA: [
    '00', '01', '02', '03', '04', '13'
  ],
  TIPOS_VALIDACAO_CARTEIRINHA: [
    { description: "Alfanumérico", name: "ALFANUMERICO" },
    { description: "Numérico", name: "NUMERICO" },
    { description: "Numérico incluindo ponto(.)", name: "NUMERICO_INCLUINDO_PONTOS" },
    { description: "Alfanumérico incluindo ponto(.)", name: "ALFANUMERICO_INCLUINDO_PONTOS" }
  ],
  VIA_UNICA: 'Única',
  NOTIFICACAO_CONSULTORIO: 'NOTIFICACAO_CONSULTORIO',
  NOTIFICACAO_COOPERADO: 'NOTIFICACAO_COOPERADO',

  AVISO_SISTEMA_GERAL: 'AVISO_SISTEMA_GERAL',
  NOTIFICACAO_REMOCAO_GUIA_PROTOCOLO: 'Atendimento removido do protocolo',
  LIST_FIELDS_GUIA_SADT: [
    { fieldName: "convenio", fieldDescription: "Convênio", type: "OBJECT" },
    {
      fieldName: "registroAns",
      fieldDescription: "Registro ANS",
      type: "STRING"
    },
    {
      fieldName: "dataAutorizacao",
      fieldDescription: "Data da autorização",
      type: "DATE"
    },
    { fieldName: "senha", fieldDescription: "Senha", type: "STRING" },
    {
      fieldName: "dataValidadeSenha",
      fieldDescription: "Data de validade da senha",
      type: "DATE"
    },
    {
      fieldName: "numeroAtendimento",
      fieldDescription: "Nº do atendimento",
      type: "STRING"
    },
    {
      fieldName: "numeroCarteira",
      fieldDescription: "Número da carteira",
      type: "STRING"
    },
    {
      fieldName: "dataValidadeCarteira",
      fieldDescription: "Data de validade da carteira",
      type: "DATE"
    },
    {
      fieldName: "cpf",
      fieldDescription: "CPF",
      type: "STRING"
    },
    {
      fieldName: "dataNascimento",
      fieldDescription: "Data de nascimento",
      type: "DATE"
    },
    {
      fieldName: "nomeBeneficiario",
      fieldDescription: "Nome do beneficiário",
      type: "STRING"
    },
    {
      fieldName: "cartaoNacionalSaude",
      fieldDescription: "Cartão nacional de saúde",
      type: "STRING"
    },
    {
      fieldName: "atendimentoRn",
      fieldDescription: "Atendimento a RN",
      type: "STRING"
    },
    {
      fieldName: "codigoOperadora",
      fieldDescription: "Código na operadora",
      type: "STRING"
    },
    {
      fieldName: "nome",
      fieldDescription: "Nome do contratado",
      type: "STRING"
    },
    { fieldName: "cnes", fieldDescription: "Código CNES", type: "STRING" },
    {
      fieldName: "conselho",
      fieldDescription: "Conselho profissional",
      type: "STRING"
    },
    { fieldName: "ufConselho", fieldDescription: "UF", type: "STRING" },
    {
      fieldName: "cooperado",
      fieldDescription: "Nome do profissional",
      type: "OBJECT"
    },
    {
      fieldName: "codigoCboSolicitante",
      fieldDescription: "Código CBO",
      type: "STRING"
    },
    {
      fieldName: "caraterAtendimento",
      fieldDescription: "Caráter do Atendimento",
      type: "STRING"
    },
    {
      fieldName: "dataSolicitacao",
      fieldDescription: "Data da solicitação",
      type: "DATE"
    },
    {
      fieldName: "indicacaoClinica",
      fieldDescription: "Indicação clínica",
      type: "STRING"
    },
    { fieldName: "tabela", fieldDescription: "Tabela", type: "STRING" },
    {
      fieldName: "codigoProcedimento",
      fieldDescription: "Código do procedimento",
      type: "STRING"
    },
    { fieldName: "descricao", fieldDescription: "Descrição", type: "STRING" },
    {
      fieldName: "quantSolicitada",
      fieldDescription: "Qtde. Solic",
      type: "STRING"
    },
    {
      fieldName: "quantAutorizada",
      fieldDescription: "Qtde. Aut",
      type: "STRING"
    },
    {
      fieldName: "codigoOperadoraContratada",
      fieldDescription: "Código na operadora",
      type: "STRING"
    },
    {
      fieldName: "nomeContratado",
      fieldDescription: "Nome do contratado",
      type: "STRING"
    },
    {
      fieldName: "indicacaoAcidente",
      fieldDescription:
        "Indicação de Acidente (acidente ou doença relacionada)",
      type: "STRING"
    },
    {
      fieldName: "tipoConsulta",
      fieldDescription: "Tipo de consulta",
      type: "OBJECT"
    },
    {
      fieldName: "motivoEncerramentoAtendimento",
      fieldDescription: "Motivo de encerramento do Atendimento",
      type: "STRING"
    },
    {
      fieldName: "dataAtendimento",
      fieldDescription: "Data atendimento",
      type: "DATE"
    },
    {
      fieldName: "horaInicial",
      fieldDescription: "Hora inicial",
      type: "HOUR"
    },
    { fieldName: "horaFinal", fieldDescription: "Hora final", type: "HOUR" },
    { fieldName: "tabela", fieldDescription: "Tabela", type: "STRING" },
    {
      fieldName: "codigoProcedimento",
      fieldDescription: "Código do procedimento",
      type: "STRING"
    },
    { fieldName: "quantAutorizada", fieldDescription: "Qtde", type: "STRING" },
    { fieldName: "via", fieldDescription: "Via", type: "STRING" },
    { fieldName: "tec", fieldDescription: "Tec", type: "STRING" },
    { fieldName: "fator", fieldDescription: "Fator", type: "STRING" },
    {
      fieldName: "valorUnitario",
      fieldDescription: "Valor unit",
      type: "STRING"
    },
    { fieldName: "grau", fieldDescription: "Grau", type: "STRING" },
    {
      fieldName: "cpf",
      fieldDescription: "Código Operadora / CPF",
      type: "STRING"
    },
    {
      fieldName: "conselho",
      fieldDescription: "Conselho profissional",
      type: "STRING"
    },
    { fieldName: "uf", fieldDescription: "UF", type: "STRING" },
    {
      fieldName: "numero",
      fieldDescription: "Número do conselho",
      type: "STRING"
    },
    {
      fieldName: "cooperado",
      fieldDescription: "Nome do profissional",
      type: "OBJECT"
    },
    { fieldName: "codigoCbo", fieldDescription: "Código CBO", type: "STRING" },
    {
      fieldName: "value35",
      fieldDescription: "Total do procedimento",
      type: "STRING"
    },
    {
      fieldName: "value36",
      fieldDescription: "Total de taxas e aluguéis",
      type: "STRING"
    },
    {
      fieldName: "value37",
      fieldDescription: "Total de materias",
      type: "STRING"
    },
    { fieldName: "value38", fieldDescription: "Total de OPME", type: "STRING" },
    {
      fieldName: "value39",
      fieldDescription: "Total de medicamentos",
      type: "STRING"
    },
    {
      fieldName: "value40",
      fieldDescription: "Total de gases medicinais",
      type: "STRING"
    },
    {
      fieldName: "_.get(convenio, 'quantMinDigitosCarteirinha',0)",
      fieldDescription: "Digitos minimos carteirinha",
      type: "INTEGER"
    }
  ],
  INIT_ATENDIMENTO_PROCEDIMENTO: [
    {
      codigoProcedimento: " ", numero: 1
    }
  ],
  validateGuiaSADT: Yup.object().shape({
    convenio: Yup.object().shape({ id: Yup.string().nullable().required("Obrigatório") }),
    complementoConvenio: Yup.string().nullable().test('complementoConvenio', 'Obrigatório', function () {
      const { parent, createError } = this;
      let convenio = parent.convenio
      if (convenio && convenioService.complementoObrigatorio(convenio.tiposGuiaProduto, parent.type) && !parent.complementoConvenio) {
        return createError();
      } else {
        return true;
      }
    }),
    hospitalLocal: Yup.object().shape({ id: Yup.string().nullable().required("Obrigatório") }).nullable()
      .required('Obrigatório'),
    envioEletronico: Yup.string().nullable(true).test('valid-anexo', 'Anexo Obrigatório', function () {
      const { parent, createError } = this;
      if (parent.envioEletronico === 'true' && (!parent.atendimentoAnexos || parent.atendimentoAnexos.length < 1)) {
        return createError({
          path: "envioEletronico",
          message: 'É necessário anexar a guia física ao marcar a opção de envio realizado eletronicamente.'
        });
      }
      return true;
    }),
    senha: Yup.string().nullable().test('valid-senha', 'Senha obrigatório', function () {
      const { parent, options, createError } = this;
      let convenio = parent.convenio
      const plano = options && options.context && options.context.values && options.context.values.plano ? this.options.context.values.plano : null;
      if (convenio && parent.caraterAtendimento && convenioService.isSenhaObrigatoria(convenio.tiposGuiaSenhaObrigatoria, ENUM_TIPOS_GUIA.SADT, convenio.listaRegrasSenhaConvenio, parent.caraterAtendimento, parent.atendimentoExecucaos, plano) && (!parent.senha || parent.senha == '')) {
        return createError({
          path: "senha",
          message: 'Senha obrigatório'
        });
      } else {
        if (convenio && parent.senha && parent.senha != '' && convenioService.validaQtdDigitosSenha(parent.senha, convenio.listaRegrasSenhaConvenio, ENUM_TIPOS_GUIA.SADT)) {
          return createError({
            path: "senha",
            message: 'Quantidade de digitos errada, para a esse convenio é necessario ' + convenioService.qtdDigitosSenha(convenio.listaRegrasSenhaConvenio, ENUM_TIPOS_GUIA.SADT) + ' caracteres.'
          });
        }
        return true;
      }
    }),

    dataValidadeCarteira:

      Yup.string().when('convenio', (convenio, schema) => {
        return convenio && convenio.validadeCarteira ? Yup.string().nullable().required("Obrigatório") : schema.nullable();
      }),

    plano: Yup.string().nullable(true).test('plano', 'Obrigatório', function () {
      const { parent, createError } = this;
      if (parent.convenio && parent.convenio.planoObrigatorio === true && (!parent.plano || parent.plano === null)) {
        return createError({
          path: "plano",
          message: 'Obrigatório'
        });
      }
      return true;
    }),

    cpf: Yup.string().when('convenio', (convenio, schema) => {
      return convenio && convenioService.isCPFObrigatoria(ENUM_TIPOS_GUIA.SADT, convenio.listaRegrasSenhaConvenio) ? Yup.string().nullable().required("Obrigatório") : schema.nullable();
    }),

    dataNascimento: Yup.string().when('convenio', (convenio, schema) => {
      return convenio && convenioService.isDataNascimentoObrigatoria(ENUM_TIPOS_GUIA.SADT, convenio.listaRegrasSenhaConvenio) ? Yup.string().nullable().required("Obrigatório") : schema.nullable();
    }),

    // Dados do Beneficiário
    numeroCarteira: Yup.string().nullable().required("Obrigatório").test('valid-senha', 'Quantidade de digitos incorreta', function () {
      const { parent, createError } = this;
      let convenio = parent.convenio
      const listaPrefixos = convenio.listCaracteresInicioCarteirinha.map(prefixo => prefixo.caracteres);
      if (convenio && convenioService.validaDigitosCarteirinha(convenio, parent.numeroCarteira)) {
        return createError();
      } else if (convenio && !verificarNumeroCarteirinha(parent.numeroCarteira, convenio)) {
        return createError({
          path: "numeroCarteira",
          message: `O número da carteira deve iniciar com o(s) caracteres necessários -> ${listaPrefixos.join(', ')}`
        });
      } else {
        return true;
      }
   }).test('validate-paciente-portal', 'Paciente sem cobertura para atendimento, favor realizar a elegibilidade ou entrar em contato com a operadora.', async function(value){
    const { parent } = this;
    let convenio = parent.convenio
    let id = parent.id;
    if(!id){
      let isPacienteElegivel = await convenioService.isPacienteElegivelConvenio(convenio, value);
      if(isPacienteElegivel.data && !isPacienteElegivel.data.success){
        return false;
      }
    }
    return true;
 }),
    numeroGuia: Yup.string().when('convenio', (convenio, schema) => {

      return convenio && convenio.numeroGuiaObrigatoria === false ? Yup.string().nullable() : Yup.string().nullable().required("Obrigatório");
    }),
    atendimentoAnexos: Yup.array().test('valid-anexo', 'Campo obrigatório', function () {
      const { parent, createError } = this;
      let atendimentoExecucaos = parent.atendimentoExecucaos;
      let convenio = parent.convenio;
      let identificacaoAtendimentoExecucao = parent.identificacaoAtendimentoExecucao;
      let guiaFisicaObrigatoria = false;
      const graus = this.options.context.graus;


      if (atendimentoExecucaos) {
        for (let i = 0; i < atendimentoExecucaos.length; i++) {
          if (atendimentoExecucaos[i].procedimento && atendimentoExecucaos[i].procedimento.procedimentoCooperativaList) {
            for (let j = 0; j < atendimentoExecucaos[i].procedimento.procedimentoCooperativaList.length; j++) {
              if (atendimentoExecucaos[i].procedimento.procedimentoCooperativaList[j] && atendimentoExecucaos[i].procedimento.procedimentoCooperativaList[j].guiaFisicaObrigatoria) {
                guiaFisicaObrigatoria = true;
              }
            }
          }
        }
      }
      if (atendimentoExecucaos && convenio && convenio.procedimentoCooperativaConvenioList) {
        for (let w = 0; w < convenio.procedimentoCooperativaConvenioList.length; w++) {
          for (let z = 0; z < atendimentoExecucaos.length; z++) {
            if (convenio.procedimentoCooperativaConvenioList[w] && atendimentoExecucaos[z].procedimento && atendimentoExecucaos[z].procedimento && convenio.procedimentoCooperativaConvenioList[w].procedimento && atendimentoExecucaos[z].procedimento.codigo == convenio.procedimentoCooperativaConvenioList[w].procedimento.codigo && convenio.procedimentoCooperativaConvenioList[w].guiaFisicaObrigatoria) {
              guiaFisicaObrigatoria = true;
            }
          }
        }
      }
      if (guiaFisicaObrigatoria && (!parent.atendimentoAnexos || parent.atendimentoAnexos.length < 1)) {
        return false;
      }
      return true;
    }),

    nomeBeneficiario: Yup.string().nullable().required("Obrigatório"),
    //END Passo 1
    //Passo 2
    // Dados do Solicitante
    codigoOperadoraContratada: Yup.string().nullable().required("Obrigatório"),
    nomeContratado: Yup.string().nullable().required("Obrigatório"),
    cnesContratado: Yup.string().nullable().required("Obrigatório"),
    cooperado: Yup.object().shape({
      /*id: Yup.string().nullable().required("Obrigatório"),
      ufConselho: Yup.string().nullable().required("Obrigatório"),
      conselho: Yup.object().shape({
        id: Yup.string().nullable().required("Obrigatório"),
        sigla: Yup.string().nullable().required("Obrigatório")
      }),
      numeroConselho: Yup.string().nullable().required("Obrigatório"),
      nome: Yup.string().nullable().required("Obrigatório")*/
    }),
    // Dados da Solicitação / Procedimentos e Exames Solicitados
    caraterAtendimento: Yup.object()
      .nullable().required("Obrigatório")
      .nullable(),
    atendimentoProcedimentos: Yup.array()
      .min(1, "É necessario informar pelo menos um procedimento")
      .of(
        Yup.object().shape({
          codigoProcedimento: Yup.string().nullable().required("Obrigatório"),
          tabela: Yup.string().nullable().required("Obrigatório"),
          descricao: Yup.string().nullable().required("Obrigatório"),
          quantSolicitada: Yup.number().typeError("Obrigatório")
            .nullable().required("Obrigatório")
            .min(1, "Número inválido")
            .min(1, "Digitar quantidade de procedimento maior que zero"),
          quantAutorizada: Yup.number().min(1, "Número inválido").nullable().required("Obrigatório")
            .typeError("Obrigatório")
        })
      ),

    // Dados do atendimento
    /*
        tipoConsulta: Yup.object()
          .nullable().required("Obrigatório")
          .nullable(),
    */

    atendimentoExecucaos: Yup.array()
      .min(1, "É necessario informar pelo menos um procedimento")
      .of(
        Yup.object().shape({
          numero: Yup.string().nullable().required("Obrigatório"),
          dataAtendimento: Yup.string().nullable().required("Obrigatório"),

          //  dataAtendimento: Yup.string().nullable().test('valid-data', 'Obrigatório', function () {
          //    const { parent, createError } = this;
          //let convenio = parent.convenio
          //if (convenio && convenioService.isSenhaObrigatoria(convenio.tiposGuiaSenhaObrigatoria, ENUM_TIPOS_GUIA.INTERNACAO, convenio.listaRegrasSenhaConvenio, parent.caraterAtendimento) && !parent.senha) {
          //  return createError();
          //} else {
          //  return true;
          // }
          //let data = parent.dataAtendimento

          //}),

          horaInicial: Yup.string().nullable().test('valid-horaInicial', 'Campo obrigatório', function (item) {
            const { parent, createError } = this;
            const values = this.options.context.values;
            if (values && values.hospitalLocal && values.hospitalLocal.disableHoraInicioFimExecucao) {
              return true
            }
            return item;
          }),
          horaFinal: Yup.string().nullable().test('valid-horaFinal', 'Campo obrigatório', function (item) {
            const { parent, createError } = this;
            const values = this.options.context.values;
            if (values && values.hospitalLocal && values.hospitalLocal.disableHoraInicioFimExecucao) {
              return true
            }
            return item;
          }),

          quantidade: Yup.number().nullable()
            .required("Obrigatório")
            .typeError("Obrigatório")
            .min(1, "Digitar quantidade de procedimento maior que zero"),

          via: Yup.string().nullable().test('valid-via', 'Campo obrigatório', function (item) {
            const { parent, createError } = this;
            const cooperativa = this.options.context.cooperativa;
            if (cooperativa && cooperativa.sufixProcedimentoObrigatoriedadeGrau12 && parent.codigoProcedimento && parent.codigoProcedimento.startsWith(cooperativa.sufixProcedimentoObrigatoriedadeGrau12)) {
              return true
            }
            if ((!item || item === "") && cooperativa && cooperativa.viaTecnicaObrigatoria) {
              return false
            }
            return true;
          }),
          tec: Yup.string().nullable().test('valid-via', 'Campo obrigatório', function (item) {
            const { parent, createError } = this;
            const cooperativa = this.options.context.cooperativa;
            if ((!item || item === "") && cooperativa && cooperativa.viaTecnicaObrigatoria) {
              return false
            }
            return true;
          }),

        })
      ).test('identificacaoAtendimentoExecucao', 'Obrigatório', function () {
        const { parent, createError } = this;
        let { cooperativa, identificacaoAtendimentoExecucao, atendimentoExecucaos } = parent;
        if (cooperativa.viaUnicaObrigatoriaCirurgia) {
          if (identificacaoAtendimentoExecucao) {
            for (let indexIdentificacao = 0; indexIdentificacao < identificacaoAtendimentoExecucao.length; indexIdentificacao++) {
              let identificacao = identificacaoAtendimentoExecucao[indexIdentificacao];
              let grau = identificacao.grau;
              if (objectsConstants.GRAUS_CIRURGIA.indexOf(grau) >= 0) {
                let viaUnicaPreenchida = false;
                for (let indexExecucao = 0; indexExecucao < atendimentoExecucaos.length; indexExecucao++) {
                  let atendimentoExecucao = atendimentoExecucaos[indexExecucao];
                  if (atendimentoExecucao.via === objectsConstants.VIA_UNICA) {
                    viaUnicaPreenchida = true;
                  }
                }
                if (!viaUnicaPreenchida) {
                  return createError({
                    path: "atendimentoExecucaos[0].via",
                    message: 'Não foi possível salvar o atendimento, pois nenhum procedimento foi cadastrado como via única.'
                  });
                }
              }
            }
          }
        }

        return true;

      }),

    // Dados do atendimento
    // tipoConsulta: Yup.object().shape({
    //   description: Yup.string().required("Obrigatório")
    // }),

    // Identificação do(s) Profissional(is) Executante(s)
    identificacaoAtendimentoExecucao: Yup.array()
      .min(1, "É necessario informar pelo menos uma identificação profissional")
      .of(
        Yup.object().shape({
          numero: Yup.number().typeError("Obrigatório").nullable().required("Obrigatório"),
          grau: Yup.string().nullable().required("Obrigatório"),
          cooperado: Yup.object().shape({
            ufConselho: Yup.string().nullable().required("Obrigatório"),
            conselho: Yup.object().shape({
              sigla: Yup.string().nullable().required("Obrigatório")
            }),
            numeroConselho: Yup.string().nullable().required("Obrigatório"),
            cpf: Yup.string().nullable().required("Obrigatório"),
            nome: Yup.string().nullable().required("Obrigatório")
          })
        })
      )

  }),
  validateGuiaConsulta: Yup.object().shape({
    // Dados do Guia
    convenio: Yup.object().shape({ id: Yup.string().nullable().required("Obrigatório") }),
    complementoConvenio: Yup.string().nullable().test('complementoConvenio', 'Obrigatório', function () {
      const { parent, createError } = this;
      let convenio = parent.convenio

      if (convenio && convenioService.complementoObrigatorio(convenio.tiposGuiaProduto, parent.type) && !parent.complementoConvenio) {
        return createError();
      } else {
        return true;
      }
    }),
    // Dados do Beneficiário
    numeroCarteira: Yup.string().nullable().required("Obrigatório").test('valid-senha', 'Quantidade de digitos incorreta', function () {
      const { parent, createError } = this;
      let convenio = parent.convenio
      const listaPrefixos = convenio.listCaracteresInicioCarteirinha.map(prefixo => prefixo.caracteres);
      if (convenio && convenioService.validaDigitosCarteirinha(convenio, parent.numeroCarteira)) {
        return createError();
      } else if (convenio && !verificarNumeroCarteirinha(parent.numeroCarteira, convenio)) {
        return createError({
          path: "numeroCarteira",
          message: `O número da carteira deve iniciar com o(s) caracteres necessários -> ${listaPrefixos.join(', ')}`
        });
      } else {
        return true;
      }
     })
    .test('validate-paciente-portal', 'Paciente sem cobertura para atendimento, favor realizar a elegibilidade ou entrar em contato com a operadora.', async function(value){
      const { parent } = this;
      let convenio = parent.convenio 
      let id = parent.id;
      if(!id){
        let isPacienteElegivel = await convenioService.isPacienteElegivelConvenio(convenio, value);
        if(isPacienteElegivel.data && !isPacienteElegivel.data.success){
          return false;
        }
      }
        
      return true;
   }),
    numeroGuia: Yup.string().when('convenio', (convenio, schema) => {
      return convenio && convenio.numeroGuiaObrigatoria === false ? Yup.string().nullable() : Yup.string().nullable().required("Obrigatório");
    }),
    envioEletronico: Yup.string().nullable(true).test('valid-anexo', 'Anexo Obrigatório', function () {
      const { parent, createError } = this;
      if (parent.envioEletronico === 'true' && (!parent.atendimentoAnexos || parent.atendimentoAnexos.length < 1)) {
        return createError({
          path: "envioEletronico",
          message: 'É necessário anexar a guia física ao marcar a opção de envio realizado eletronicamente.'
        });
      }
      return true;
    }),
    /*senha: Yup.string().nullable().test('valid-senha', 'Senha obrigatório', function () {
      const { parent, options, createError } = this;
      let convenio = parent.convenio ? parent.convenio :  null;
      const type = parent.type ? objectsConstants.TIPOS_GUIA.find(element => element.label == parent.type).id : null;
      const procedimentos = parent.atendimentoExecucaos ? parent.atendimentoExecucaos.map(element => element.codigoProcedimento) : null;      
      const caraterAtendimento = parent.caraterAtendimento ? parent.caraterAtendimento.name : null;
      const plano = options && options.context && options.context.values && options.context.values.plano ? this.options.context.values.plano : null;
      return convenioService.isSenhaObrigatoria(convenio, type, procedimentos, caraterAtendimento, plano).then(
        response => {
          if (response.data && (!parent.senha || parent.senha == '')) {
            return createError({
              path: "senha",
              message: 'Senha obrigatório'
            });
          } else if (response.data && parent.senha && parent.senha != '' && convenioService.validaQtdDigitosSenha(parent.senha, convenio.listaRegrasSenhaConvenio, ENUM_TIPOS_GUIA.SADT)) {
            return createError({
              path: "senha",
              message: 'Quantidade de digitos errada, para a esse convenio é necessario ' + convenioService.qtdDigitosSenha(convenio.listaRegrasSenhaConvenio, ENUM_TIPOS_GUIA.SADT) + ' caracteres.'
            });
          }
          return true;
        },
        error => {
          console.error(error);
        }
      );
    }),*/
    senha: Yup.string().nullable().test('valid-senha', 'Senha obrigatório', function () {
      const { parent, options, createError } = this;
      let convenio = parent.convenio;
      const plano = options && options.context && options.context.values && options.context.values.plano ? this.options.context.values.plano : null;
      if (convenio && convenioService.isSenhaObrigatoria(convenio.tiposGuiaSenhaObrigatoria, ENUM_TIPOS_GUIA.CONSULTA, convenio.listaRegrasSenhaConvenio, parent.caraterAtendimento, parent.atendimentoExecucaos, plano) && (!parent.senha || parent.senha === '')) {
        return createError();
      } else {
        if (convenio && parent.senha && parent.senha != '' && convenioService.validaQtdDigitosSenha(parent.senha, convenio.listaRegrasSenhaConvenio, ENUM_TIPOS_GUIA.CONSULTA)) {
          return createError({
            path: "senha",
            message: 'Quantidade de digitos errada, para a esse convenio é necessario ' + convenioService.qtdDigitosSenha(convenio.listaRegrasSenhaConvenio, ENUM_TIPOS_GUIA.CONSULTA) + ' caracteres.'
          });
        }
        return true;
      }
    }),

    dataValidadeCarteira: Yup.string().when('convenio', (convenio, schema) => {
      return convenio && convenio.validadeCarteira ? Yup.string().nullable().required("Obrigatório") : schema.nullable();
    }),

    plano: Yup.string().nullable(true).test('plano', 'Obrigatório', function () {
      const { parent, createError } = this;
      if (parent.convenio && parent.convenio.planoObrigatorio === true && (!parent.plano || parent.plano === null)) {
        return createError({
          path: "plano",
          message: 'Obrigatório'
        });
      }
      return true;
    }),

    cpf: Yup.string().when('convenio', (convenio, schema) => {
      return convenio && convenioService.isCPFObrigatoria(ENUM_TIPOS_GUIA.CONSULTA, convenio.listaRegrasSenhaConvenio) ? Yup.string().nullable().required("Obrigatório") : schema.nullable();
    }),

    dataNascimento: Yup.string().when('convenio', (convenio, schema) => {
      return convenio && convenioService.isDataNascimentoObrigatoria(ENUM_TIPOS_GUIA.CONSULTA, convenio.listaRegrasSenhaConvenio) ? Yup.string().nullable().required("Obrigatório") : schema.nullable();
    }),
    nomeBeneficiario: Yup.string().nullable().required("Obrigatório"),

    //hospitalLocal: Yup.object().shape({ id: Yup.string().nullable().required("Obrigatório") }).nullable()
    //  .required('Obrigatório'),

    hospitalLocal: Yup.string().when('cooperativa', (cooperativa, schema) => {
      return cooperativa && cooperativa.utilizaHospitalLocal == true ? Yup.string().nullable().required("Obrigatório") : schema.nullable();
    }),


    // Dados do Solicitante
    codigoOperadoraContratada: Yup.string().nullable().required("Obrigatório"),
    nomeContratado: Yup.string().nullable().required("Obrigatório"),
    cnesContratado: Yup.string().nullable().required("Obrigatório"),
    cooperado: Yup.object().shape({
      id: Yup.string().nullable().required("Obrigatório"),
      ufConselho: Yup.string().nullable().required("Obrigatório"),
      conselho: Yup.object().shape({
        id: Yup.string().nullable().required("Obrigatório"),
        sigla: Yup.string().nullable().required("Obrigatório")
      }),
      numeroConselho: Yup.string().nullable().required("Obrigatório"),
      nome: Yup.string().nullable().required("Obrigatório")
    }),
    // Dados da Solicitação / Procedimentos e Exames Solicitados
    atendimentoExecucaos: Yup.array()
      .min(1, "É necessario informar pelo menos um procedimento")
      .of(
        Yup.object().shape({
          codigoProcedimento: Yup.string().nullable().required("Obrigatório"),
          tabela: Yup.string().nullable().required("Obrigatório"),
          dataAtendimento: Yup.string().nullable().required("Obrigatório"),

        })
      ),

    /*atendimentoExecucaos: Yup.array()
  .min(1, 'É necessário informar pelo menos um procedimento')
  .of(
    Yup.object().shape({
      codigoProcedimento: Yup.string().nullable().required('Obrigatório'),
      tabela: Yup.string().nullable().required('Obrigatório'),
      dataAtendimento: Yup.string().nullable().required('Obrigatório'),
    })
  )
  .test('firstElement', 'É necessário informar pelo menos um procedimento', function (value) {
    if (!value || value.length === 0) {
      // Se o array estiver vazio, não há nada para validar
      return true;
    }

    const firstElement = value[0];

    const procedimentoSchema = Yup.object().shape({
      codigoProcedimento: Yup.string().nullable().required('Obrigatório'),
      tabela: Yup.string().nullable().required('Obrigatório'),
      dataAtendimento: Yup.string().nullable().required('Obrigatório'),
    });

    try {
      procedimentoSchema.validateSync(firstElement);
      return true;
    } catch (error) {
      return false;
    }
  }),*/

    // Dados do atendimento
  }),
  validateHonorarios: Yup.object().shape({
    // Dados do Guia
    convenio: Yup.object().shape({ id: Yup.string().nullable().required("Obrigatório") }),
    // registroAns: Yup.string().nullable().required("Obrigatório"),
    // senha: Valida Senha de acordo com configuração do convenio
    /*senha: Yup.string().when('convenio', (convenio, schema) => {
      return convenio && !convenioService.isSenhaObrigatoria(convenio.tiposGuiaSenhaObrigatoria,ENUM_TIPOS_GUIA.HONORARIO) ? Yup.string().nullable() : Yup.string().nullable().nullable().required("Obrigatório");
    }),*/
    complementoConvenio: Yup.string().nullable().test('complementoConvenio', 'Obrigatório', function () {
      const { parent, createError } = this;
      let convenio = parent.convenio

      if (convenio && convenioService.complementoObrigatorio(convenio.tiposGuiaProduto, parent.type) && !parent.complementoConvenio) {
        return createError();
      } else {
        return true;
      }
    }),
    envioEletronico: Yup.string().nullable(true).test('valid-anexo', 'Anexo Obrigatório', function () {
      const { parent, createError } = this;
      
      if(parent.cooperativa.sigla == cooperativa.COOPMEDRS && parent.type === "guiaHonorarios"){
        return true;
      }
      if (parent.envioEletronico === 'true' && (!parent.atendimentoAnexos || parent.atendimentoAnexos.length < 1)) {
        return createError({
          path: "envioEletronico",
          message: 'É necessário anexar a guia física ao marcar a opção de envio realizado eletronicamente.'
        });
      }
      return true;
    }),
    /*senha: Yup.string().nullable(true).test('valid-senha', 'Senha obrigatório', function () {
      const { parent, options, createError } = this;
      let convenio = parent.convenio ? parent.convenio :  null;
      const type = parent.type ? objectsConstants.TIPOS_GUIA.find(element => element.label == parent.type).id : null;
      const procedimentos = parent.atendimentoExecucaos ? parent.atendimentoExecucaos.map(element => element.codigoProcedimento) : null;      
      const caraterAtendimento = parent.caraterAtendimento ? parent.caraterAtendimento.name : null;
      const plano = options && options.context && options.context.values && options.context.values.plano ? this.options.context.values.plano : null;
      return convenioService.isSenhaObrigatoria(convenio, type, procedimentos, caraterAtendimento, plano).then(
        response => {
          console.log('teste 12', parent.senha ? parent.senha : null);

          if (response.data && (!parent.senha || parent.senha == '')) {
            return createError({
              path: "senha",
              message: 'Senha obrigatório'
            });
          } else if (response.data && parent.senha != '' && convenioService.validaQtdDigitosSenha(parent.senha, convenio.listaRegrasSenhaConvenio, ENUM_TIPOS_GUIA.SADT)) {
            return createError({
              path: "senha",
              message: 'Quantidade de digitos errada, para a esse convenio é necessario ' + convenioService.qtdDigitosSenha(convenio.listaRegrasSenhaConvenio, ENUM_TIPOS_GUIA.SADT) + ' caracteres.'
            });
          }
          return true;
        },
        error => {
          console.error(error);
        }
      );
    }),*/
    senha: Yup.string().nullable(true).test('valid-senha', 'Senha obrigatório', function () {
      const { parent, options, createError } = this;
      let convenio = parent.convenio
      const plano = options && options.context && options.context.values && options.context.values.plano ? this.options.context.values.plano : null;
      if (convenio && convenioService.isSenhaObrigatoria(convenio.tiposGuiaSenhaObrigatoria, ENUM_TIPOS_GUIA.HONORARIO, convenio.listaRegrasSenhaConvenio, parent.caraterAtendimento, parent.atendimentoExecucaos, plano) && (!parent.senha || parent.senha === '')) {
        return createError();
      } else {
        if (convenio && parent.senha && parent.senha != '' && convenioService.validaQtdDigitosSenha(parent.senha, convenio.listaRegrasSenhaConvenio, ENUM_TIPOS_GUIA.HONORARIO)) {
          return createError({
            path: "senha",
            message: 'Quantidade de digitos errada, para a esse convenio é necessario ' + convenioService.qtdDigitosSenha(convenio.listaRegrasSenhaConvenio, ENUM_TIPOS_GUIA.HONORARIO) + ' caracteres.'
          });
        }
        return true;
      }
    }),


    hospitalLocal: Yup.object().shape({ id: Yup.string().nullable().required("Obrigatório") }).nullable()
      .required('Obrigatório'),

    atendimentoAnexos: Yup.array().test('valid-anexo', 'Campo obrigatório', function () {
      const { parent, createError } = this;
      let atendimentoExecucaos = parent.atendimentoExecucaos;
      let convenio = parent.convenio;
      let identificacaoAtendimentoExecucao = parent.identificacaoAtendimentoExecucao;
      var guiaFisicaObrigatoria = false;
      const graus = this.options.context.graus;

      if (atendimentoExecucaos) {
        for (let i = 0; i < atendimentoExecucaos.length; i++) {
          if (atendimentoExecucaos[i].procedimento && atendimentoExecucaos[i].procedimento.procedimentoCooperativaList) {
            for (let j = 0; j < atendimentoExecucaos[i].procedimento.procedimentoCooperativaList.length; j++) {
              if (atendimentoExecucaos[i].procedimento.procedimentoCooperativaList[j] && atendimentoExecucaos[i].procedimento.procedimentoCooperativaList[j].guiaFisicaObrigatoria) {
                guiaFisicaObrigatoria = true;
              }
            }
          }
        }
      }
      if (atendimentoExecucaos && convenio && convenio.procedimentoCooperativaConvenioList) {
        for (let w = 0; w < convenio.procedimentoCooperativaConvenioList.length; w++) {
          for (let z = 0; z < atendimentoExecucaos.length; z++) {
            if (convenio.procedimentoCooperativaConvenioList[w] && atendimentoExecucaos[z].procedimento && convenio.procedimentoCooperativaConvenioList[w].procedimento && atendimentoExecucaos[z].procedimento.codigo == convenio.procedimentoCooperativaConvenioList[w].procedimento.codigo && convenio.procedimentoCooperativaConvenioList[w].guiaFisicaObrigatoria) {
              guiaFisicaObrigatoria = true;
            }
          }
        }
      }

      /*   if (identificacaoAtendimentoExecucao) {
           for (let w = 0; w < identificacaoAtendimentoExecucao.length; w++) {

             if(identificacaoAtendimentoExecucao[w].grau){
               for(let i =0; i<graus.length ; i++){
                 if(graus[i].grau===identificacaoAtendimentoExecucao[w].grau && graus[i].guiaFisicaObrigatoria){
                   guiaFisicaObrigatoria = true;
                 }
               }



             }

           }
         }*/
      if (guiaFisicaObrigatoria && (!parent.atendimentoAnexos || parent.atendimentoAnexos.length < 1)) {
        return false;
      }
      return true;
    }),
    anexoEncaminhado: Yup.string().nullable().test(
      'valid-file', 
      'Anexo é obrigatório para a guia de honorário', 
      function (value) {
        const { parent, createError } = this;
        if (parent.cooperativa.permiteEnvioEletronicoComAnexoGuiaHonorario) {
          const possuiId = parent.id;
          const dataCadastro = new Date(parent.dataCadastro);
          dataCadastro.setHours(0, 0, 0, 0);
          const dataImplTipoAnexo = objectsConstants.DATE_IMPL_GUIA_HONORARIO_ENVIO_ELETRONICO_AUTOMATICO;
          if (!possuiId || (possuiId && dataCadastro && dataCadastro >= dataImplTipoAnexo)) {
            if (value == null) {
              return createError({
                path: "anexoEncaminhado",
                message: "Obrigatório"
              });
            }
            if (
              value === "false" &&
              parent.type === "guiaHonorarios" &&
              (!parent.atendimentoAnexos || parent.atendimentoAnexos.length < 1)
            ) {
              return createError({
                path: "anexoEncaminhado",
                message: "Não foi possível salvar o atendimento! Para guia de honorário o envio de anexo é obrigatório."
              });
            }
          }
        }
        return true;
      }
    ),
    // Dados do Beneficiário
    numeroCarteira: Yup.string().nullable().required("Obrigatório").test('valid-senha', 'Quantidade de digitos incorreta', function () {
      const { parent, createError } = this;
      let convenio = parent.convenio
      const listaPrefixos = convenio.listCaracteresInicioCarteirinha.map(prefixo => prefixo.caracteres);
      if (convenio && convenioService.validaDigitosCarteirinha(convenio, parent.numeroCarteira)) {
        return createError();
      } else if (convenio && !verificarNumeroCarteirinha(parent.numeroCarteira, convenio)) {
        return createError({
          path: "numeroCarteira",
          message: `O número da carteira deve iniciar com o(s) caracteres necessários -> ${listaPrefixos.join(', ')}`
        });
      } else {
        return true;
      }
     }).test('validate-paciente-portal', 'Paciente sem cobertura para atendimento, favor realizar a elegibilidade ou entrar em contato com a operadora.', async function(value){
      const { parent } = this;
      let convenio = parent.convenio;
      let id = parent.id;
      if(!id){
        let isPacienteElegivel = await convenioService.isPacienteElegivelConvenio(convenio, value);
        if(isPacienteElegivel.data && !isPacienteElegivel.data.success){
          return false;
        }
      }
      return true;
   }),

    plano: Yup.string().nullable(true).test('plano', 'Obrigatório', function () {
      const { parent, createError } = this;
      if (parent.convenio && parent.convenio.planoObrigatorio === true && (!parent.plano || parent.plano === null)) {
        return createError({
          path: "plano",
          message: 'Obrigatório'
        });
      }
      return true;
    }),

    cpf: Yup.string().when('convenio', (convenio, schema) => {
      return convenio && convenioService.isCPFObrigatoria(ENUM_TIPOS_GUIA.HONORARIO, convenio.listaRegrasSenhaConvenio) ? Yup.string().nullable().required("Obrigatório") : schema.nullable();
    }),

    dataNascimento: Yup.string().when('convenio', (convenio, schema) => {
      return convenio && convenioService.isDataNascimentoObrigatoria(ENUM_TIPOS_GUIA.HONORARIO, convenio.listaRegrasSenhaConvenio) ? Yup.string().nullable().required("Obrigatório") : schema.nullable();
    }),
    nomeBeneficiario: Yup.string().nullable().required("Obrigatório"),
    numeroGuia: Yup.string().when('convenio', (convenio, schema) => {
      return convenio && convenio.numeroGuiaObrigatoria === false ? Yup.string().nullable() : Yup.string().nullable().required("Obrigatório");
    }),
    /*atendimentoAnexos: Yup.array().when('atendimentoExecucaos', (atendimentoExecucaos, schema) => {

      return false;
    }),*/
    // Dados do Solicitante
    //codigoOperadoraContratada: Yup.string().required("Obrigatório"),
    nomeContratado: Yup.string().nullable().required("Obrigatório"),
    cnesContratado: Yup.string().nullable().required("Obrigatório"),
    // Dados da Solicitação / Procedimentos e Exames Solicitados
    // caraterAtendimento: Yup.string().nullable().required("Obrigatório"),
    acomodacao: Yup.string().nullable().required("Obrigatório"),
    dataIniFaturamento: Yup.date().nullable().required("Obrigatório"),
    dataFimFaturamento: Yup.date().nullable().required("Obrigatório"),
    atendimentoExecucaos: Yup.array()
      .min(1, "É necessario informar pelo menos um procedimento")
      .of(
        Yup.object().shape({
          numero: Yup.string().nullable().required("Obrigatório"),
          dataAtendimento: Yup.string().nullable().required("Obrigatório"),
          horaInicial: Yup.string().nullable().required("Obrigatório"),
          horaFinal: Yup.string().nullable().required("Obrigatório"),
          via: Yup.string().nullable().test('valid-via', 'Campo obrigatório', function (item) {
            const { parent, createError } = this;
            const cooperativa = this.options.context.cooperativa;
            if (cooperativa && cooperativa.sufixProcedimentoObrigatoriedadeGrau12 && parent.codigoProcedimento && parent.codigoProcedimento.startsWith(cooperativa.sufixProcedimentoObrigatoriedadeGrau12)) {
              return true
            }
            if ((!item || item === "") && cooperativa && cooperativa.viaTecnicaObrigatoria) {
              return false
            }
            return true;
          }),
          tec: Yup.string().nullable().test('valid-via', 'Campo obrigatório', function (item) {
            const { parent, createError } = this;
            const cooperativa = this.options.context.cooperativa;
            if ((!item || item === "") && cooperativa && cooperativa.viaTecnicaObrigatoria) {
              return false
            }
            return true;
          }),
          quantidade: Yup.number().nullable()
            .required("Obrigatório").typeError("Obrigatório")
            .min(1, "Digitar quantidade de procedimento maior que zero"),
          codigoProcedimento: Yup.string().nullable().required("Obrigatório")
        })
      ).test('identificacaoAtendimentoExecucao', 'Obrigatório', function () {
        const { parent, createError } = this;
        let { cooperativa, identificacaoAtendimentoExecucao, atendimentoExecucaos } = parent;
        if (cooperativa.viaUnicaObrigatoriaCirurgia) {
          if (identificacaoAtendimentoExecucao) {
            for (let indexIdentificacao = 0; indexIdentificacao < identificacaoAtendimentoExecucao.length; indexIdentificacao++) {
              let identificacao = identificacaoAtendimentoExecucao[indexIdentificacao];
              let grau = identificacao.grau;
              if (objectsConstants.GRAUS_CIRURGIA.indexOf(grau) >= 0) {
                let viaUnicaPreenchida = false;
                for (let indexExecucao = 0; indexExecucao < atendimentoExecucaos.length; indexExecucao++) {
                  let atendimentoExecucao = atendimentoExecucaos[indexExecucao];
                  if (atendimentoExecucao.via === objectsConstants.VIA_UNICA) {
                    viaUnicaPreenchida = true;
                  }
                }
                if (!viaUnicaPreenchida) {
                  return createError({
                    path: "atendimentoExecucaos[0].via",
                    message: 'Não foi possível salvar o atendimento, pois nenhum procedimento foi cadastrado como via única.'
                  });
                }
              }
            }
          }
        }

        return true;

      }),

    // Dados do atendimento
    // tipoConsulta: Yup.object().shape({
    //   description: Yup.string().nullable().required("Obrigatório")
    // }),

    // Identificação do(s) Profissional(is) Executante(s)
    identificacaoAtendimentoExecucao: Yup.array()
      .min(1, "É necessario informar pelo menos uma identificação profissional")
      .of(
        Yup.object().shape({
          numero: Yup.number().nullable().required("Obrigatório").typeError("Obrigatório"),
          grau: Yup.string().nullable().required("Obrigatório"),
          cooperado: Yup.object().shape({
            ufConselho: Yup.string().nullable().required("Obrigatório"),
            conselho: Yup.object().shape({
              sigla: Yup.string().nullable().required("Obrigatório")
            }),
            numeroConselho: Yup.string().nullable().required("Obrigatório"),
            cpf: Yup.string().nullable().required("Obrigatório"),
            nome: Yup.string().nullable().required("Obrigatório")
          })
        })
      )
  }),
  validateGuiaInternacao: Yup.object().shape({
    //Passo 1
    // Dados do Guia
    convenio: Yup.object().shape({ id: Yup.string().nullable().required("Obrigatório") }),
    // registroAns: Yup.string().nullable().required("Obrigatório"),
    /* senha: Yup.string().when('convenio', (convenio, schema) => {
       return convenio && !convenioService.isSenhaObrigatoria(convenio.tiposGuiaSenhaObrigatoria,ENUM_TIPOS_GUIA.INTERNACAO) ? Yup.string().nullable() : Yup.string().nullable().nullable().required("Obrigatório");
     }),*/

    complementoConvenio: Yup.string().nullable().test('complementoConvenio', 'Obrigatório', function () {
      const { parent, createError } = this;
      let convenio = parent.convenio

      if (convenio && convenioService.complementoObrigatorio(convenio.tiposGuiaProduto, parent.type) && !parent.complementoConvenio) {
        return createError();
      } else {
        return true;
      }
    }),
    envioEletronico: Yup.string().nullable(true).test('valid-anexo', 'Anexo Obrigatório', function () {
      const { parent, createError } = this;
      if (parent.envioEletronico === 'true' && (!parent.atendimentoAnexos || parent.atendimentoAnexos.length < 1)) {
        return createError({
          path: "envioEletronico",
          message: 'É necessário anexar a guia física ao marcar a opção de envio realizado eletronicamente.'
        });
      }
      return true;
    }),
    /*senha: Yup.string().nullable().test('valid-senha', 'Senha obrigatório', function () {
      const { parent, options, createError } = this;
      let convenio = parent.convenio ? parent.convenio :  null;
      const type = parent.type ? objectsConstants.TIPOS_GUIA.find(element => element.label == parent.type).id : null;
      const procedimentos = parent.atendimentoExecucaos ? parent.atendimentoExecucaos.map(element => element.codigoProcedimento) : null;      
      const caraterAtendimento = parent.caraterAtendimento ? parent.caraterAtendimento.name : null;
      const plano = options && options.context && options.context.values && options.context.values.plano ? this.options.context.values.plano : null;
      return convenioService.isSenhaObrigatoria(convenio, type, procedimentos, caraterAtendimento, plano).then(
        response => {
          if ( response.data && !parent.senha) {
            return createError();
          } 
          return true;
        },
        error => {
          console.error(error);
        }
      );
    }), */
    senha: Yup.string().nullable().test('valid-senha', 'Senha obrigatório', function () {
      const { parent, options, createError } = this;
      let convenio = parent.convenio
      const plano = options && options.context && options.context.values && options.context.values.plano ? this.options.context.values.plano : null;
      if (convenio && convenioService.isSenhaObrigatoria(convenio.tiposGuiaSenhaObrigatoria, ENUM_TIPOS_GUIA.INTERNACAO, convenio.listaRegrasSenhaConvenio, parent.caraterAtendimento, parent.atendimentoExecucaos, plano) && !parent.senha) {
        return createError();
      } else {
        return true;
      }
    }),

    dataValidadeCarteira: Yup.string().when('convenio', (convenio, schema) => {
      return convenio && convenio.validadeCarteira ? Yup.string().nullable().required("Obrigatório") : schema.nullable();
    }),

    plano: Yup.string().nullable(true).test('plano', 'Obrigatório', function () {
      const { parent, createError } = this;
      if (parent.convenio && parent.convenio.planoObrigatorio === true && (!parent.plano || parent.plano === null)) {
        return createError({
          path: "plano",
          message: 'Obrigatório'
        });
      }
      return true;
    }),

    cpf: Yup.string().when('convenio', (convenio, schema) => {
      return convenio && convenioService.isCPFObrigatoria(ENUM_TIPOS_GUIA.INTERNACAO, convenio.listaRegrasSenhaConvenio) ? Yup.string().nullable().required("Obrigatório") : schema.nullable();
    }),

    dataNascimento: Yup.string().when('convenio', (convenio, schema) => {
      return convenio && convenioService.isDataNascimentoObrigatoria(ENUM_TIPOS_GUIA.INTERNACAO, convenio.listaRegrasSenhaConvenio) ? Yup.string().nullable().required("Obrigatório") : schema.nullable();
    }),

    // Dados do Beneficiário
    numeroCarteira: Yup.string().nullable().required("Obrigatório").test('valid-senha', 'Quantidade de digitos incorreta', function () {
      const { parent, createError } = this;
      let convenio = parent.convenio
      const listaPrefixos = convenio.listCaracteresInicioCarteirinha.map(prefixo => prefixo.caracteres);
      if (convenio && convenioService.validaDigitosCarteirinha(convenio, parent.numeroCarteira)) {
        return createError();
      } else if (convenio && !verificarNumeroCarteirinha(parent.numeroCarteira, convenio)) {
        return createError({
          path: "numeroCarteira",
          message: `O número da carteira deve iniciar com o(s) caracteres necessários -> ${listaPrefixos.join(', ')}`
        });
      } else {
        return true;
      }
     }).test('validate-paciente-portal', 'Paciente sem cobertura para atendimento, favor realizar a elegibilidade ou entrar em contato com a operadora.', async function(value){
      const { parent } = this;
      let convenio = parent.convenio 
      let id = parent.id;
      if(!id){
        let isPacienteElegivel = await convenioService.isPacienteElegivelConvenio(convenio, value);
        if(isPacienteElegivel.data && !isPacienteElegivel.data.success){
          return false;
        }
      }
      return true;
   }),
    numeroGuia: Yup.string().when('convenio', (convenio, schema) => {
      return convenio && convenio.numeroGuiaObrigatoria === false ? Yup.string().nullable() : Yup.string().nullable().required("Obrigatório");
    }),
    nomeBeneficiario: Yup.string().nullable().required("Obrigatório"),
    //END Passo 1
    //Passo 2
    // Dados do Solicitante
    // codigoOperadora: Yup.string().required("Obrigatório"),
    nomeContratado: Yup.string().nullable().required("Obrigatório"),
    cnesContratado: Yup.string().nullable().required("Obrigatório"),
    cooperado: Yup.object().shape({
      id: Yup.string().nullable().required("Obrigatório"),
      ufConselho: Yup.string().nullable().required("Obrigatório"),
      conselho: Yup.object().shape({
        id: Yup.string().nullable().required("Obrigatório"),
        sigla: Yup.string().nullable().required("Obrigatório")
      }),
      numeroConselho: Yup.string().nullable().required("Obrigatório"),
      nome: Yup.string().nullable().required("Obrigatório")
    }),
    identificacaoAtendimentoExecucao: Yup.array()
      .min(1, "É necessario informar pelo menos uma identificação profissional")
      .of(
        Yup.object().shape({
          numero: Yup.number().nullable().required("Obrigatório").typeError("Obrigatório"),
          grau: Yup.string().nullable().test('valid-grau', 'Campo obrigatório', function (item) {
            const { parent, createError } = this;
            const cooperativa = this.options.context.cooperativa;
            if (cooperativa && cooperativa.sufixProcedimentoObrigatoriedadeGrau12 && parent.codigoProcedimento && parent.codigoProcedimento.startsWith(cooperativa.sufixProcedimentoObrigatoriedadeGrau12)) {
              return true
            }
            if ((!item || item === "")) {
              return false
            }
            return true;
          }),
          cooperado: Yup.object().shape({
            ufConselho: Yup.string().nullable().required("Obrigatório"),
            conselho: Yup.object().shape({
              sigla: Yup.string().nullable().required("Obrigatório")
            }),
            numeroConselho: Yup.string().nullable().required("Obrigatório"),
            cpf: Yup.string().nullable().required("Obrigatório"),
            nome: Yup.string().nullable().required("Obrigatório")
          })
        })
      ),

    //Passo 3
    codigoOperadoraContratada: Yup.string().nullable().required("Obrigatório"),
    caraterAtendimento: Yup.object().nullable().required("Obrigatório").nullable(),
    tipoInternacao: Yup.object().nullable().required("Obrigatório").nullable(),
    diariasSolicitadas: Yup.number().nullable().required("Obrigatório").typeError("Obrigatório"),


    // Passo 4

    // Dados da Solicitação / Procedimentos e Exames Solicitados
    // caraterAtendimento: Yup.string()
    //   .nullable().required("Obrigatório")
    //   .nullable(),
    atendimentoProcedimentos: Yup.array()
      .min(1, "É necessario informar pelo menos um procedimento")
      .of(
        Yup.object().shape({
          codigoProcedimento: Yup.string().nullable().required("Obrigatório"),
          tabela: Yup.string().nullable().required("Obrigatório"),
          descricao: Yup.string().nullable().required("Obrigatório"),
          quantSolicitada: Yup.number().typeError("Obrigatório")
            .nullable()
            .required("Obrigatório")
            .min(1, "Digitar quantidade de procedimento maior que zero"),
          quantAutorizada: Yup.number().nullable().required("Obrigatório").typeError("Obrigatório").min(1, "Digitar quantidade de procedimento maior que zero")
        })
      ),
    /*guiaInternacaoOpms: Yup.array()
      .min(1, "É necessario informar pelo menos um procedimento")
      .of(

        Yup.object().shape({
          opm: Yup.object().nullable().shape({
            /!*codigo: Yup.string().required("Obrigatório"),
            descricao: Yup.string().required("Obrigatório")*!/
          }),


        })
      ),*/
    guiaInternacaoCids: Yup.array()
      .min(1, "É necessario informar pelo menos um procedimento")
      .of(
        Yup.object().shape({
          tipoDoenca: Yup.string().nullable().required("Obrigatório"),
          indicacaoAcidente: Yup.string().nullable().required("Obrigatório"),
          tempoDoenca: Yup.string().nullable().required("Obrigatório"),
          tipoTempoDoenca: Yup.string().nullable().required("Obrigatório"),
        })
      )



  }),
  etapasSADT: [
    {
      etapa: 1,
      titulo: "Informe dados do beneficiário",
      subTitutlo:
        "O primeiro passo para a criação de uma guia de atendimento é informar os dados do beneficiário, como Nome e Número da carteira, e os dados do seu convênio.",
      ajudaParaAvancar: AJUDA_AVANCAR_DEFAULT,
      valdations: [
        "convenio",
        "complementoConvenio",
        "senha",
        "numeroCarteira",
        "plano",
        "cpf",
        "dataNascimento",
        "numeroGuia",
        "nomeBeneficiario",
        "dataValidadeCarteira"
      ]
    },
    {
      etapa: 2,
      titulo: "Informe os dados do solicitante e de uma ou mais solicitações",
      subTitutlo:
        "Agora você precisa informar os dados do local, do profissional e das solicitações realizadas neste atendimento.",
      ajudaParaAvancar: AJUDA_AVANCAR_DEFAULT,
      valdations: [
        "convenio",
        "complementoConvenio",
        "senha",
        "numeroCarteira",
        "plano",
        "cpf",
        "dataNascimento",
        "numeroGuia",
        "nomeBeneficiario",
        "cooperado",
        "caraterAtendimento",
        "atendimentoProcedimentos",
        "hospitalLocal",

      ]
    },
    {
      etapa: 3,
      titulo: "Informe os dados do tipo de consulta",
      subTitutlo:
        "Agora você precisa informar os dados do tipo da consulta, se é uma primeira consulta ou retorno do beneficiário.",
      ajudaParaAvancar: AJUDA_AVANCAR_DEFAULT,
      valdations: [
        "convenio",
        "complementoConvenio",
        "senha",
        "numeroCarteira",
        "plano",
        "cpf",
        "dataNascimento",
        "numeroGuia",
        "nomeBeneficiario",
        "cooperado",
        "caraterAtendimento",
        "atendimentoProcedimentos",
        "hospitalLocal",

      ]
    },
    {
      etapa: 4,
      titulo: "Informe os dados da execução e dos profissionais executantes",
      subTitutlo:
        "Informe os dados dos procedimentos realizados, vinculando um ou mais profissionais que executaram o procedimento.",
      ajudaParaAvancar: AJUDA_AVANCAR_DEFAULT,
      valdations: [
        "convenio",
        "complementoConvenio",
        "senha",
        "numeroCarteira",
        "plano",
        "cpf",
        "dataNascimento",
        "numeroGuia",
        "nomeBeneficiario",
        "cooperado",
        "caraterAtendimento",
        "atendimentoProcedimentos",
        "hospitalLocal",
        "atendimentoExecucaos",
        "identificacaoAtendimentoExecucao",

      ]
    },
    {
      etapa: 5,
      titulo: "Observações, justificativas e anexos de guia",
      subTitutlo:
        "Por fim, não menos que importante, insira se necessário observações e justificativas e anexe a guia física assinada para um maior desempenho do faturamento.",
      ajudaParaAvancar: HELPER_AVANCAR_SALVAR,
      labelBtnAvancar: SALVAR_TEXT,
      submit: true,
      valdations: [
        "convenio",
        "complementoConvenio",
        "senha",
        "numeroCarteira",
        "plano",
        "cpf",
        "dataNascimento",
        "numeroGuia",
        "nomeBeneficiario",
        "cooperado",
        "caraterAtendimento",
        "atendimentoProcedimentos",
        "hospitalLocal",
        "atendimentoExecucaos",
        "identificacaoAtendimentoExecucao",
        "envioEletronico",
      ]
    }
  ],
  etapasInternacao: [
    {
      etapa: 1,
      titulo: "Identifique o convênio e o beneficiário",
      subTitutlo:
        "O primeiro passo para a criação de uma guia de atendimento é informar os dados do beneficiário, como Nome e Número da carteira, e os dados do seu convênio.",
      ajudaParaAvancar: AJUDA_AVANCAR_DEFAULT,
      valdations: [
        "convenio",
        "complementoConvenio",
        "senha",
        "numeroGuia",
        "numeroCarteira",
        "plano",
        "cpf",
        "dataNascimento",
        "dataValidadeCarteira",
        "nomeBeneficiario",
      ]
    },
    {
      etapa: 2,
      titulo: "Informe os dados do solicitante e de uma ou mais solicitações",
      subTitutlo:
        "Agora você precisa informar os dados do local, do profissional e das solicitações realizadas neste atendimento.",
      ajudaParaAvancar: AJUDA_AVANCAR_DEFAULT,
      valdations: [
        "convenio",
        "complementoConvenio",
        "senha",
        "numeroGuia",
        "numeroCarteira",
        "plano",
        "cpf",
        "dataNascimento",
        "nomeBeneficiario",
        "cooperado",
      ]
    },
    {
      etapa: 3,
      titulo: "Informe os dados do tipo de consulta",
      subTitutlo:
        "Agora você precisa informar os dados do tipo da consulta, se é uma primeira consulta ou retorno do beneficiário.",
      ajudaParaAvancar: AJUDA_AVANCAR_DEFAULT,
      valdations: [
        "convenio",
        "complementoConvenio",
        "senha",
        "numeroGuia",
        "numeroCarteira",
        "plano",
        "cpf",
        "dataNascimento",
        "nomeBeneficiario",
        "cooperado",
        "codigoOperadoraContratada",
        "nomeContratado",
        "caraterAtendimento",
        "tipoInternacao",
        "diariasSolicitadas",
        "guiaInternacaoCids"

      ]
    },
    {
      etapa: 4,
      titulo: "Informe os dados da execução e dos profissionais executantes",
      subTitutlo:
        "Informe os dados dos procedimentos realizados, vinculando um ou mais profissionais que executaram o procedimento.",
      ajudaParaAvancar: AJUDA_AVANCAR_DEFAULT,
      valdations: [
        "convenio",
        "complementoConvenio",
        "senha",
        "numeroGuia",
        "numeroCarteira",
        "plano",
        "cpf",
        "dataNascimento",
        "nomeBeneficiario",
        "cooperado",
        "codigoOperadoraContratada",
        "nomeContratado",
        "caraterAtendimento",
        "tipoInternacao",
        "diariasSolicitadas",
        "guiaInternacaoCids",
        "atendimentoProcedimentos",

        "identificacaoAtendimentoExecucao",
      ]
    },
    {
      etapa: 5,
      titulo: "Procedimentos solicitados e OPM",
      subTitutlo:
        "Informe os procedimentos e os OPMs solicitados.",
      ajudaParaAvancar: AJUDA_AVANCAR_DEFAULT,
      valdations: [
        "convenio",
        "complementoConvenio",
        "senha",
        "numeroGuia",
        "numeroCarteira",
        "plano",
        "cpf",
        "dataNascimento",
        "nomeBeneficiario",
        "cooperado",
        "codigoOperadoraContratada",
        "nomeContratado",
        "caraterAtendimento",
        "tipoInternacao",
        "diariasSolicitadas",
        "guiaInternacaoCids",
        "atendimentoProcedimentos",
        "identificacaoAtendimentoExecucao",


      ]
    },
    {
      etapa: 6,
      titulo: "Observações, justificativas e anexos de guia",
      subTitutlo:
        "Por fim, não menos que importante, insira se necessário observações e justificativas e anexe a guia física assinada para um maior desempenho do faturamento.",
      ajudaParaAvancar: HELPER_AVANCAR_SALVAR,
      labelBtnAvancar: SALVAR_TEXT,
      submit: true,
      valdations: [
        "convenio",
        "complementoConvenio",
        "senha",
        "numeroGuia",
        "numeroCarteira",
        "plano",
        "cpf",
        "dataNascimento",
        "nomeBeneficiario",

        "cooperado",
        "codigoOperadoraContratada",
        "nomeContratado",
        "caraterAtendimento",
        "tipoInternacao",
        "diariasSolicitadas",
        "guiaInternacaoCids",
        "atendimentoProcedimentos",
        "envioEletronico",
      ]
    }
  ],
  etapasHonorarios: [
    {
      etapa: 1,
      titulo: "Identifique o convênio e o beneficiário",
      subTitutlo:
        "O primeiro passo para a criação de uma guia é informar os dados do beneficiário, como Nome e Número da carteira, e os dados do seu convênio.",
      ajudaParaAvancar: AJUDA_AVANCAR_DEFAULT,
      valdations: [
        "convenio",
        "complementoConvenio",
        "senha",
        "numeroCarteira",
        "plano",
        "cpf",
        "dataNascimento",
        "numeroGuia",
        "nomeBeneficiario",
      ]
    },
    {
      etapa: 2,
      titulo: "Informe os dados do contratado e a data de internação",
      subTitutlo:
        "Agora você precisa informar os dados do contratado de onde foi executado o procedimento e o período de internação.",
      ajudaParaAvancar: AJUDA_AVANCAR_DEFAULT,
      valdations: [
        "convenio",
        "complementoConvenio",
        "senha",
        "numeroCarteira",
        "plano",
        "cpf",
        "dataNascimento",
        "numeroGuia",
        "nomeBeneficiario",
        "hospitalLocal",
        "acomodacao",
        "dataIniFaturamento",
        "dataFimFaturamento",
      ]
    },
    {
      etapa: 3,
      titulo: "Informe os dados da execução e dos profissionais executantes",
      subTitutlo:
        "Informe os dados dos procedimentos realizados, vinculando um ou mais profissionais que executaram o procedimento.",
      ajudaParaAvancar: AJUDA_AVANCAR_DEFAULT,
      valdations: [
        "convenio",
        "complementoConvenio",
        "senha",
        "numeroCarteira",
        "plano",
        "cpf",
        "dataNascimento",
        "numeroGuia",
        "nomeBeneficiario",
        "hospitalLocal",
        "acomodacao",
        "dataIniFaturamento",
        "dataFimFaturamento",
        "atendimentoExecucaos",
        "identificacaoAtendimentoExecucao",
      ]
    },
    {
      etapa: 4,
      titulo: "Observações, justificativas e anexos de guia",
      subTitutlo:
        "Por fim, não menos que importante, insira se necessário observações e justificativas e anexe a guia física assinada para um maior desempenho do faturamento.",
      ajudaParaAvancar: HELPER_AVANCAR_SALVAR,
      labelBtnAvancar: SALVAR_TEXT,
      submit: true,
      valdations: [
        "convenio",
        "complementoConvenio",
        "senha",
        "numeroCarteira",
        "plano",
        "cpf",
        "dataNascimento",
        "numeroGuia",
        "nomeBeneficiario",
        "hospitalLocal",
        "acomodacao",
        "dataIniFaturamento",
        "dataFimFaturamento",
        "atendimentoExecucaos",
        "identificacaoAtendimentoExecucao",
        "envioEletronico",
        "anexoEncaminhado"
      ]
    }
  ],
  etapasConsulta: [
    {
      etapa: 1,
      titulo: "Informe dados do beneficiário",
      subTitutlo:
        "O primeiro passo para a criação de uma guia de atendimento é informar os dados do beneficiário, como Nome e Número da carteira, e os dados do seu convênio.",
      ajudaParaAvancar: AJUDA_AVANCAR_DEFAULT,
      valdations: ["convenio", "complementoConvenio", "senha", "numeroCarteira", "plano", "cpf", "dataNascimento", "nomeBeneficiario", "dataValidadeCarteira"
      ]
    },
    {
      etapa: 2,
      titulo: "Informe os dados do solicitante",
      subTitutlo:
        "Agora você precisa informar os dados do local, do profissional solicitante.",
      ajudaParaAvancar: AJUDA_AVANCAR_DEFAULT,
      valdations: [
        "convenio",
        "complementoConvenio",
        "senha",
        "numeroCarteira",
        "plano",
        "cpf",
        "dataNascimento",
        "nomeBeneficiario",
        "cooperado",
        "hospitalLocal"
      ]
    },
    {
      etapa: 3,
      titulo: "Informe os dados do atendimento",
      subTitutlo:
        "Agora você precisa informar os dados do tipo da consulta, se é uma primeira consulta ou retorno do beneficiário.",
      ajudaParaAvancar: AJUDA_AVANCAR_DEFAULT,
      valdations: [
        "convenio",
        "complementoConvenio",
        "senha",
        "numeroCarteira",
        "plano",
        "cpf",
        "dataNascimento",
        "nomeBeneficiario",

        "cooperado",

      ]
    },
    {
      etapa: 4,
      titulo: "Observações, justificativas e anexos de guia",
      subTitutlo:
        "Por fim, não menos que importante, insira se necessário observações e justificativas e anexe a guia física assinada para um maior desempenho do faturamento.",
      ajudaParaAvancar: HELPER_AVANCAR_SALVAR,
      labelBtnAvancar: SALVAR_TEXT,
      submit: true,
      valdations: [
        "convenio",
        "complementoConvenio",
        "senha",
        "numeroCarteira",
        "plano",
        "cpf",
        "dataNascimento",
        "nomeBeneficiario",

        "cooperado",
        "atendimentoExecucaos",
        "envioEletronico",
      ]
    }
  ],

  PAGE_MAX: 100000000,
  EXECUTAR: "EXECUTAR",
  VISUALIZAR: "VISUALIZAR",
  ALTERAR: "ALTERAR",
  VIEW: "VIEW",
  BTN_USUARIOS: "BTN_USUARIOS",
  BTN_ATENDIMENTOS: "BTN_ATENDIMENTOS",
  BTN_COOPERATIVAS: "BTN_COOPERATIVAS",
  CRUD_GUIAS: "CRUD_GUIAS",
  CRUD_ATENDIMENTOS_DELETAR: "CRUD_ATENDIMENTOS_DELETAR",
  BTN_NOTIFICACOES: "BTN_NOTIFICACOES",
  BTN_CONSULTORIOS: "BTN_CONSULTORIOS",
  BTN_NOTIFICACOES_CONSULTORIOS: "BTN_NOTIFICACOES_CONSULTORIOS",
  BTN_CONVENIOS: "BTN_CONVENIOS",
  CRUDUSUARIO: "CRUDUSUARIO",
  BTN_PERFIL: "BTN_PERFIL",
  BTN_EXAME: "BTN_EXAME",
  BTN_INTEGRACAO: "BTN_INTEGRACAO",
  BTN_AVISOS: "BTN_AVISOS",
  BTN_COOPERADOS: "BTN_COOPERADOS",
  STRING: "STRING",
  CRUDCONSULTORIOS_DADOS: "CRUDCONSULTORIOS_DADOS",
  CRUDCONSULTORIOS_CADASTRAR: "CRUDCONSULTORIOS_CADASTRAR",
  CRUDCOOPERATIVAS_CADASTRAR: "CRUDCOOPERATIVAS_CADASTRAR",
  CRUDNOTIFICACONSULTORIOS_CADASTRAR: "CRUDNOTIFICACONSULTORIOS_CADASTRAR",
  CRUDUSUARIO_DADOS: "CRUDUSUARIO_DADOS",
  CRUDCOOPERADOS_DADOS: "CRUDCOOPERADOS_DADOS",
  CRUD_ATENDIMENTOS_CRIAR: "CRUD_ATENDIMENTOS_CRIAR",
  CRUD_ATENDIMENTOS_EDITAR: "CRUD_ATENDIMENTOS_EDITAR",
  CRUD_PENDENCIA_CRIAR: "CRUD_PENDENCIA_CRIAR",
  CRUD_PENDENCIA_RESOLVER: "CRUD_PENDENCIA_RESOLVER",
  CRUD_LOTES_CRIAR: "CRUD_LOTES_CRIAR",
  CRUD_LOTES_VIEW: "CRUD_LOTES_VIEW",
  TYPE_EXCLUIR: "TYPE_EXCLUIR",
  TYPE_WARNING: "TYPE_WARNING",
  TYPE_CANCEL: "TYPE_CANCEL",
  TYPE_FORWARD: "TYPE_FORWARD",
  TYPE_NEW_LOTE: "TYPE_NEW_LOTE",
  TYPE_ADD_IN_LOTE: "TYPE_ADD_IN_LOTE",
  TYPE_RETORNO: "TYPE_RETORNO",
  ATIVO: "Ativo",
  INATIVO: "Inativo",
  NAO_VINCULADO: "Não vinculado",
  TIPO_CONSULTA_RETORNO: TIPO_RETORNO,
  NAO_FATURADO: "Não faturado",
  FATURAMENTO_PARTICULAR: "Faturamento Particular",
  GUIA_RECUSADA: "Guia recusada",
  GUIA_ARQUIVADAS: "Guias Arquivadas",
  GUIASADT: 'guiaSADT',
  TIPOS_CONSULTA: [
    { description: "Consulta", name: "CONSULTA" },
    { description: "Primeira consulta", name: "PRIMEIRA_CONSULTA" },
    { description: "Retorno", name: TIPO_RETORNO },
    { description: "Reconsulta", name: "RECONSULTA" }
  ],
  TIPO_RECONSULTA: {
    description: "Reconsulta",
    name: "RECONSULTA"
  },

  INDICACAO_ACIDENTE: [
    { description: "Não acidente", name: "NAO_ACIDENTE" },
  ],
  MENSAGEM_TIPO: [
    { name: "Profissional", label: "cooperado", id: 0 },
    { name: "Especialidade", label: "especialidade", id: 1 }
  ],
  MENSAGEM_CATEGORIA: [
    { name: "Exames", label: "exames", id: 1 }
  ],
  TIPOS_GUIA: [
    { name: "SADT", label: "guiaSADT", id: 0 },
    { name: "Honorário", label: "guiaHonorarios", id: 1 },
    { name: "Consulta", label: "guiaConsulta", id: 2 },
    { name: "Solicitação de internação", label: "guiaInternacao", id: 3 }
  ],
  TIPOS_GUIA_RELATORIO: [
    { name: "SADT", label: "guiaSADT", id: 4 },
    { name: "Honorário", label: "guiaHonorarios", id: 1 },
    { name: "Consulta", label: "guiaConsulta", id: 2 },
    { name: "Solicitação de internação", label: "guiaInternacao", id: 3 }
  ],
  TIPOS_GUIA_CONSULTORIO: [
    { name: "SADT", label: "guiaSADT", id: 0 },

    { name: "Honorário", label: "guiaHonorarios", id: 1 },
    { name: "Consulta", label: "guiaConsulta", id: 2 },
    { name: "Solicitação de internação", label: "guiaInternacao", id: 3 },

  ],
  TIPOS_GUIA_REGRA_CONVENIO: [
    { nome: "SADT", label: "guiaSADT", id: "0" },
    { nome: "Honorário", label: "guiaHonorarios", id: "1" },
    { nome: "Consulta", label: "guiaConsulta", id: "2" },
    { nome: "Solicitação de internação", label: "guiaInternacao", id: "3" },
  ],
  TIPOS_GUIA_CONVENIO: [
    { nome: "SADT", label: "Guia SADT", id: "0" },
    { nome: "Honorário", label: "Guia de Honorarios", id: "1" },
    { nome: "Consulta", label: "Guia de Consulta", id: "2" },
    { nome: "Solicitação de internação", label: "Guia de Internação", id: "3" },
  ],
  TIPOS_GUIA_REGRA_CONVENIO_SEM_HONORARIO: [
    { nome: "SADT", label: "guiaSADT", id: "0" },
    { nome: "Honorário", label: "guiaHonorarios", id: "1" },
    { nome: "Consulta", label: "guiaConsulta", id: "2" },
    { nome: "Solicitação de internação", label: "guiaInternacao", id: "3" },
  ],
  TIPOS_GUIA_CONVENIO_USUARIO: [
    { nome: "SADT", label: "guiaSADT", id: "0" },
    { nome: "Honorario", label: "guiaHonorarios", id: "1" },
    { nome: "Consulta", label: "guiaConsulta", id: "2" },
    { nome: "Internação", label: "guiaInternacao", id: "3" },
  ],
  ENUM_TIPOS_GUIA: ENUM_TIPOS_GUIA,
  ENUM_SEXO: ENUM_SEXO,
  SITUACAO_ATENDIMENTO: {
    AUDITORIA: "AUDITORIA",
    PENDENTE: "PENDENTE",
    FATURAMENTO: "FATURAMENTO",
    GLOSADO: "GLOSADO",
    REPASSADO: "REPASSADO",
    NAO_FATURADO: "NAO_FATURADO",
    PENDENCIA_RESOLVIDA: "PENDENCIA_RESOLVIDA",
    RECEBIDA: "RECEBIDA",
    REMOVIDAS_DO_LOTE: "REMOVIDAS_DO_LOTE",
    PROTOCOLADAS: "PROTOCOLADAS",
    GUIA_ELETRONICA:"GUIA_ELETRONICA",
    FATURAMENTO_PARTICULAR: "FATURAMENTO_PARTICULAR",
  },
  SUB_STATUS: {
    AUDITORIA: "AUDITORIA",
    PENDENCIA_RESOLVIDA: "PENDENCIA_RESOLVIDA",
    RECEBIDA: "RECEBIDA",
    GUIA_ELETRONICA: "GUIA_ELETRONICA",
    REMOVIDAS_DO_LOTE: "REMOVIDAS_DO_LOTE",
    PENDENTE: "PENDENTE",
    FATURAMENTO: "FATURAMENTO",
    GLOSADO: "GLOSADO",
    REPASSADO: "REPASSADO",
    NAO_FATURADO: "NAO_FATURADO",
    PROTOCOLADAS: "PROTOCOLADAS"
  },
  STATUS_ATENDIMENTO_INDEX: {
    EM_AUDITORIA: 0,
    PENDENTE: 1,
    FATURAMENTO: 2,
    GLOSADO: 3,
    REPASSADO: 4
  },
  STATUS_ENCAMINHAMENTO: {
    NOME: "Encaminhado guia física para cooperativa",
    ID: 17
  },
  STATUS_RECUSADO: {
    NOME: "Guia recusada"
  },
  VALIDADE_FORM: [
    { description: "Não é obrigatório", name: "NAO_OBRIGATORIO" },
    { description: "Obrigatório", name: "OBRIGATORIO" }
  ],
  SIM_NAO: [
    { label: "Não", value: false },
    { label: "Sim", value: true }
  ],
  INCONSISTENCIA_AUXILIARES: "Inconsistência de auxiliares",
  ROLE_ADMIN: "Administrador",
  PROTOCOLO: "PROTOCOLO", //Protocolo
  AUDITOR: "AUDITOR", //Auditor
  AUDITOR_SEM_SETOR: "AUDITOR_SEM_SETOR", //Auditor sem Setor
  CONVENIO: "CONVENIO",
  CONSULTORIO: "CONSULTORIO", //Consultorio
  COOPERADO: "COOPERADO", //Cooperado
  ADMINISTRADOR: "ADMINISTRADOR", //Administrador,
  SUDO: "SUDO", //Administrador,
  ATENDIMENTO_EXECUCAO: {
    type: ENUM_TIPOS_GUIA.SADT,
    dataAtendimento: "",
    numero: 1,
    quantidade: 1,
    fator: 1,

    valorUnitario: null,
    tabela: "22",
    valorTotal: null,
    atendimentoProcedimento: {
      numero: 1,
      tabela: "22",

    }
  },
  INIT_IDENTIFICACAO_ATENDIMENTO_EXECUCAO: [
    {
      numero: 1,
      codigoCbo: null,

      codigoProcedimento: "10101012",
      descricao: "Em consultório (no horário normal ou preestabelecido)",
      dataAtendimento: moment().toDate()
    }
  ],
  INIT_ATENDIMENTO_EXECUCAO: {
    numero: 1,
    quantidade: 1,
    fator: 1,
    codigoProcedimento: '',
    valorUnitario: null,
    tabela: "22",
    valorTotal: null,
    atendimentoProcedimento: {
      numero: 1,
      tabela: "22",

    }
  },
  SUMARIO_REPASSE: {
    totalCreditos: 0,
    totalRepassado: 0,
    baseBrutaIR: 0,
    descontoDependenteIR: 0,
    baseLiquidaIR: 0,
    valorIR: 0,
    valorDevedorAcumulado: 0,
    totalDesconto: 0
  },
  EDITAR_CONVENIO: 'EDITAR_CONVENIO',
  DATA_FILTER: [
    { name: "data" },
    { name: "mes" },
    { name: "ano" },
    { name: "periodo" },
    { name: "dataEncaminhamento" },
    { name: "dataRecebimento" },
  ],
  TIPO_ANEXO_DOCUMENTO: [
    { codigo: 0, nome: "Laudo", name:"LAUDO"},
    { codigo: 1, nome: "Evolução Clínica",name:"EVOLUCAO_CLINICA"},
    { codigo: 2, nome: "Descrição cirúrgica",name:"DESCRICAO_CIRURGICA"},
    { codigo: 3, nome: "Outros",name:"OUTROS"},
  ],
  EXAME_PRESCRICAO: {
    EXAME_NAO_PRESCRITO: "Exame não prescrito",
    EXAME_PRESCRITO: "Exame prescrito"
  },
  FELICOOP: {
    id: 1,
    name: 'FELICOOP'
  },
  COOPMED: {
    id: 2,
    name: 'COOPMED-RS'
  },
  COOPANESTRIO: {
    id: 3,
    name: 'COOPANESTRIO'
  },
  COOPIMIMG: {
    id: 4,
    name: 'COOPIMIMG'
  },
  SCCOOPER: {
    id: 5,
    name: 'SCCOOPER'
  },
  SANTACOOP: {
    id: 6,
    name: 'SANTACOOP'
  },
  COOPANESTTM: {
    id: 7,
    name: 'COOPANESTTM'
  },
  COOPANESTAL: {
    id: 8,
    name: 'COOPANESTAL'
  },
  COOPANESTMT: {
    id: 10,
    name: 'COOPANESTMT'
  },
  SINGULIER: {
    id: 11,
    name: 'SINGULIER'
  },
  DATE_IMPL_GUIA_HONORARIO_ENVIO_ELETRONICO_AUTOMATICO: new Date(2025,1,17),
};

export const validateConstant = {
  nomeValidate: {
    name: "nome",
    message: "Campo obrigatório",
    type: "text",
    required: true
  },
  dataNascimentoValidate: {
    name: "dataNascimento",
    message: "Campo obrigatório",
    type: "date",
    required: true
  },
  cpfValidate: {
    name: "cpf",
    message: "Campo obrigatório",
    type: "cpf",
    required: true
  },
  emailValidate: {
    name: "email",
    message: "Email inválido",
    type: "email",
    required: true
  },
  confirmarEmailValidate: {
    name: "confirmarEmail",
    message: "Email inválido",
    type: "email",
    required: true
  },
  celularValidate: {
    name: "celular",
    message: "Campo obrigatório",
    type: "text",
    required: true
  },
  senhaValidate: {
    name: "senha",
    message: "Campo obrigatório",
    type: "text",
    required: true
  },
  confirmarSenhaValidate: {
    name: "confirmarSenha",
    message: "Campo obrigatório",
    type: "text",
    required: true
  },
};