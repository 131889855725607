//@flow
import { urlsConstants } from "../constants/url.constant";
import { defaultService } from "./defaultService";
import { objectsConstants } from "../constants/objects.constants";
import { func } from "prop-types";

export const integracaoConvenioService = {
    existsIntegracaoByOrdinalAndIdConvenio,
    getTipoIntegracaoEnum
};


function existsIntegracaoByOrdinalAndIdConvenio(integracao, id) {
  return defaultService.doGet(`${urlsConstants.INTEGRACAO_CONVENIO}${integracao}/${id}`);
}

function getTipoIntegracaoEnum(){
    return defaultService.doGet(`${urlsConstants.INTEGRACAO_CONVENIO}getEnums`);
}


