import { Can } from "@casl/react";
import { Field, Formik, yupToFormErrors } from "formik";
import _, { forEach } from "lodash";
import moment from "moment";
import React, { PureComponent } from "react";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Collapse, Modal, ModalBody } from "reactstrap";
import * as Yup from "yup";
import BtnLink from "../../components/Buttons/BtnLink";
import Control from "../../components/Buttons/ControlSelectInput";
import ClearIndicator from "../../components/Buttons/CustomClearIndicatorSelectInput";
import MultiValueRemove from "../../components/Buttons/CustomCloseSelectInput";
import { FormGroup } from "../../components/FormGroup";
import CheckBoxInput from "../../components/Inputs/CheckBoxInput";
import FormInput from "../../components/Inputs/FormInput";
import FormSelectInput from "../../components/Inputs/FormSelectInput";
import FormTextArea from "../../components/Inputs/FormTextArea";
import InputViewEdit from "../../components/Inputs/InputViewEdit";
import PhoneInput from "../../components/Inputs/PhoneInput";
import SelectInput from "../../components/Inputs/SelectInput";
import { ToolTipHelp } from "../../components/Utils/ToolTipHelp";
import {
  cooperativa,
  objectsConstants,
  cooperativaID,
  enumTipoMsgAviso,
  enumTipoAvisos,
} from "../../constants/objects.constants";
import { dateHelper } from "../../helpers/date.helper";
import { translate } from "../../helpers/message.helper";
import { ConfirmAcationService } from "../../services/ConfirmAcationService";
import { alertCooperativaGuiaService } from "../../services/alertCooperativaGuia.service";
import { atendimentoService } from "../../services/atendimento.service";
import { beneficiarioService } from "../../services/beneficiario.service";
import { convenioUsuarioService } from "../../services/convenioUsuario.service";
import { defaultService } from "../../services/defaultService";
import { exameService } from "../../services/exame.service";
import { justificativaService } from "../../services/justificativa.service";
import { loteAtendimentoService } from "../../services/loteAtendimento.service";
import { mensagemService } from "../../services/mensagem.service";
import { notificacaoService } from "../../services/notificacaoService";
import { pacoteExameService } from "../../services/pacoteExame.service";
import { procedimentoService } from "../../services/procedimento.service";
import { statusAtendimentoService } from "../../services/statusAtendimento.service";
import { userService } from "../../services/user.service";
import TopoTitleComponente from "../home/TopoTitleComponente";
import MenuHistorico from "./historico/MenuHistorico";
import fechar from "../../img/icon_fechar_BLACK.svg";
import { ModalAnexosLote } from "../../components/Utils/ModalAnexosLote";
import { convenioService } from "../../services/convenio.service";
import { descricaoPendenciaService } from "../../services/descricaoPendencia.service";
import { pessoaService } from "../../services/pessoa.service";
import { faturamentoParticularService } from "../../services/faturamentoParticular.service";
import iconAddLote from '../../img/icon_addLote.svg';
import MoneyInput from "../../components/Inputs/MoneyInput";
import { regraProcedimnetoBloqueadosGuiaHospitalService } from "../../services/RegrasProcedimentosBloqueadosGuiaHospital.service";

class GuiaAtendimentoForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
    const viewMode = props.atendimento && props.atendimento.id;
    let execucaoDefault = objectsConstants.ATENDIMENTO_EXECUCAO;
    execucaoDefault.dataAtendimento = "";
    this.state = {
      collapse: props.collapse,
      viewMode,
      showModalExclusao: false,
      viewPendencia: false,
      openMenuNotificacao: false,
      listaHistoricos: [],
      statusEdicao: props.listStatusEncam ? props.listStatusEncam : [],
      showModalTrocarGuia: false,
      typeEditGuia: null,
      showModalSepararProcedimentos: false,
      glosas: null,
      showModalJustificativaGlosa: false,
      observacaoSeparacaoGuia: "",
      removerFromLote: false,
      removerFaturadoFromLote: false,
      justificativaExclusaoLote: props.atendimento.justificativaExclusaoLote,
      erroJustificativaExclusaoLote: "",
      justificativas: [],
      changeGuia: false,
      btnJustificarGlosaReapresentar: true,
      reinicializar: true,
      showModalExames: false,
      confirmPrazoExames: false,
      showModalImprimirExames: false,
      showModalEvolucaoClinica: false,
      listaEvolucoes: [],
      inconsistencia: props.atendimento.atendimentoInconsistencias,
      openAnexo: false,
      showModalFaturamentoParticular: false,
      initialValuesFaturamentoParticular: {
        formaPagamento: "",
        valorAtendimento: "",
        recebedor: "",
        cooperativaId: "",
        convenioId: "",
        atendimentoId: "",
        id: "",
      },
      toggleEditarFaturamentoParticular: true,
      defaultForm: props.defaultForm,
      showModalEnvioPendencia: false,
      cooperados: [],
      sendWppConsultorio: false
    };
  }

  componentDidMount() {
    if (
      this.props.atendimento.status != null &&
      this.props.atendimento.status.situacao != null &&
      this.props.atendimento.status.situacao ===
      objectsConstants.SITUACAO_ATENDIMENTO.GLOSADO
    ) {
      atendimentoService
        .validarPermisaoJustificarGlosa(this.props.atendimento.id)
        .then((response) => {
          this.setState({ btnJustificarGlosaReapresentar: response.data });
        });
    }

    if (
      this.props.atendimento &&
      this.props.atendimento.type &&
      !this.props.atendimento.id
    ) {
      alertCooperativaGuiaService
        .findbyTipoGuia(this.props.atendimento.type)
        .then((r) => {
          if (r.data) {
            this.props.warning({
              message: r.data,
            });
          }
        }, error => {
          console.error(error);
        });
    }
    if (
      this.props.atendimento &&
      this.props.atendimento.status &&
      this.props.atendimento.status.situacao !==
      objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA &&
      this.props.atendimento.status.situacao !==
      objectsConstants.SITUACAO_ATENDIMENTO.PENDENTE
    ) {
      atendimentoService
        .findGlosaSeraRepresentada(this.props.atendimento.id)
        .then((r) => {
          if (r.data && r.data.length > 0) {
            this.setState({ glosas: r.data });
          }
        });
    }
    if (this.props.atendimento && this.props.atendimento.type) {
      localStorage.setItem("tipoGuia", this.props.atendimento.type);
    }

    this.setState({ cooperados: this.props.atendimento.usuariosToSendPendenciaWpp });
  }

  componentWillMount() {
    let extension = userService.getExtensionUser(this.props.user);
    //Não deixar criar atendimento sem extensão, e sem selecionar estabelecimento no filtro
    if (
      (!this.props.atendimento || !this.props.atendimento.id) &&
      !extension &&
      !this.props.filtroAtendimento |
      !this.props.filtroAtendimento.estabelecimento
    ) {
      this.props.history.push({
        pathname: "/atendimento",
      });
    }
  }

  toggle = () => {
    this.setState((state) => ({ collapse: !state.collapse }));
  };

  confirmCancel = () => {
    this.setState({ viewMode: !this.state.viewMode, collapse: false }, () => {
      this.props.onCancelEditCreate &&
        this.props.onCancelEditCreate(
          this.props.atendimento,
          this.props.index,
          this.state.viewMode
        );
    });
    localStorage.removeItem("rascunho");
  };

  confirmLimpaDados = () => {
    if (localStorage.getItem("rascunho")) {
      localStorage.removeItem("rascunho")
    }
    this.formRef.resetForm(this.props.defaultForm)
  }

  confirmCancelBack = () => {
    this.confirmCancel();
    this.props.history.push({
      pathname: "/atendimento",
    });
    localStorage.removeItem("rascunho");
  };

  toggleModalJustificativaGlosa = () => {
    this.setState({
      showModalJustificativaGlosa: !this.state.showModalJustificativaGlosa,
      justificativa: "",
    });
  };
  salvarJustificativaGlosa = (justificativa) => {
    this.props.loading(true);
    atendimentoService
      .salvarJustificativaGlosa(this.props.atendimento.id, justificativa)
      .then(
        (response) => {
          let atendimento = _.cloneDeep(this.props.atendimento);
          atendimento.justificativaGlosa = justificativa;
          this.props.success({
            message: `Justificativa salva com sucesso!`,
          });
          this.setState({
            showModalJustificativaGlosa:
              !this.state.showModalJustificativaGlosa,
          });
          this.props.loading(false);
          this.props.onSaved(atendimento, this.props.index);
        },
        (erros) => {
          this.props.error({
            message: "Não foi possível salvar Justificativa.",
          });
          this.props.loading(false);
        }
      );
  };
  deleteAtendimento = (justificativa) => {
    atendimentoService.doDelete(this.props.atendimento, justificativa).then(
      (response) => {
        this.props.success({
          message: `Atendimentos excluido sucesso!`,
        });
        this.props.history.push({
          pathname: "/atendimento",
        });
        notificacaoService.findByFilter({}).then((response) => {
          this.props.addAllNotificacoes({
            notificacoes: response.data.data.notificacoes,
          });
        });
      },
      (erros) => {
        this.props.error({
          message: "Não foi possível excluir atendimento.",
        });
        this.props.loading(false);
      }
    );
  };
  getLabelCancelBtn = () => {
    if (this.props.user.cooperativa.tipoConsultaNaoDisponiveis) {
      for (
        var i = 0;
        i < this.props.user.cooperativa.tipoConsultaNaoDisponiveis.length;
        i++
      ) {
        if (
          this.props.user.cooperativa.tipoConsultaNaoDisponiveis[i].name ==
          objectsConstants.TIPOS_CONSULTA[2].name
        ) {
          return;
        }
      }
    }
    return "Retorno";
  };
  changeViewMode = (e) => {
    e.stopPropagation();
    this.setState({ viewMode: !this.state.viewMode }, () => {
      this.props.onCancelEditCreate &&
        this.props.onCancelEditCreate(
          this.props.atendimento,
          this.props.index,
          this.state.viewMode
        );
    });
  };
  editMode = (e) => {
    e.stopPropagation();
    this.setState({ viewMode: false, collapse: true }, () => {
      this.props.onCancelEditCreate &&
        this.props.onCancelEditCreate(
          this.props.atendimento,
          this.props.index,
          this.state.viewMode
        );
    });
  };

  abreEdicaoEmMassa = (e) => {
    e.stopPropagation();
    this.setState({ collapse: true });
    this.props.atendimentoParaEditar(this.props.atendimento);
    this.props.toggleEdicaoEmMassa();
  };

  abrePendencia = (e) => {
    e.stopPropagation();
    this.setState({ collapse: true });
    this.props.atendimentoAtual(this.props.atendimento);
    this.props.togglePendencia();
    this.setState({ viewPendencia: !this.state.viewPendencia });
  };

  isChecked = () => {
    if (
      this.props.checkedAll |
      this.props.rowsCheckedIdValue.includes(this.props.atendimento.id)
    ) {
      return true;
    } else {
      return false;
    }
  };

  validaExclusaoGuiaRecusada = (status) => {
    const user = userService.getCurrentUser();
    const tipoPerfil = user.perfilAtual.perfil.tipoPerfil;

    if (
      tipoPerfil === objectsConstants.ADMINISTRADOR ||
      tipoPerfil === objectsConstants.PROTOCOLO
    ) {
      if (
        status.situacao === objectsConstants.SITUACAO_ATENDIMENTO.NAO_FATURADO
      ) {
        //GUIA RECUSADA
        return status.nome === objectsConstants.STATUS_RECUSADO.NOME
          ? true
          : false;
      }
    }
    return false;
  };

  canDelete = () => {
    if (
      this.props.atendimento &&
      this.props.atendimento.status &&
      (this.props.atendimento.status.situacao ===
        objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA ||
        this.props.atendimento.status.situacao ===
        objectsConstants.SITUACAO_ATENDIMENTO.PENDENTE ||
        this.props.atendimento.status.situacao ===
        objectsConstants.SITUACAO_ATENDIMENTO.RECEBIDA ||
        this.props.atendimento.status.situacao ===
        objectsConstants.SITUACAO_ATENDIMENTO.GUIA_ELETRONICA ||
        this.props.atendimento.status.situacao ===
        objectsConstants.SITUACAO_ATENDIMENTO.PENDENCIA_RESOLVIDA ||
        this.props.atendimento.status.situacao ===
        objectsConstants.SITUACAO_ATENDIMENTO.REMOVIDAS_DO_LOTE ||
        this.props.atendimento.status.situacao ===
        objectsConstants.SITUACAO_ATENDIMENTO.NAO_FATURADO ||
        this.props.atendimento.status.situacao === objectsConstants.SITUACAO_ATENDIMENTO.FATURAMENTO_PARTICULAR ||
        this.validaExclusaoGuiaRecusada(this.props.atendimento.status))
    ) {
      if (
        userService.isPerfil(this.props.user, objectsConstants.ADMINISTRADOR) ||
        userService.isPerfil(this.props.user, objectsConstants.PROTOCOLO) ||
        userService.isPerfil(this.props.user, objectsConstants.SUDO)
      ) {
        return true;
      }
    }
    return false;
  };

  changeTypeGuia = (value) => {
    ConfirmAcationService.confirmMsg(
      () => {
        localStorage.setItem("tipoGuia", value);
        this.props.changeTypeGuia(value);
        this.setState({ changeGuia: true });
      },
      "Trocar tipo de guia",
      <span>
        {" "}
        Todos os dados preenchido serão perdidos ao trocar o tipo de guia.
      </span>,
      null,
      "Cancelar",
      "Trocar",
      objectsConstants.TYPE_WARNING
    );
  };

  async validarDataAtendimento20Dias(data) {
    let validarDataMenor20dias = [];
    const dataValidacao = await atendimentoService
      .getDataAtendimentoValidacao()
      .then((result) => {
        if (data[0] && data[0].dataAtendimento) {
          for (let i = 0; i < data.length; i++) {
            let dataAten = new Date(data[i].dataAtendimento);
            let dataLimite = new Date(result.data);

            if (dataAten > dataLimite) {
              validarDataMenor20dias.push(false);
            }
          }
        }
      });

    if (validarDataMenor20dias.length > 0) {
      return false;
    } else {
      return true;
    }
  }

  async validarBloqueioCooperado(atendimento) {
    if (atendimento.cooperado && atendimento.cooperado.id) {
      let bloqueioGuia = false;
      const vinculo = await convenioUsuarioService.findByConvenioAndUsuario(
        atendimento.convenio.id,
        atendimento.cooperado.id
      );
      let guia = objectsConstants.TIPOS_GUIA_CONVENIO_USUARIO.filter(
        (tipo) => tipo.label === atendimento.type
      );
      if (vinculo.data && guia && guia[0]) {
        let guiaBloqueio = vinculo.data.tiposGuiaConvenioUsuario.filter(
          (tipo) => tipo.id === guia[0].id
        );
        if (guiaBloqueio && guiaBloqueio[0]) {
          this.props.error({
            message: `O cooperado ${atendimento.cooperado.nome} possui bloqueio de digitação de atendimentos de ${guiaBloqueio[0].nome} para o convênio ${atendimento.convenio.nome}.`,
          });
          bloqueioGuia = true;
        }
      }
      return bloqueioGuia;
    }
  }

  async validarBloqueioProfissionalExecutante(atendimento) {
    if (
      atendimento.identificacaoAtendimentoExecucao &&
      atendimento.identificacaoAtendimentoExecucao.length && atendimento.convenio
    ) {
      const bloqueioGuiaPromises =
        atendimento.identificacaoAtendimentoExecucao.map(
          async (identificacao) => {
            let bloqueioGuia = false;
            const vinculo =
              await convenioUsuarioService.findByConvenioAndUsuario(
                atendimento.convenio.id,
                identificacao.cooperado.id
              );
            let guia = objectsConstants.TIPOS_GUIA_CONVENIO_USUARIO.filter(
              (tipo) => tipo.label === atendimento.type
            );
            if (vinculo.data && guia && guia[0]) {
              let guiaBloqueio = vinculo.data.tiposGuiaConvenioUsuario.filter(
                (tipo) => tipo.id === guia[0].id
              );
              if (guiaBloqueio && guiaBloqueio[0]) {
                this.props.error({
                  message: `O cooperado ${identificacao.cooperado.nome} possui bloqueio de digitação de atendimentos de ${guiaBloqueio[0].nome} para o convênio ${atendimento.convenio.nome}.`,
                });
                bloqueioGuia = true;
              }
            }
            return bloqueioGuia;
          }
        );
      const bloqueioGuia = await Promise.all(bloqueioGuiaPromises);
      return bloqueioGuia.some((bloqueio) => bloqueio === true);
    }
  }

  async validarBloqueioTipoGuiaConvenio(atendimento) {
    if (await this.validarBloqueioCooperado(atendimento)) {
      return true;
    } else if (await this.validarBloqueioProfissionalExecutante(atendimento)) {
      return true;
    } else {
      return false;
    }
  }

  getSaveBtn = (values, validateForm, handleSubmit) => {
    if (!this.state.viewMode) {
      return (
        <Can
          I={objectsConstants.ALTERAR}
          a={objectsConstants.CRUD_ATENDIMENTOS_EDITAR}
          ability={this.props.permissions}
        >
          <button
            type="submit"
            className="btn btn-primary mr-2"
            //disabled={false}
            disabled={this.state.isSubmitting}
            onClick={(e) => {
              if (!this.state.isSubmitting) {
                this.setState({ isSubmitting: true }, () => {
                  this.submit(values, validateForm, handleSubmit, e);
                });
              }
            }}
          >
            {"Salvar"}
          </button>
        </Can>
      );
    }
  };

  getCancelBtn = (values, canEdit) => {
    if (!this.state.viewMode) {
      return (
        <Can
          I={objectsConstants.ALTERAR}
          a={objectsConstants.CRUD_ATENDIMENTOS_EDITAR}
          ability={this.props.permissions}
        >
          <button
            type="button"
            className="btn btn-secondary mr-2"
            onClick={(e) => {
              e.stopPropagation();
              ConfirmAcationService.confirmMsg(
                !canEdit ? this.confirmCancelBack : this.confirmCancel,
                `Cancelar ${values.id ? "Edição" : "criação"} `,
                "Você confirma que deseja " +
                `cancelar a ${values.id ? "edição" : "criação"
                } do atendimento `,
                null,
                "Não",
                "Sim",
                objectsConstants.TYPE_CANCEL
              );
            }}
            disabled={false}
          >
            {"Cancelar"}
          </button>
        </Can>
      );
    }
  };

  getLimpaBtn = () => {
    if (!this.state.viewMode) {
      return (
        <Can
          I={objectsConstants.ALTERAR}
          a={objectsConstants.CRUD_ATENDIMENTOS_CRIAR}
          ability={this.props.permissions}
        >
          <button
            type="button"
            className="btn btn-secondary mr-2"
            onClick={(e) => {
              ConfirmAcationService.confirmMsg(
                this.confirmLimpaDados,
                `Limpar criação" `,
                "Você confirma que deseja " +
                `limpar os dados de "criação"
                 do atendimento `,
                null,
                "Não",
                "Sim",
                objectsConstants.TYPE_CANCEL
              );
            }}
            disabled={false}
          >
            {"Limpar Tudo"}
          </button>
        </Can>
      );
    }
  };

  validarHoraUrgencia = (values, handleSubmit) => {
    atendimentoService
      .isRegraUrgencia(values.atendimentoExecucaos)
      .then((response) => {
        if (response && response.data) {
          ConfirmAcationService.confirmMsg(
            () => {
              handleSubmit();
            },
            "A hora digitada está dentro da regra de urgência e será adicionado 30% sobre o valor de honorário. " +
            "Caso a hora digitada esteja incorreta poderá ocorrer glosas pelo convênio, deseja continuar com a digitação?",
            null,
            null,
            "Cancelar",
            "Continuar",
            objectsConstants.TYPE_WARNING,
            () => {
              this.setState({ isSubmitting: false })
            }
          );
        } else {
          handleSubmit();
        }
      });
  };

  submit = (values, validateForm, handleSubmit, e) => {
    e.stopPropagation();
    validateForm(values).then(async (erros) => {
      this.verificaRetorno(`atendimentoExecucaos[0].tipoConsulta`).then(
        async (consulta) => {
          if (!this.state.confirmarQueixa) {
            if (!this.validarQntProfissionalExec(values)) {
              if (_.isEmpty(erros)) {
                if (
                  await this.validarDataAtendimento20Dias(
                    values.atendimentoExecucaos
                  )
                ) {
                  if (
                    !this.state.confirmPrazoExames &&
                    values.exames &&
                    values.exames.length > 0 &&
                    userService.isCooperado(this.props.user)
                  ) {
                    this.confirmExames(true, handleSubmit);
                    this.setState({ isSubmitting: false });
                  } else {
                    if (
                      (values.type === objectsConstants.ENUM_TIPOS_GUIA.SADT ||
                      values.type === objectsConstants.ENUM_TIPOS_GUIA.HONORARIO) && values.convenio
                    ) {
                      this.validarHoraUrgencia(values, handleSubmit);
                    } else {
                      if (
                        !(await this.validarBloqueioTipoGuiaConvenio(values))
                      ) {
                        handleSubmit();
                      }
                    }
                  }
                } else {
                  this.props.error({
                    message:
                      "Não foi possível salvar o atendimento com data maior que 20 dias",
                  });
                  this.props.loading(false);
                  this.setState({ isSubmitting: false });
                }
              } else if (!this.verificarNumeroCarteirinha(values.numeroCarteira, values.convenio)) {
                const listaPrefixos = values.convenio.listCaracteresInicioCarteirinha.map(prefixo => prefixo.caracteres);
                this.props.error({
                  message:
                    `O campo número da carteira não inicia com o(s) caracteres necessários -> ${listaPrefixos.join(', ')}`,
                });
                this.props.loading(false);
                this.setState({ isSubmitting: false });
              } else {
                this.props.error({
                  message:
                    "Não foi possível salvar o atendimento, existem erros no formulário",
                  duration: 5,
                });
                this.props.loading(false);
                this.setState({ isSubmitting: false });
              }
            } else {
              this.props.error({
                message:
                  "Não foram digitados os profissionais executantes para todos os procedimentos do atendimento. Favor verificar",
              });
              this.props.loading(false);
              this.setState({ isSubmitting: false });
            }
          }
        }
      );
    });
  };

  validateForm = () => {
    let _this = this;
    let promise = new Promise(function (resolve) {
      _this.formRef.validateForm(_this.formRef.values).then((erros) => {
        if (_.isEmpty(erros)) {
          resolve(true);
        }
        resolve(false);
      });
    });
    return promise;
  };

  submitForm = async () => {
    if (
      !(await this.validarBloqueioTipoGuiaConvenio(this.formRef.state.values))
    ) {
      if (
        this.formRef.state.values.type ===
        objectsConstants.ENUM_TIPOS_GUIA.SADT ||
        this.formRef.state.values.type ===
        objectsConstants.ENUM_TIPOS_GUIA.HONORARIO
      ) {
        atendimentoService
          .isRegraUrgencia(this.formRef.state.values.atendimentoExecucaos)
          .then((response) => {
            if (response && response.data) {
              ConfirmAcationService.confirmMsg(
                () => {
                  this.formRef.executeSubmit();
                },
                "A hora digitada está dentro da regra de urgência e será adicionado 30% sobre o valor de honorário. " +
                "Caso a hora digitada esteja incorreta poderá ocorrer glosas pelo convênio, deseja continuar com a digitação?",
                null,
                null,
                "Cancelar",
                "Continuar",
                objectsConstants.TYPE_WARNING
              );
            } else {
              this.formRef.executeSubmit();
            }
          });
      } else {
        this.formRef.executeSubmit();
      }
    }
  };

  showAlertRetorno = (outroAtedimento, propName) => {
    let dataAtendimento = dateHelper.format(outroAtedimento.dataAtendimento, {
      mode: "DATE",
    });
    if (this.getLabelCancelBtn()) {
      ConfirmAcationService.confirmMsg(
        () => {
          this.setState({ confirmarQueixa: true, propName });
        },
        `Retorno do beneficiário?`,
        <span>
          {" "}
          {`Este beneficiário foi atendido no dia ${dataAtendimento}, e está dentro da cobertura do convênio por um atendimento de retorno `}
          <span className="font-bold row pt-3">
            {
              "Este atendimento de hoje seria um retorno ou é uma nova queixa do beneficiário?"
            }
          </span>
        </span>,
        `${outroAtedimento.numeroAtendimento} - ${dataAtendimento}`,
        this.getLabelCancelBtn(),
        "Nova queixa",
        objectsConstants.TYPE_RETORNO,
        () => {
          this.confirmRetorno(propName);
        }
      );
    } else {
      this.setState({ confirmarQueixa: true, propName });
    }
  };

  confirmMudarNovaQueixaModal = (mensagem) => {
    let _this = this;
    let newValues = _.cloneDeep(this.formRef.state.values);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-ui css-alert-sasc confirm-ui-form confirm-ui-gray">
            <h2 className="mb-3">Justifique a nova queixa</h2>
            <p>
              Para este novo atendimento ser faturado pelo convênio é
              obrigatório o preenchimento da justificativa, informando os
              motivos deste não ser um atendimento de retorno.
            </p>
            <p className="font-bold">Preencha abaixo a justificativa:</p>

            <SelectInput
              className="min-width"
              name={"justificativa"}
              value={this.state.justificativa}
              onChange={(name, value) => {
                let justificativa = value.texto;
                if (_this.state.observacao) {
                  _this.setState({
                    observacao: justificativa.concat(
                      " ",
                      _this.state.observacao
                    ),
                  });
                } else {
                  _this.setState({ observacao: justificativa });
                }
              }}
              returnFullObject={true}
              labelKey={"texto"}
              valueKey={"id"}
              placeholder="Selecione a justificativa"
              onFetchData={justificativaService.findByTexto}
            />

            <p className="font-bold">
              Deseja descrever as informações: Direcionar para digitação.
            </p>
            <div className="row body-confirm cardCrud ">{mensagem}</div>
            {_this.getLabelCancelBtn() && (
              <button
                className="btn btn-secondary white mt-3 mb-4 mx-2"
                onClick={() => {
                  _this.cancelNovaQueixa();
                  onClose();
                }}
              >
                Cancelar
              </button>
            )}

            <button
              className="btn btn-primary white mt-3 mb-4 mx-2"
              onClick={() => {
                let observacaoForm = _.get(
                  _this.formRef.state.values,
                  "observacao"
                );
                if (
                  !this.state.observacao &&
                  !observacaoForm &&
                  newValues.obersavaoObrigatoria
                ) {
                  _this.setState({
                    erroObservacao: "Justifique o atendimento.",
                  });
                  this.props.error({
                    message: "Justifique o atendimento.",
                  });
                } else {
                  _this.confirmNovaQueixa();
                  this.setState({ isSubmitting: false });
                  onClose();
                }
              }}
            >
              Justificar
            </button>
          </div>
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  confirmReconsultaSadt = (mensagem) => {
    let newValues = _.cloneDeep(this.formRef.state.values);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-ui css-alert-sasc confirm-ui-form confirm-ui-gray">
            <h2 className="mb-3">Atendimento reconsulta</h2>
            <p>
              Não foi possível salvar o atendimento, pois foi identificado que o
              mesmo é uma reconsulta dentro do prazo de{" "}
              {newValues.convenio.prazoDiasRetorno} dias do convênio{" "}
              {newValues.convenio.nome}
            </p>

            <button
              className="btn btn-secondary white mt-3 mb-4 mx-2"
              onClick={() => {
                this.setState({ confirmarQueixa: false, observacao: "" });
                onClose();
              }}
            >
              Fechar
            </button>
          </div>
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  confirmReconsultaSadt2 = (mensagem) => {
    let _this = this;
    let newValues = _.cloneDeep(this.formRef.state.values);
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="confirm-ui css-alert-sasc confirm-ui-form confirm-ui-gray">
            <h2 className="mb-3">Atendimento reconsulta</h2>
            <p>
              Não foi possível salvar o atendimento, pois foi identificado que o
              mesmo é uma reconsulta dentro do prazo de{" "}
              {newValues.convenio.prazoDiasRetorno} dias do convênio{" "}
              {newValues.convenio.nome}
            </p>
            <p className="font-bold">
              Deseja salvar somente os demais códigos?
            </p>
            <button
              className="btn btn-primary white mt-3 mb-4 mx-2"
              onClick={(e) => {
                _this.removeProcedimento();
                _this.setState({ confirmarQueixa: false, observacao: "" });
                _this.submit(
                  this.formRef.state.values,
                  this.formRef.validateForm,
                  this.formRef.handleSubmit,
                  e
                );
                onClose();
              }}
            >
              Sim
            </button>
            <button
              className="btn btn-secondary white mt-3 mb-4 mx-2"
              onClick={() => {
                this.setState({ confirmarQueixa: false, observacao: "" });
                onClose();
              }}
            >
              Não
            </button>
          </div>
        );
      },
      closeOnEscape: false,
      closeOnClickOutside: false,
    });
  };

  removeProcedimento = () => {
    let newValues = _.cloneDeep(this.formRef.state.values);
    let indexProcedimento = newValues.atendimentoExecucaos.findIndex(
      (i) => i.codigoProcedimento == "10101012"
    );

    let procedimentoExecucao =
      newValues.atendimentoExecucaos[indexProcedimento];
    newValues.atendimentoExecucaos.splice(indexProcedimento, 1);

    _.remove(newValues.identificacaoAtendimentoExecucao, {
      numero: procedimentoExecucao.numero,
    });
    _.remove(newValues.atendimentoProcedimentos, {
      numero: procedimentoExecucao.numero,
    });

    newValues.identificacaoAtendimentoExecucao.map(
      (identificacao, indexIdentificacao) => {
        if (indexProcedimento < identificacao.numero) {
          identificacao.numero = identificacao.numero - 1;
        }
      }
    );

    newValues.atendimentoExecucaos.map(
      (atendimentoExecucao, indexIdentificacao) => {
        if (indexProcedimento < atendimentoExecucao.numero) {
          atendimentoExecucao.numero = atendimentoExecucao.numero - 1;
        }
      }
    );

    newValues.atendimentoProcedimentos.map(
      (atendimentoExecucao, indexIdentificacao) => {
        atendimentoExecucao.numero = indexIdentificacao + 1;
      }
    );

    this.formRef.state.values = newValues;
  };

  cancelNovaQueixa = () => {
    this.setState({ confirmarQueixa: false, observacao: "" });
    this.confirmRetorno(this.state.propName);
  };
  confirmNovaQueixa = () => {
    this.setState({ confirmarQueixa: false, observacao: "" });
    let newValues = _.cloneDeep(this.formRef.state.values);
    _.set(newValues, "observacao", this.state.observacao);
    newValues.obersavaoObrigatoria = true;
    this.formRef.setValues(newValues);
  };

  removeRascunho = () => {
    let newValues = _.cloneDeep(this.formRef.state.values);
    newValues.rascunho = false;
    this.formRef.setValues(newValues);
    localStorage.removeItem("rascunho");
  };

  confirmRetorno = (propName) => {
    let newValues = _.cloneDeep(this.formRef.state.values);
    let retorno = objectsConstants.TIPOS_CONSULTA[2];
    _.set(newValues, propName, retorno);
    newValues.obersavaoObrigatoria = false;
    this.formRef.setValues(newValues);
  };

  confirmExames = (confirm, handleSubmit) => {
    let newValues = _.cloneDeep(this.formRef.state.values);
    let ae = {
      atendimento: newValues.id,
      exames: newValues.exames,
      data:
        newValues.atendimentoExecucaos.length > 0 &&
        newValues.atendimentoExecucaos[0].dataAtendimento,
      convenio: newValues.convenio.id,
      estabelecimento: newValues.estabelecimento.id,
      numeroCarteira: newValues.numeroCarteira,
    };

    if (newValues.exames) {
      this.props.loading(true);
      exameService.checkPrazo(ae).then((response) => {
        if (response.data.length > 0) {
          let exameProcedimento = response.data;

          let codExames = [];
          let dataAten = [];
          let cooperadosAten = [];

          exameProcedimento.map((ep) => {
            codExames.push(ep.procedimento);
            dataAten.push(ep.exame.atendimento.dataAtendimento);
            cooperadosAten.push(ep.exame.atendimento.cooperado);
          });

          const allEqual = (arr) => arr.every((val) => val.id === arr[0].id);
          const isEqual = allEqual(cooperadosAten);

          if (isEqual) {
            let cooperado = cooperadosAten[0];
            cooperadosAten = [];
            cooperadosAten.push(cooperado);
          }
          this.props.loading(false);
          ConfirmAcationService.confirmMsg(
            () => {
              if (!confirm) {
                this.saveExames();
              } else {
                handleSubmit();
              }
            },
            "Exame realizado",
            <span>
              {" "}
              Este beneficiário realizou o(s) exame(s){" "}
              {codExames.map((e) => {
                return (
                  <span className="font-bold">
                    {e.codigo} - {e.descricao}
                    {", "}
                  </span>
                );
              })}{" "}
              solicitado(s) pelo(s) cooperado(s){" "}
              {cooperadosAten.map((e) => {
                return (
                  <span className="font-bold">
                    {e.nome}
                    {", "}
                  </span>
                );
              })}{" "}
              na(s) data(s){" "}
              {dataAten.map((data) => {
                return (
                  <span className="font-bold">
                    {moment(data).format("DD/MM/YYYY")}
                    {", "}
                  </span>
                );
              })}
              . Deseja prosseguir?
            </span>,
            <span className="text-danger">{ }</span>,
            "Não",
            "Sim",
            objectsConstants.TYPE_FORWARD
          );
        } else {
          this.props.loading(false);
          if (!confirm) {
            this.saveExames();
          } else {
            handleSubmit();
          }
        }
      });
    }
  };

  saveExames = () => {
    this.props.loading(true);
    let newValues = _.cloneDeep(this.formRef.state.values);
    this.formRef.setValues(newValues);

    let ae = {
      atendimento: newValues.id,
      exames: newValues.exames,
      pacotes: newValues.pacotes,
    };

    exameService.doSave(ae).then(
      (response) => {
        this.props.success({
          message: "Exames prescritos com sucesso!",
        });
        this.props.loading(false);
        this.toogleModalExames();
        this.props.onSaved();
      },
      (erros) => {
        console.error(erros.response.data.messages);
        this.props.loading(false);
        this.props.error({
          message: "Ocorreu um erro ao prescrever os exames",
        });
      }
    );
  };

  enviarNotificacaoPendenciaWpp = () => {
    const envioConsultorio = this.state.sendWppConsultorio;
    const cooperados = this.state.cooperados.filter(cooperado => cooperado.checked).map(cooperado => cooperado.id);
    if (cooperados || envioConsultorio) {
      this.props.loading(true);
      atendimentoService
        .enviarPendenciaWpp(this.props.atendimento.id, envioConsultorio, cooperados)
        .then((resp) => {
          if (resp.data) {
            resp.data.map(data => {
              window.open(data);
            })
            this.props.loading(false);
            this.toogleModalEnvioPendencia();
          } else {
            this.props.loading(false);
            this.props.error({
              message:
                "Ocorreu um erro ao redirecionar para o WhatsApp",
            });
          }
        })
        .catch((erro) => {
          this.props.loading(false);
          console.error(erro);
          this.props.error({
            message: "Ocorreu um erro ao redirecionar para o WhatsApp",
          });
        });
    }
  }

  imprimirExames = () => {
    this.props.loading(true);
    let newValues = _.cloneDeep(this.formRef.state.values);
    if (
      !newValues.enviarPDF &&
      !newValues.enviarEmail &&
      !newValues.enviarCelular
    ) {
      this.props.loading(false);
      this.props.error({
        message: "Selecione uma opção para continuar.",
      });
    }
    if (newValues.enviarPDF) {
      atendimentoService
        .imprimirExames(newValues.id)
        .then((res) => {
          var base64 = _.get(res, "data.data");
          if (base64 != null) {
            let asciiString = atob(base64);
            let array = new Uint8Array(
              [...asciiString].map((char) => char.charCodeAt(0))
            );
            const file = new Blob([array], { type: "application/pdf" });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            this.props.loading(false);
            if (this.state.showModalImprimirExames) {
              this.toogleModalImprimirExames();
            }
          } else {
            this.props.loading(false);
            this.props.error({
              message: "Ocorreu um erro ao imprimir os exames",
            });
          }
        })
        .catch((err) => {
          this.props.loading(false);
          console.error(err);
          this.props.error({
            message: "Ocorreu um erro ao imprimir os exames",
          });
        });
    }
    if (newValues.enviarEmail) {
      beneficiarioService
        .saveEmail(newValues.idBeneficiario, newValues.email)
        .then((res) => {
          if (res.data) {
            if (newValues.mensagem && newValues.mensagem.id) {
              atendimentoService
                .enviarExames(newValues.id, newValues.mensagem.id)
                .then((resp) => {
                  if (resp.data) {
                    this.props.success({
                      message: "Exames enviados com sucesso!",
                    });
                    this.props.loading(false);
                    this.toogleModalImprimirExames();
                  } else {
                    this.props.loading(false);
                    this.props.error({
                      message: "Ocorreu um erro ao enviar os exames",
                    });
                  }
                })
                .catch((erro) => {
                  this.props.loading(false);
                  console.error(erro);
                  this.props.error({
                    message: "Ocorreu um erro ao enviar os exames",
                  });
                });
            } else {
              this.props.loading(false);
              this.props.error({
                message: "Selecione uma mensagem padrão para continuar.",
              });
            }
          } else {
            this.props.loading(false);
            this.props.error({
              message: "Ocorreu um erro ao salvar o email",
            });
          }
        })
        .catch((err) => {
          this.props.loading(false);
          console.error(err);
          this.props.error({
            message: "Ocorreu um erro ao salvar o email",
          });
        });
    }
    if (newValues.enviarCelular) {
      beneficiarioService
        .saveCelular(newValues.idBeneficiario, newValues.celular)
        .then((res) => {
          if (res.data) {
            if (newValues.mensagem && newValues.mensagem.id) {
              atendimentoService
                .enviarWpp(newValues.id, newValues.mensagem.id)
                .then((resp) => {
                  if (resp.data) {
                    window.open(resp.data);
                    this.props.loading(false);
                    this.toogleModalImprimirExames();
                  } else {
                    this.props.loading(false);
                    this.props.error({
                      message:
                        "Ocorreu um erro ao redirecionar para o WhatsApp",
                    });
                  }
                })
                .catch((erro) => {
                  this.props.loading(false);
                  console.error(erro);
                  this.props.error({
                    message: "Ocorreu um erro ao redirecionar para o WhatsApp",
                  });
                });
            } else {
              this.props.loading(false);
              this.props.error({
                message: "Selecione uma mensagem padrão para continuar.",
              });
            }
          } else {
            this.props.loading(false);
            this.props.error({
              message: "Ocorreu um erro ao salvar o celular",
            });
          }
        })
        .catch((err) => {
          this.props.loading(false);
          console.error(err);
          this.props.error({
            message: "Ocorreu um erro ao salvar o celular",
          });
        });
    }
  };

  IdsCooperadosAtendimento = (atendimento) => {
    let ids = [];
    if (atendimento.cooperado && atendimento.cooperado.id) {
      ids.push(atendimento.cooperado.id);
    }
    for (
      let i = 0;
      i < atendimento.identificacaoAtendimentoExecucao.length;
      i++
    ) {
      if (
        atendimento.identificacaoAtendimentoExecucao[i].cooperado &&
        atendimento.identificacaoAtendimentoExecucao[i].cooperado.id
      ) {
        ids.push(atendimento.identificacaoAtendimentoExecucao[i].cooperado.id);
      }
    }
    return ids;
  };

  verificaRetorno = (propName, name, value) => {
    this.props.loading(false);
    let _this = this;
    let values = _.cloneDeep(this.formRef.state.values);
    let tipoConsulta = _.get(values, propName);
    let observacao = _.get(values, "observacao");
    if (name) {
      _.set(values, name, value);
    }

    let tipoSADT = objectsConstants.GUIASADT === values.type;

    let promise = new Promise(function (resolve) {
      if (tipoConsulta) {
        atendimentoService.verificaRetorno(values).then(
          (response) => {
            let newValues = _.cloneDeep(values);
            let outroAtedimento = response.data;
            if (
              outroAtedimento.numeroAtendimento !== values.numeroAtendimento
            ) {
              if (tipoConsulta.description !== "Retorno" && !observacao) {
                if (values.cooperativa.reconsultaAutomatica) {
                  _this.showAlertRetorno(outroAtedimento, propName);
                  _.set(newValues, propName, objectsConstants.TIPO_RECONSULTA);
                  newValues.obersavaoObrigatoria = true;
                } else {
                  _this.showAlertRetorno(outroAtedimento, propName);
                  newValues.obersavaoObrigatoria = true;
                }
                _this.formRef.setValues(newValues);
              }
              resolve(outroAtedimento);
            }
            resolve(null);
          },
          (error) => {
            console.error(error);
            resolve(null);
          }
        );
      } else if (values.convenio && values.convenio.validarReconsultaSadt && tipoSADT) {
        atendimentoService.verificaRetorno(values).then(
          (response) => {
            let outroAtedimento = response.data;
            if (
              outroAtedimento.numeroAtendimento !== values.numeroAtendimento
            ) {
              if (
                values.atendimentoExecucaos.find((element) =>
                  _.isEqual(element.codigoProcedimento, "10101012")
                )
              ) {
                _this.setState({ confirmarQueixa: true, propName });
              } else {
                resolve(null);
              }
            } else {
              resolve(null);
            }
          },
          (error) => {
            console.error(error);
            resolve(null);
          }
        );
      } else {
        resolve(null);
      }
    });
    return promise;
  };

  removerLote = (atendimento, index) => {
    if (this.state.justificativaExclusaoLote.length === 0) {
      this.setState({
        erroJustificativaExclusaoLote: "Justifique a exclusão.",
      });
      this.props.error({
        message: "Justifique a remoção do atendimento do lote.",
      });
    } else {
      loteAtendimentoService
        .removeAtendimento(atendimento.id, this.state.justificativaExclusaoLote)
        .then((response) => {
          if (this.state.removerFaturadoFromLote) {
            atendimentoService.removerAtendimentoFaturamento(atendimento.id);
            this.setState({ removerFaturadoFromLote: false });
          }
          this.props.success({ message: "Atendimento Removido com sucesso." });
          this.props.removeLote(atendimento);
        });
      this.setState({ removerFromLote: false });
    }
  };

  typeName = (atendimento) => {
    let typeName = "";
    if (atendimento.type) {
      switch (atendimento.type) {
        case objectsConstants.ENUM_TIPOS_GUIA.CONSULTA:
          typeName = "Consulta";
          break;
        case objectsConstants.ENUM_TIPOS_GUIA.HONORARIO:
          typeName = "Honorário";
          break;
        case objectsConstants.ENUM_TIPOS_GUIA.INTERNACAO:
          typeName = "Solicitação de internação";
          break;
        default:
          typeName = "SADT";
          break;
      }
    }
    return typeName;
  };

  hasPendencia = (atendimento) => {
    return _.get(atendimento, "atendimentoPendencias.length", 0) > 0;
  };

  toogleModalExclusao = () => {
    this.setState({ showModalExclusao: !this.state.showModalExclusao });
  };

  validarAuxiliares = (values) => {
    this.props.loading(true);
    atendimentoService.validarAuxiliares(values).then(response => {
      atendimentoService.findByIds([this.props.atendimento.id]).then(res => {
        this.setState({ inconsistencia: response.data.atendimentoInconsistencias })
        this.props.onSaved();
        this.props.loading(false);
      }).catch(error => {
        this.props.loading(false);
        console.error('Error: ', error);
      });
    }).catch(error => {
      this.props.loading(false);
      console.error('Error: ', error);
    });
  }

  toogleModalExames = () => {
    this.setState({ showModalExames: !this.state.showModalExames });
  };

  toogleModalEnvioPendencia = () => {
    this.setState({ showModalEnvioPendencia: !this.state.showModalEnvioPendencia, sendWppConsultorio: false });
  };

  handleEnvioWppCooperado = (name, value) => {
    let state = _.cloneDeep(this.state);
    _.set(state, name, value);
    this.setState(state);
  };

  toogleModalImprimirExames = () => {
    beneficiarioService
      .findByCarteirinha(this.props.atendimento.numeroCarteira)
      .then((res) => {
        let newValues = _.cloneDeep(this.formRef.state.values);
        if (res.data && res.data.email != null) {
          newValues.email = res.data.email;
        }
        if (res.data && res.data.celular != null) {
          newValues.celular = res.data.celular;
        }
        this.formRef.setValues(newValues);
      });
    this.setState({
      showModalImprimirExames: !this.state.showModalImprimirExames,
    });
  };

  changeTypeGuiaEdicao = (value) => {
    this.setState({ typeEditGuia: value });
  };
  changeTypeGuiaSubmit = () => {
    this.props.loading(true);
    if (this.state.typeEditGuia) {
      atendimentoService
        .alterarTipoGuia(this.props.atendimento.id, this.state.typeEditGuia.id)
        .then((r) => {
          this.props.onSaved({ id: r.data }, this.props.index);
          this.toogleTipoGuia();
          this.props.loading(false);
        });
    }
  };
  toogleModalSepararProcedimentos = () => {
    this.setState({
      showModalSepararProcedimentos: !this.state.showModalSepararProcedimentos,
    });
  };
  toogleTipoGuia = () => {
    this.setState({ showModalTrocarGuia: !this.state.showModalTrocarGuia });
  };

  validarPermisaoEditarStatusGuiaRecebidaOuGuiaEletronica = (situacao) => {
    const tipoPerfil = this.props.user.perfilAtual.perfil.tipoPerfil;
    if (
      situacao === objectsConstants.SITUACAO_ATENDIMENTO.RECEBIDA &&
      (tipoPerfil === objectsConstants.CONSULTORIO ||
        tipoPerfil === objectsConstants.COOPERADO)
    ) {
      return false;
    } else if (situacao === objectsConstants.SITUACAO_ATENDIMENTO.GUIA_ELETRONICA &&
      (tipoPerfil === objectsConstants.CONSULTORIO || tipoPerfil === objectsConstants.COOPERADO)) {
      return false;
    }
    return true;
  };

  validarPermisaoEditarGuiaInternacaoStatusNaoFaturado = (
    situacao,
    statusNome
  ) => {
    const tipoPerfil = this.props.user.perfilAtual.perfil.tipoPerfil;
    if (
      situacao === objectsConstants.SITUACAO_ATENDIMENTO.NAO_FATURADO &&
      statusNome === objectsConstants.NAO_FATURADO &&
      (tipoPerfil === objectsConstants.CONSULTORIO ||
        tipoPerfil === objectsConstants.COOPERADO)
    ) {
      return false;
    }
    return true;
  };

  validarPermisaoEditarGuiaRecusadaNaoFaturado = (situacao, statusNome) => {
    const tipoPerfil = this.props.user.perfilAtual.perfil.tipoPerfil;
    if (
      situacao === objectsConstants.SITUACAO_ATENDIMENTO.NAO_FATURADO &&
      statusNome === objectsConstants.STATUS_RECUSADO.NOME &&
      (tipoPerfil === objectsConstants.CONSULTORIO ||
        tipoPerfil === objectsConstants.COOPERADO)
    ) {
      return false;
    }
    return true;
  };

  validarQntProfissionalExec = (atendimento) => {
    let missingProfissionalExecutante = false;
    if (atendimento.type === objectsConstants.ENUM_TIPOS_GUIA.HONORARIO) {
      let numeroProfissional = [];
      for (
        let j = 0;
        j < atendimento.identificacaoAtendimentoExecucao.length;
        j++
      ) {
        numeroProfissional.push(
          atendimento.identificacaoAtendimentoExecucao[j].numero
        );
      }
      for (let i = 0; i < atendimento.atendimentoExecucaos.length; i++) {
        if (
          !numeroProfissional.includes(
            atendimento.atendimentoExecucaos[i].numero
          )
        ) {
          missingProfissionalExecutante = true;
        }
      }
    }
    return missingProfissionalExecutante;
  };

  async validarDuplicata(e) {
    const response = await atendimentoService.validarAtendimentoDuplicados(e);
    let confirmed = objectsConstants.ATENDIMENTO_SEM_DUPLICIDADE;
    if (response.data && response.data.length > 0) {
      let numeroAtendimentos = response.data.map(t => { return `${t.origem.name}${t.numeroAtendimento}` });
      confirmed = await this.modalAtendimentoDuplicidade(numeroAtendimentos)
      if (objectsConstants.ATENDIMENTO_DUPLICADO_SALVAR) {
        e.observacao = `Existe duplicidade com o${response.data.length > 1 ? 's' : ''} atendimento${response.data.length > 1 ? 's' : ''} 
        ${numeroAtendimentos.map((item, index, array) => {
          const separator = index === array.length - 1 ? '.' : ', ';
          return `${item}${separator}`;
        }).join('')}
        Você pode verificar inserindo o número d${response.data.length > 1 ? 'os' : 'o'} atendimento${response.data.length > 1 ? 's' : ''} ${numeroAtendimentos.map(item => `${item},`).join(' ')} no filtro da tela de atendimento.`;
      }
    }
    return confirmed;
  }

  async modalAtendimentoDuplicidade(numeroAtendimentos) {
    this.props.loading(false);
    return new Promise(resolve => {
      ConfirmAcationService.confirmMsg(
        () => {
          resolve(objectsConstants.ATENDIMENTO_DUPLICADO_SALVAR);
        },
        `Alerta de possível duplicidade de atendimento`,
        <p>Já existe{numeroAtendimentos.length > 1 ? 'm' : ''} atendimento{numeroAtendimentos.length > 1 ? 's' : ''} {numeroAtendimentos.map(item => `${item},`).join(' ')} digitado no sistema para o mesmo paciente, médico, convênio, data e procedimento, porém com <b className="extra-bold">hora</b> ou <b className="extra-bold">grau de participação</b> diferentes. Deseja prosseguir com a digitação deste atendimento?</p>,
        null,
        "Não",
        "Sim",
        objectsConstants.TYPE_WARNING,
        () => {
          resolve(objectsConstants.ATENDIMENTO_DUPLICADO_NAO_SALVAR);
        },
      );
    });
  }

  evolucaoClinica = async (e) => {
    e.stopPropagation();
    this.props.loading(true);
    if (
      this.props.atendimento &&
      this.props.atendimento.numeroAtendimento &&
      this.props.atendimento.cooperativa &&
      this.props.atendimento.cooperativa.id
    ) {
      await atendimentoService
        .getEvolucaoClinicaAtendimento(
          this.props.atendimento.numeroAtendimento,
          this.props.atendimento.cooperativa.id
        )
        .then((response) => {
          this.setState({ listaEvolucoes: response.data });
        })
        .catch((error) => console.error(error));
      this.toogleModalEvolucaoClinica();
    }
    this.props.loading(false);
  };

  toogleModalEvolucaoClinica = () => {
    this.setState({
      showModalEvolucaoClinica: !this.state.showModalEvolucaoClinica,
    });
  };

  imprimirEvolucaoClinica = () => {
    this.props.loading(true);
    let newValues = _.cloneDeep(this.formRef.state.values);
    atendimentoService
      .imprimirEvolucaoClinica(
        newValues.numeroAtendimento,
        newValues.cooperativa.id
      )
      .then((res) => {
        var base64 = _.get(res, "data.data");
        if (base64 != null) {
          let asciiString = atob(base64);
          let array = new Uint8Array(
            [...asciiString].map((char) => char.charCodeAt(0))
          );
          const file = new Blob([array], { type: "application/pdf" });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.props.loading(false);
        } else {
          this.props.loading(false);
          this.props.error({
            message: "Ocorreu um erro ao imprimir a Evolução Clínica",
          });
        }
      })
      .catch((err) => {
        this.props.loading(false);
        console.error(err);
        this.props.error({
          message: "Ocorreu um erro ao imprimir a Evolução Clínica",
        });
      });
  };

  imprimirAtendimento = (values) => {
    if (
      values.cooperativa &&
      (values.cooperativa.sigla === cooperativa.COOPMEDRS ||
        values.cooperativa.sigla === cooperativa.COOPANESTTM ||
        (values.cooperativa.sigla === cooperativa.SANTACOOP 
          && (values.type == "guiaSADT" || values.type == "guiaHonorarios" )))
    ) {
      atendimentoService.imprimirGuia(values.id).then((response) => {
        var base64 = _.get(response, "data.data");
        if (base64 != null) {
          let asciiString = atob(base64);
          let array = new Uint8Array(
            [...asciiString].map((char) => char.charCodeAt(0))
          );
          const file = new Blob([array], { type: "application/pdf" });
          let fileURL = URL.createObjectURL(file);
          window.open(fileURL, "_blank");
        }
      });
    }
    else {
      // else temporario
      this.props.imprimir(values, this.props.atendimento.type);
    }
  };


  exibicaoInconsistencia = (atendimentoList) => {
    return (
      <>
        {
          atendimentoList.map((t, index) => {
            if (!t.concluida) {
              return <span className="spanMensagem" key={index}>{t.inconsistencia}</span>
            }
          })
        }

      </>
    );
  }

  toggleAnexos = () => {
    this.setState(({ openAnexo }) => ({ openAnexo: !openAnexo }));
  };

  verificarNumeroCarteirinha = (numeroCarteira, convenio) => {
    const listaPrefixos = convenio.listCaracteresInicioCarteirinha.map(prefixo => prefixo.caracteres);
    if (listaPrefixos && listaPrefixos.length > 0) {
      const iniciaCom = listaPrefixos.some(prefixo => numeroCarteira.startsWith(prefixo.toString()));
      return iniciaCom;
    }
    return true;
  }

  faturamentoParticular = async () => {
    this.setState({
      showModalFaturamentoParticular: !this.state.showModalFaturamentoParticular,
    });
    await this.carregarDadosFaturamentoParticular(this.props.atendimento.convenio.id);
  };

  async recebedorFaturamentoParticular(id) {
    const responseRecebedor = await pessoaService.findNomeById(id);
    const recebedor = responseRecebedor.data.data[0]
    return recebedor;
  };

  async carregarDadosFaturamentoParticular() {
    const responseFaturamento = await faturamentoParticularService.findFaturamentoByAtendimentoId(this.props.atendimento.id);
    const responseOptions = await faturamentoParticularService.findFormaPagamento();
    const options = responseOptions.data || responseOptions;
    this.setState({
      formaPagamentoOptions: options || "",
    })
    if (responseFaturamento && responseFaturamento.data && responseFaturamento.data.length > 0) {
      const dados = responseFaturamento.data[0];
      const recebedor = await this.recebedorFaturamentoParticular(dados.recebedor_id);
      this.setState({
        initialValuesFaturamentoParticular: {
          formaPagamento: dados.forma_pagamento_id || "",
          valorAtendimento: dados.valor_atendimento,
          recebedor: recebedor || "",
          id: dados.id || ""
        }
      });
    }
  }

  //Verifica se a data é antes da implementacao do chamado de tipo do anexo.
  verificaDataAntesImplTipoAnexo = (values) => {
    const dataCadastro = new Date(values.dataCadastro);
    dataCadastro.setHours(0, 0, 0, 0);
    if (dataCadastro < objectsConstants.DATE_IMPL_GUIA_HONORARIO_ENVIO_ELETRONICO_AUTOMATICO) {
      return true;
    } else {
      return false;
    }
  }

  verificaESetBloqueioProcedimentoGuiaHospital = async (values, setFieldError) => {
    let bloqueio = 0;
    if(values.hospitalLocal){
      let codigoGuia;
      objectsConstants.TIPOS_GUIA.forEach(element => {
        if (values.type == element.label) {
          codigoGuia = element.id;
        }});

      for (let i = 0; i < values.atendimentoExecucaos.length; i++) {
        if(codigoGuia == 0){
          let response = await regraProcedimnetoBloqueadosGuiaHospitalService.getRegraProcedimnetoBloqueadosGuiaHospital(values.convenio.id,
            codigoGuia, values.hospitalLocal.id, values.atendimentoProcedimentos[i].codigoProcedimento);
          if (response.data > 0) {
            bloqueio += 1;
          setFieldError(`atendimentoProcedimentos[${i}].codigoProcedimento`, "Esse código de procedimento não é válido para o hospital e convênio selecionados")
          }
        }else{
          let response = await regraProcedimnetoBloqueadosGuiaHospitalService.getRegraProcedimnetoBloqueadosGuiaHospital(values.convenio.id,
            codigoGuia, values.hospitalLocal.id, values.atendimentoExecucaos[i].codigoProcedimento);
            if (response.data > 0) {
              bloqueio += 1;
              setFieldError(`atendimentoExecucaos[${i}].codigoProcedimento`, "Esse código de procedimento não é válido para o hospital e convênio selecionados")
          }
        }
      }
      return bloqueio;
    }
    return bloqueio;
  }

  render() {
    let _this = this;
    const { ActionLine } = this.props;
    let situacao = _.get(this.props.atendimento, "status.situacao");
    let statusNome = _.get(this.props.atendimento, "status.nome");
    let canEdit =
      this.validarPermisaoEditarStatusGuiaRecebidaOuGuiaEletronica(situacao) &&
      this.validarPermisaoEditarGuiaInternacaoStatusNaoFaturado(
        situacao,
        statusNome
      ) &&
      (situacao === objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA ||
        situacao === objectsConstants.SITUACAO_ATENDIMENTO.PENDENTE ||
        situacao === objectsConstants.SITUACAO_ATENDIMENTO.RECEBIDA ||
        situacao === objectsConstants.SITUACAO_ATENDIMENTO.GUIA_ELETRONICA ||
        situacao === objectsConstants.SITUACAO_ATENDIMENTO.PENDENCIA_RESOLVIDA ||
        situacao === objectsConstants.SITUACAO_ATENDIMENTO.REMOVIDAS_DO_LOTE ||
        situacao === objectsConstants.SITUACAO_ATENDIMENTO.NAO_FATURADO ||
        situacao === objectsConstants.SITUACAO_ATENDIMENTO.FATURAMENTO_PARTICULAR
      );

    const {
      criarGuiaPassoPasso,
      validateObject,
      GuiaForm,
      initData,
      ...otherProps
    } = this.props;
    const { confirmarQueixa } = this.state;
    if (this.formRef && this.formRef.state && this.formRef.state.values)
      if (
        confirmarQueixa &&
        _.isEqual(this.props.atendimento.type, objectsConstants.GUIASADT)
      ) {
        let valuesProcedimentos = _.cloneDeep(this.formRef.state.values);
        let countProcedimento10101012 = 0;
        valuesProcedimentos.atendimentoProcedimentos.forEach((element) => {
          if (element.codigoProcedimento === "10101012")
            countProcedimento10101012++;
        });

        if (
          valuesProcedimentos.atendimentoProcedimentos.length ===
          countProcedimento10101012
        ) {
          _this.confirmReconsultaSadt(
            <div className="section-form section-form-confirm col-12">
              <FormGroup className={``}>
                <FormTextArea
                  label={"Observação/Justificativa"}
                  type={"textarea"}
                  disabled={false}
                  required={true}
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    _this.setState({ observacao: value });
                  }}
                  name={"observacao"}
                  id={"observacaoJustificativa"}
                  erroMensagem={_this.state.erroObservacao}
                  placeholder="Justifique o atendimento"
                  viewMode={false}
                  value={_this.state.observacao}
                />
              </FormGroup>
            </div>
          );
        } else {
          _this.confirmReconsultaSadt2(
            <div className="section-form section-form-confirm col-12">
              <FormGroup className={``}>
                <FormTextArea
                  label={"Observação/Justificativa"}
                  type={"textarea"}
                  disabled={false}
                  required={true}
                  onChange={(e) => {
                    const { value } = e.currentTarget;
                    _this.setState({ observacao: value });
                  }}
                  name={"observacao"}
                  id={"observacaoJustificativa"}
                  erroMensagem={_this.state.erroObservacao}
                  placeholder="Justifique o atendimento"
                  viewMode={false}
                  value={_this.state.observacao}
                />
              </FormGroup>
            </div>
          );
        }
      } else if (confirmarQueixa) {
        _this.confirmMudarNovaQueixaModal(
          <div className="section-form section-form-confirm col-12">
            <FormGroup className={``}>
              <FormTextArea
                label={"Observação/Justificativa"}
                type={"textarea"}
                disabled={false}
                required={true}
                onChange={(e) => {
                  const { value } = e.currentTarget;
                  _this.setState({ observacao: value });
                }}
                name={"observacao"}
                id={"observacaoJustificativa"}
                erroMensagem={_this.state.erroObservacao}
                placeholder="Justifique o atendimento"
                viewMode={false}
                value={_this.state.observacao}
              />
            </FormGroup>
          </div>
        );
      }
    if (this.state.removerFromLote) {
      ConfirmAcationService.confirmMsg(
        () => {
          this.removerLote(this.props.atendimento, this.props.index);
        },
        `Remover do Lote`,
        <div className="flex-fill m-auto justify-content-center">
          Você confirma que deseja remover o atendimento{" "}
          {this.props.atendimento.numeroAtendimento} ?
          <FormGroup className={``}>
            <FormTextArea
              label={"Justificativa"}
              type={"textarea"}
              disabled={false}
              required={true}
              onChange={(e) => {
                const { value } = e.currentTarget;
                this.setState({ justificativaExclusaoLote: value });
              }}
              maxLength={100}
              id={"observacaoJustificativa"}
              placeholder="Justifique a remoção"
              erroMensagem={this.state.erroJustificativaExclusaoLote}
              viewMode={false}
              value={this.state.justificativaExclusaoLote}
            />
          </FormGroup>
        </div>,
        null,
        "Não",
        "Sim",
        objectsConstants.TYPE_CANCEL,
        () => {
          this.setState({
            removerFromLote: !this.state.removerFromLote,
            justificativaExclusaoLote:
              this.props.atendimento.justificativaExclusaoLote,
          });
        }
      );
    }
    return (
      <React.Fragment>
        <Formik
          validate={(value) => {
            try {
              return validateObject.validate(value, {
                abortEarly: false,
                context: {
                  cooperativa: value.cooperativa,
                  graus: this.state.graus,
                  values: value,
                },
              });
            } catch (err) {
              return yupToFormErrors(err);
            }
          }}
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize={this.state.reinicializar}
          initialValues={initData}
          onSubmit={(values, actions) => {   
            this.setState({ reinicializar: false });
            this.props.loading(true);
            if (values.convenio) {
            beneficiarioService
              .getBloqueioAll([values.numeroCarteira], values.convenio.id)
              .then(async (bloqueio) => {
                if (bloqueio.data.length == 0) {
                  let validacao = await this.validarDuplicata(values);
                  const bloqueios = await this.verificaESetBloqueioProcedimentoGuiaHospital(values, actions.setFieldError)
                  if ( bloqueios == 0){
                  this.props.loading(true);
                  if (validacao == objectsConstants.ATENDIMENTO_DUPLICADO_SALVAR
                    || validacao == objectsConstants.ATENDIMENTO_SEM_DUPLICIDADE) {
                    atendimentoService.save(values).then(
                      (response) => {
                        this.removeRascunho();
                        let id = values.id
                          ? values.id
                          : defaultService.getIdFromUrl(
                            response.headers.location
                          );

                        this.props.success({
                          message: `Atendimento ${values.numeroAtendimento
                            ? values.numeroAtendimento
                            : "M" + id
                            } ${!values.id ? "criado" : "alterado"} com sucesso!`,
                        });
                        values.id = id;
                        _.set(values, "status", { situacao: "AUDITORIA" });
                        this.props.onSaved(values, this.props.index);
                        this.setState({ viewMode: !this.state.viewMode }, () => {
                          this.props.onCancelEditCreate &&
                            this.props.onCancelEditCreate(
                              this.props.atendimento,
                              this.props.index,
                              this.state.viewMode
                            );
                        });
                        this.props.loading(false);
                        this.setState({ isSubmitting: false });
                        this.setState({ reinicializar: true });
                        actions.setSubmitting(false);
                      },
                      (erros) => {
                        console.error(erros.response);
                        if (!this.validarQntProfissionalExec(values)) {
                          this.props.error({
                            message:
                              "Não foi possível criar atendimento, verifique os valores digitados.",
                          });
                        } else {
                          this.props.error({
                            message:
                              "Não foram digitados os profissionais executantes para todos os procedimentos do atendimento. Favor verificar",
                          });
                        }
                        try {
                          let response = erros.response.data;
                          if (response && response.messages) {
                            for (var i = 0; i < response.messages.length; i++) {
                              let erroItem = response.messages[i];
                              if (erroItem.fieldName) {
                                actions.setFieldError(
                                  erroItem.fieldName,
                                  translate(erroItem.message.code)
                                );
                              }
                              if (erroItem.target === "FIELD") {
                                this.props.error({
                                  message: translate(erroItem.message.code),
                                });
                              } else if (erroItem.target === "GLOBAL") {
                                this.props.error({
                                  message: erroItem.message.code,
                                });
                              }
                            }
                          }
                        } catch (error) {
                          console.error(error);
                        }
                        this.props.loading(false);
                        actions.setSubmitting(false);
                        this.setState({ isSubmitting: false });
                      }
                    );
                  } else {
                    this.props.loading(false);
                    this.setState({ isSubmitting: false });
                  }
                  this.props.loading(false);
                } else {
                    this.props.error({ message: enumTipoAvisos.PROCEDIMENTO_BLOQUEADO_GUIA_HOSPITAL });
                    this.props.loading(false);
                    this.setState({ isSubmitting: false });
                  }
                } else {
                  this.props.error({
                    message:
                      "Paciente sem cobertura para atendimento, favor realizar a elegibilidade ou entrar em contato com a operadora",
                  });
                  this.props.loading(false);
                  this.setState({ isSubmitting: false });
                }
              });
            } else {
              actions.setFieldError("convenio.id", "Selecione um convênio.")
              this.props.loading(false);
              actions.setSubmitting(false);
              this.setState({ isSubmitting: false });
            }
          }}
          ref={(form) => {
            this.formRef = form;
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            validateForm,
            setValues,
            touched,
          }) => {
            return (
              <div
                id={this.props.index}
                className={`accordion ${this.state.collapse ? "open" : ""}`}
                ref={this.props.reference}
                style={this.props.estilo}
              >
                <TopoTitleComponente
                  mainTitle={
                    this.props.mainTitle ? this.props.mainTitle : "Guias"
                  } //TODO: Retornar o nome da cooperativa
                  subTitle=" "
                  canBack={true}
                  backUrl={"/atendimento"}
                  backConfirmObject={
                    !this.state.viewMode && {
                      title: `Cancelar ${!values.id ? "criação" : "Edição"} `,
                      message:
                        "Você confirma que deseja " +
                        `cancelar a ${!values.id ? "criação" : "Edição"
                        } do atendimento `,
                      subMessage: null,
                      type: objectsConstants.TYPE_CANCEL,
                    }
                  }
                />
                <div className="card content-form cardGuia cardCrud">
                  <div
                    className="card-header d-flex align-items-center flex-column flex-sm-row b-bottom"
                    id="headingOne"
                    onClick={() => {
                      if (this.props.atendimento.id) {
                        this.toggle();
                      }
                    }}
                  >
                    <div className="flex-grow-1 d-flex align-items-center form-inside">
                      {this.props.atendimento.id && canEdit && (
                        <React.Fragment>
                          <span className="icon-order"></span>
                          <div className="p-1 fz-sm-10">
                            {this.props.index + 1}
                          </div>
                          <div className="p-1 ">
                            <CheckBoxInput
                              className="mb-0 pb-1"
                              label=""
                              name="checkItem"
                              checked={this.isChecked()}
                              onChange={(name, checked) =>
                                this.props.onCheckedAtendimento(
                                  checked,
                                  this.props.atendimento.id,
                                  this.props.atendimento
                                )
                              }
                            />
                          </div>
                        </React.Fragment>
                      )}

                      {this.props.atendimento && this.props.atendimento.id && (
                        <div className="p-1 status-guia">
                          <b>{this.props.atendimento.numeroAtendimento}</b>
                        </div>
                      )}

                      {this.props.atendimento && !this.props.atendimento.id && (
                        <React.Fragment>
                          <div className="section-form w-250p">
                            <SelectInput
                              className=""
                              required={true}
                              label={"Tipo de guia"}
                              name={"tipoGuia"}
                              value={this.props.atendimento.type}
                              options={_.orderBy(
                                objectsConstants.TIPOS_GUIA,
                                ["name"],
                                ["asc"]
                              )}
                              onChange={(name, value) => {
                                this.changeTypeGuia(value);
                              }}
                              returnFullObject={false}
                              labelKey={"name"}
                              valueKey={"label"}
                              placeholder="Tipo de guia de atendimento"
                            />
                          </div>
                          <div className="p-1 status-guia">
                            <span>Em Criação</span>
                          </div>
                        </React.Fragment>
                      )}

                      <div className="p-1 status-guia">
                        {this.props.atendimento &&
                          this.props.atendimento.id && (
                            <b>
                              {this.typeName(this.props.atendimento)} {"-"}{" "}
                            </b>
                          )}
                        {this.hasPendencia(this.props.atendimento) && (
                          <span className={"icon-btn-pendente"}>{"  "}</span>
                        )}
                        {this.props.atendimento &&
                          this.props.atendimento.id &&
                          this.props.atendimento.status && (
                            <span className={"pl-1"}>
                              {this.props.atendimento.status.nome}
                            </span>
                          )}
                        {this.props.atendimento &&
                          !this.props.atendimento.id &&
                          this.props.atendimento.status && (
                            <React.Fragment>Em Criação</React.Fragment>
                          )}
                      </div>
                      {this.props.atendimento.justificativaGlosa && (
                        <React.Fragment>
                          <i
                            className="icon-info"
                            id={"justificativaGlosaIcon"}
                          />
                          <ToolTipHelp
                            className={"info"}
                            target={"justificativaGlosaIcon"}
                            message={this.props.atendimento.justificativaGlosa}
                          />
                        </React.Fragment>
                      )}
                    </div>
                    <div className="buttons-right ml-auto">
                      {this.props.lote &&
                        this.props.lote.id &&
                        this.props.atendimento &&
                        this.props.atendimento.atendimentoAnexos &&
                        this.props.atendimento.atendimentoAnexos.length > 0 && (
                          <button
                            type="button"
                            className={"btn btn-primary"}
                            onClick={() => {
                              this.toggleAnexos();
                            }}
                          >
                            <span className={"icon-visualizar"}></span>
                            Visualizar Anexo
                          </button>
                        )}
                      {this.props.user.cooperativa &&
                        this.props.user.cooperativa.id ===
                        cooperativaID.SANTACOOP.id && (
                          <BtnLink
                            type={"button"}
                            className={""}
                            icone={"icon-btn-imprimir"}
                            title={"Evolução Clínica"}
                            onSubmit={this.evolucaoClinica}
                            disabled={
                              this.props.pendenciaOpen ||
                              this.props.edicaoEmMassaOpen
                            }
                          />
                        )}
                      {this.state.viewMode &&
                        values.id &&
                        canEdit && this.props.atendimento.status.situacao ===
                        objectsConstants.SITUACAO_ATENDIMENTO.PENDENTE && this.props.atendimento.canSendPendenciaWppHasCelular && (
                          <Can
                            I={objectsConstants.ALTERAR}
                            a={objectsConstants.CRUD_ATENDIMENTOS_EDITAR}
                            ability={this.props.permissions}
                          >
                            <BtnLink
                              type={"button"}
                              className={""}
                              icone={"icon-btn-wpp"}
                              title={"Enviar notificação por Whatsapp"}
                              onSubmit={(e) => {
                                e.stopPropagation();
                                this.toogleModalEnvioPendencia();
                              }}
                            />
                          </Can>
                        )}

                      {
                        this.state.viewMode &&
                        values.id &&
                        this.props.atendimento.convenio.faturamentoParticular && (
                          <BtnLink
                            type={"button"}
                            className={""}
                            icone={"icon-btn-editar"}
                            title={"Dados Faturamento Particular"}
                            onSubmit={this.faturamentoParticular}
                            disabled={
                              this.props.pendenciaOpen ||
                              this.props.edicaoEmMassaOpen ||
                              (this.props.user.cooperativa &&
                                !this.props.user.cooperativa
                                  .permiteEditarGuiaPendente &&
                                this.props.atendimento.status.situacao ===
                                objectsConstants.SITUACAO_ATENDIMENTO
                                  .PENDENTE)
                              ||
                              this.props.atendimento.status.situacao ===
                              objectsConstants.SITUACAO_ATENDIMENTO.GLOSADO
                            }
                          />
                        )}
                      {this.validarPermisaoEditarGuiaRecusadaNaoFaturado(
                        situacao,
                        statusNome
                      ) &&
                        this.state.viewMode &&
                        values.id &&
                        canEdit && (
                          //<Can>
                          <BtnLink
                            type={"button"}
                            className={""}
                            icone={"icon-btn-editar"}
                            title={"Editação em massa"}
                            onSubmit={this.abreEdicaoEmMassa}
                            disabled={
                              this.props.pendenciaOpen ||
                              this.props.edicaoEmMassaOpen ||
                              (this.props.user.cooperativa &&
                                !this.props.user.cooperativa
                                  .permiteEditarGuiaPendente &&
                                this.props.atendimento.status.situacao ===
                                objectsConstants.SITUACAO_ATENDIMENTO
                                  .PENDENTE) ||
                              (this.state.statusEdicao.length > 0 &&
                                this.props.atendimento.status.situacao ===
                                objectsConstants.SITUACAO_ATENDIMENTO
                                  .PROTOCOLADAS &&
                                !this.props.atendimento.status.primeiroStatus &&
                                _.filter(this.state.statusEdicao, {
                                  nome: this.props.atendimento.status.nome,
                                }).length === 0) ||
                              this.props.atendimento.status.situacao ===
                              objectsConstants.SITUACAO_ATENDIMENTO.GLOSADO
                            }
                          />
                        )}
                      {this.validarPermisaoEditarGuiaRecusadaNaoFaturado(
                        situacao,
                        statusNome
                      ) &&
                        this.state.viewMode &&
                        values.id &&
                        canEdit && (
                          <Can
                            I={objectsConstants.ALTERAR}
                            a={objectsConstants.CRUD_ATENDIMENTOS_EDITAR}
                            ability={this.props.permissions}
                          >
                            <BtnLink
                              type={"button"}
                              className={""}
                              icone={"icon-btn-editar"}
                              title={"Editar"}
                              onSubmit={this.editMode}
                              disabled={
                                this.props.pendenciaOpen ||
                                this.props.edicaoEmMassaOpen ||
                                (this.props.user.cooperativa &&
                                  !this.props.user.cooperativa
                                    .permiteEditarGuiaPendente &&
                                  this.props.atendimento.status.situacao ===
                                  objectsConstants.SITUACAO_ATENDIMENTO
                                    .PENDENTE) ||
                                (this.state.statusEdicao.length > 0 &&
                                  this.props.atendimento.status.situacao ===
                                  objectsConstants.SITUACAO_ATENDIMENTO
                                    .PROTOCOLADAS &&
                                  !this.props.atendimento.status
                                    .primeiroStatus &&
                                  _.filter(this.state.statusEdicao, {
                                    nome: this.props.atendimento.status.nome,
                                  }).length === 0)
                              }
                            />
                          </Can>
                        )}
                      {this.state.viewMode &&
                        values.id &&
                        canEdit &&
                        (values.type ===
                          objectsConstants.ENUM_TIPOS_GUIA.SADT ||
                          values.type ===
                          objectsConstants.ENUM_TIPOS_GUIA.CONSULTA) &&
                        userService.isCooperado(this.props.user) && (
                          <Can
                            I={objectsConstants.ALTERAR}
                            a={objectsConstants.CRUD_ATENDIMENTOS_EDITAR}
                            ability={this.props.permissions}
                          >
                            <BtnLink
                              type={"button"}
                              className={""}
                              icone={"icon-btn-editar"}
                              title={"Prescrever exames"}
                              onSubmit={(e) => {
                                e.stopPropagation();
                                this.toogleModalExames();
                              }}
                              disabled={
                                this.props.pendenciaOpen ||
                                this.props.edicaoEmMassaOpen ||
                                (this.props.user.cooperativa &&
                                  !this.props.user.cooperativa
                                    .permiteEditarGuiaPendente &&
                                  this.props.atendimento.status.situacao ===
                                  objectsConstants.SITUACAO_ATENDIMENTO
                                    .PENDENTE) ||
                                (this.state.statusEdicao.length > 0 &&
                                  this.props.atendimento.status.situacao ===
                                  objectsConstants.SITUACAO_ATENDIMENTO
                                    .PROTOCOLADAS &&
                                  !this.props.atendimento.status
                                    .primeiroStatus &&
                                  _.filter(this.state.statusEdicao, {
                                    nome: this.props.atendimento.status.nome,
                                  }).length === 0)
                              }
                            />
                          </Can>
                        )}
                      {this.state.viewMode &&
                        values.id &&
                        canEdit &&
                        values.exames &&
                        (values.type ===
                          objectsConstants.ENUM_TIPOS_GUIA.SADT ||
                          values.type ===
                          objectsConstants.ENUM_TIPOS_GUIA.CONSULTA) && (
                          <Can
                            I={objectsConstants.ALTERAR}
                            a={objectsConstants.CRUD_ATENDIMENTOS_EDITAR}
                            ability={this.props.permissions}
                          >
                            <BtnLink
                              type={"button"}
                              className={""}
                              icone={"icon-btn-imprimir"}
                              title={"Enviar exames"}
                              onSubmit={(e) => {
                                e.stopPropagation();
                                this.toogleModalImprimirExames();
                              }}
                              disabled={
                                this.props.pendenciaOpen ||
                                this.props.edicaoEmMassaOpen ||
                                (this.props.user.cooperativa &&
                                  !this.props.user.cooperativa
                                    .permiteEditarGuiaPendente &&
                                  this.props.atendimento.status.situacao ===
                                  objectsConstants.SITUACAO_ATENDIMENTO
                                    .PENDENTE) ||
                                (this.state.statusEdicao.length > 0 &&
                                  this.props.atendimento.status.situacao ===
                                  objectsConstants.SITUACAO_ATENDIMENTO
                                    .AUDITORIA &&
                                  !this.props.atendimento.status
                                    .primeiroStatus &&
                                  _.filter(this.state.statusEdicao, {
                                    nome: this.props.atendimento.status.nome,
                                  }).length === 0)
                              }
                            />
                          </Can>
                        )}
                      {this.state.viewMode &&
                        values.id &&
                        (situacao ===
                          objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA ||
                          situacao ===
                          objectsConstants.SITUACAO_ATENDIMENTO.PENDENTE ||
                          situacao ===
                          objectsConstants.SITUACAO_ATENDIMENTO.RECEBIDA ||
                          situacao ===
                          objectsConstants.SITUACAO_ATENDIMENTO.GUIA_ELETRONICA ||
                          situacao ===
                          objectsConstants.SITUACAO_ATENDIMENTO
                            .PENDENCIA_RESOLVIDA ||
                          situacao ===
                          objectsConstants.SITUACAO_ATENDIMENTO
                            .REMOVIDAS_DO_LOTE ||
                          situacao ===
                          objectsConstants.SITUACAO_ATENDIMENTO
                            .NAO_FATURADO ||
                          situacao ===
                          objectsConstants.SITUACAO_ATENDIMENTO
                            .FATURAMENTO_PARTICULAR) && (
                          <Can
                            I={objectsConstants.ALTERAR}
                            a={objectsConstants.CRUD_PENDENCIA_CRIAR}
                            ability={this.props.permissions}
                          >
                            <BtnLink
                              type={"button"}
                              className={""}
                              icone={"icon-btn-pendencia"}
                              title={"Pendência"}
                              onSubmit={this.abrePendencia}
                              disabled={
                                this.props.pendenciaOpen ||
                                this.props.edicaoEmMassaOpen
                              }
                            />
                          </Can>
                        )}
                      {this.state.viewMode &&
                        values.id &&
                        this.props.removeLote &&
                        (situacao ===
                          objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA ||
                          situacao ===
                          objectsConstants.SITUACAO_ATENDIMENTO.PENDENTE ||
                          situacao ===
                          objectsConstants.SITUACAO_ATENDIMENTO.RECEBIDA ||
                          situacao ===
                          objectsConstants.SITUACAO_ATENDIMENTO.GUIA_ELETRONICA ||
                          situacao ===
                          objectsConstants.SITUACAO_ATENDIMENTO
                            .PENDENCIA_RESOLVIDA ||
                          situacao ===
                          objectsConstants.SITUACAO_ATENDIMENTO
                            .REMOVIDAS_DO_LOTE) && (
                          <Can
                            I={objectsConstants.ALTERAR}
                            a={objectsConstants.CRUD_PENDENCIA_CRIAR}
                            ability={this.props.permissions}
                          >
                            <BtnLink
                              type={"button"}
                              className={""}
                              icone={"icon-btn-excluir"}
                              title={"Remover do Lote"}
                              onSubmit={(e) => {
                                e.stopPropagation();
                                this.setState({
                                  removerFromLote: !this.state.removerFromLote,
                                });
                              }}
                            />
                          </Can>
                        )}
                      {this.state.viewMode &&
                        values.id &&
                        this.props.removeLote &&
                        values.atendimentoExcluidoSasc &&
                        situacao !==
                        objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA &&
                        situacao !==
                        objectsConstants.SITUACAO_ATENDIMENTO.PENDENTE &&
                        situacao !==
                        objectsConstants.SITUACAO_ATENDIMENTO.RECEBIDA &&
                        situacao !==
                        objectsConstants.SITUACAO_ATENDIMENTO.GUIA_ELETRONICA &&
                        situacao !==
                        objectsConstants.SITUACAO_ATENDIMENTO
                          .PENDENCIA_RESOLVIDA &&
                        situacao !==
                        objectsConstants.SITUACAO_ATENDIMENTO
                          .REMOVIDAS_DO_LOTE && (
                          <Can
                            I={objectsConstants.ALTERAR}
                            a={objectsConstants.CRUD_PENDENCIA_CRIAR}
                            ability={this.props.permissions}
                          >
                            <BtnLink
                              type={"button"}
                              className={""}
                              icone={"icon-btn-excluir"}
                              title={"Remover do Lote"}
                              onSubmit={(e) => {
                                e.stopPropagation();
                                this.setState({
                                  removerFromLote: !this.state.removerFromLote,
                                  removerFaturadoFromLote: true,
                                });
                              }}
                            />
                          </Can>
                        )}
                      {this.state.btnJustificarGlosaReapresentar == true
                        ? this.state.viewMode &&
                        values.id &&
                        this.state.glosas &&
                        this.props.atendimento.status.situacao !==
                        objectsConstants.SITUACAO_ATENDIMENTO.PENDENTE &&
                        this.props.atendimento.status.situacao !==
                        objectsConstants.SITUACAO_ATENDIMENTO.AUDITORIA && (
                          <Can
                            I={objectsConstants.ALTERAR}
                            a={objectsConstants.CRUD_ATENDIMENTOS_EDITAR}
                            ability={this.props.permissions}
                          >
                            <BtnLink
                              type={"button"}
                              className={""}
                              icone={"icon-btn-editar"}
                              title={"Justificar glosa"}
                              onSubmit={this.toggleModalJustificativaGlosa}
                            />
                          </Can>
                        )
                        : ""}
                      {this.state.viewMode && values.id && (
                        <React.Fragment>
                          <BtnLink
                            type={"button"}
                            className={""}
                            icone={"icon-btn-historico"}
                            title={"Histórico"}
                            onSubmit={(e) => {
                              e.stopPropagation();
                              this.props.toggleHistorico(values);
                            }}
                            disabled={false}
                          />
                          <BtnLink
                            type={"button"}
                            className={""}
                            icone={"icon-btn-imprimir"}
                            title={"Imprimir"}
                            onSubmit={(e) => {
                              e.stopPropagation();
                              let showAviso =
                                values.status.situacao ===
                                objectsConstants.SITUACAO_ATENDIMENTO
                                  .RECEBIDA ||
                                values.status.situacao ===
                                objectsConstants.SITUACAO_ATENDIMENTO
                                  .GUIA_ELETRONICA ||
                                values.status.situacao ===
                                objectsConstants.SITUACAO_ATENDIMENTO
                                  .REPASSADO ||
                                values.status.situacao ===
                                objectsConstants.SITUACAO_ATENDIMENTO
                                  .GLOSADO ||
                                values.status.situacao ===
                                objectsConstants.SITUACAO_ATENDIMENTO
                                  .FATURAMENTO;

                              const tipoPerfil =
                                this.props.user.perfilAtual.perfil.tipoPerfil;
                              let isPerfilAviso =
                                tipoPerfil === objectsConstants.CONSULTORIO ||
                                tipoPerfil === objectsConstants.COOPERADO;

                              if (showAviso && isPerfilAviso) {
                                ConfirmAcationService.confirmMsg(
                                  () => this.imprimirAtendimento(values),
                                  `As guias selecionadas já se encontram em processo de faturamento, portanto, não há necessidade de enviá-las novamente para cooperativa, deseja continuar?`,
                                  null,
                                  null,
                                  "Cancelar",
                                  "Continuar",
                                  objectsConstants.TYPE_WARNING
                                );
                              } else {
                                this.imprimirAtendimento(values);
                              }
                            }}
                            disabled={false}
                          />
                        </React.Fragment>
                      )}
                      {this.state.viewMode &&
                        values.id &&
                        _.size(values.atendimentoProcedimentos) > 1 &&
                        values.status.permiteSepararAtendimento && (
                          <React.Fragment>
                            <Can
                              I={objectsConstants.ALTERAR}
                              a={objectsConstants.SEPARAR_PROCEDIMENTOS}
                              ability={this.props.permissions}
                            >
                              <BtnLink
                                type={"button"}
                                className={""}
                                icone={"icon-btn-separar"}
                                title={"Separar procedimentos"}
                                onSubmit={(e) => {
                                  e.stopPropagation();
                                  this.setState(
                                    {
                                      procedimentosSeparacaoGuia:
                                        values.atendimentoProcedimentos,
                                    },
                                    () => {
                                      this.toogleModalSepararProcedimentos();
                                    }
                                  );
                                }}
                                disabled={false}
                              />
                            </Can>
                          </React.Fragment>
                        )}

                      {!this.props.listStatusEncam &&
                        this.getLimpaBtn()}

                      {!criarGuiaPassoPasso &&
                        this.getCancelBtn(values, canEdit)}
                      {!criarGuiaPassoPasso &&
                        this.getSaveBtn(values, validateForm, handleSubmit)}

                      {this.state.viewMode && this.canDelete() && (
                        <Can
                          I={objectsConstants.ALTERAR}
                          a={objectsConstants.CRUD_ATENDIMENTOS_DELETAR}
                          ability={this.props.permissions}
                        >
                          <BtnLink
                            //conferir
                            type={"button"}
                            className={""}
                            icone={"icon-btn-excluir"}
                            title={"Excluir"}
                            onSubmit={(e) => {
                              e.stopPropagation();
                              this.toogleModalExclusao();
                            }}
                            disabled={false}
                          />
                        </Can>
                      )}
                      {this.state.viewMode &&
                        this.props.atendimento.status.situacao !==
                        objectsConstants.SITUACAO_ATENDIMENTO.FATURAMENTO &&
                        this.props.atendimento.status.situacao !==
                        objectsConstants.SITUACAO_ATENDIMENTO.GLOSADO &&
                        this.props.atendimento.status.situacao !==
                        objectsConstants.SITUACAO_ATENDIMENTO.REPASSADO &&
                        ((!userService.isConsultorio(this.props.user) &&
                          !userService.isCooperado(this.props.user)) ||
                          this.props.atendimento.type ===
                          objectsConstants.TIPOS_GUIA[3].label) && (
                          <Can
                            I={objectsConstants.ALTERAR}
                            a={objectsConstants.ALTERAR_TIPO_GUIA}
                            ability={this.props.permissions}
                          >
                            <BtnLink
                              type={"button"}
                              className={""}
                              icone={"icon-btn-editar"}
                              title={"Alterar Tipo da Guia"}
                              onSubmit={(e) => {
                                e.stopPropagation();
                                this.toogleTipoGuia();
                              }}
                              disabled={false}
                            />
                          </Can>
                        )}
                      {this.state.viewMode &&
                        this.state.inconsistencia &&
                        this.state.inconsistencia.some(t => !t.concluida) &&
                        this.props.atendimento.status.situacao != objectsConstants.SITUACAO_ATENDIMENTO.FATURAMENTO &&
                        this.props.atendimento.status.situacao != objectsConstants.SITUACAO_ATENDIMENTO.GLOSADO &&
                        this.props.atendimento.status.situacao != objectsConstants.SITUACAO_ATENDIMENTO.REPASSADO &&
                        (
                          <BtnLink
                            type={"button"}
                            className={""}
                            icone={"icon-auxiliares"}
                            title={"Validar Auxiliares"}
                            onSubmit={e => {
                              e.stopPropagation();
                              this.validarAuxiliares(this.props.atendimento);
                            }}
                            disabled={false}
                          />
                        )
                      }
                      {ActionLine && canEdit && (
                        <ActionLine
                          onSaved={this.props.onSaved}
                          index={this.props.index}
                          atendimento={this.props.atendimento}
                        ></ActionLine>
                      )}
                    </div>
                    <span
                      className={`${this.state.collapse ? "icon-traco" : "icon-seta-baixo"
                        }`}
                    ></span>
                  </div>

                  <Collapse isOpen={this.state.collapse}>
                    <div
                      id="guia"
                      className={`collapse show ${this.state.viewMode ? "viewMode" : ""
                        }`}
                      aria-labelledby="headingOne"
                      data-parent="#guiaUm"
                    >
                      {this.state.viewMode &&
                        this.state.inconsistencia &&
                        this.state.inconsistencia.some(t => !t.concluida) && (
                          <div className="cardInconsistencia d-flex flex-column flex-sm-column">
                            <div>
                              <span>Inconsistência de auxiliares</span>
                            </div>
                            {this.exibicaoInconsistencia(this.state.inconsistencia)}
                          </div>
                        )}

                      <React.Fragment>
                        <GuiaForm
                          criarGuiaPassoPasso={criarGuiaPassoPasso}
                          errors={
                            errors && errors.errors
                              ? yupToFormErrors(errors)
                              : errors
                          }
                          setFieldValue={setFieldValue}
                          atendimento={this.props.atendimento}
                          values={values}
                          handleChange={handleChange}
                          viewMode={this.state.viewMode}
                          user={this.props.user}
                          setValues={setValues}
                          touched={touched}
                          verificaRetorno={this.verificaRetorno}
                          changeGuia={this.state.changeGuia}
                          {...otherProps}
                        />
                      </React.Fragment>
                      {!criarGuiaPassoPasso && (
                        <div className="bg-branco text-right py-4 pr-2 mb-3">
                          {this.getCancelBtn(values, canEdit)}
                          {this.getSaveBtn(values, validateForm, handleSubmit)}
                        </div>
                      )}
                    </div>
                  </Collapse>

                  {this.props.atendimento.id && (
                    <MenuHistorico
                      titulo={this.props.atendimento.numeroAtendimento}
                      subTitulo={this.props.atendimento.status.nome}
                      listaHistoricos={this.state.listaHistoricos}
                      toggleHistorico={this.toggleHistorico}
                      openMenuHistorico={this.state.openMenuHistorico}
                    />
                  )}
                </div>
              </div>
            );
          }}
        </Formik>
        <Modal
          isOpen={this.state.showModalExclusao}
          toggle={this.toogleModalExclusao}
          backdrop="static"
          modalClassName=""
          className=""
          centered={true}
        >
          <ModalBody>
            <Formik
              validationSchema={Yup.object().shape({
                justificativa: Yup.string().required("Obrigatorio"),
              })}
              validateOnBlur={false}
              validateOnChange={false}
              enableReinitialize={true}
              initialValues={{}}
              onSubmit={(values, actions) => {
                this.deleteAtendimento(values.justificativa);
              }}
              ref={(form) => {
                this.formRef = form;
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
                validateForm,
                setValues,
                touched,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modalCooperado">
                      <div className="row">
                        <div className="col-11 text-center my-2">
                          <h2 className="font-weight-bold">
                            Informe o motivo de exclusão do atendimento:
                          </h2>
                        </div>
                      </div>

                      <div className="row">
                        <div className="flex-fill px-5 m-auto justify-content-center">
                          <FormGroup className="col-12">
                            <FormTextArea
                              label={"Justificativa"}
                              id={"justificativa"}
                              className={"textArea"}
                              type={"textarea"}
                              disabled={false}
                              onChange={handleChange}
                              name={"justificativa"}
                              erroMensagem={errors.justificativa}
                              placeholder=""
                              value={values.justificativa}
                            />
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="flex-grow px-1 m-auto justify-content-center">
                          <button
                            className="btn btn-secondary white mt-3 mb-4 mx-2"
                            type={"button"}
                            onClick={() => {
                              this.toogleModalExclusao();
                            }}
                          >
                            Cancelar
                          </button>
                          <button
                            className="btn btn-primary white mt-3 mb-4 mx-2"
                            type={"submit"}
                          >
                            Excluir
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.showModalSepararProcedimentos}
          toggle={this.toogleModalSepararProcedimentos}
          backdrop="static"
          modalClassName=""
          className=""
          centered={true}
        >
          <ModalBody>
            <Formik
              validationSchema={Yup.object().shape({
                observacaoSeparacaoGuia: Yup.string()
                  .nullable()
                  .required("Obrigatorio"),
                cooperado: Yup.string().nullable().required("Obrigatorio"),
              })}
              validateOnBlur={false}
              validateOnChange={false}
              enableReinitialize={true}
              initialValues={{
                cooperado:
                  _.uniqBy(
                    initData.identificacaoAtendimentoExecucao.map((iae) => {
                      return iae.cooperado;
                    }),
                    "id"
                  ).length > 1
                    ? null
                    : initData.cooperado,
              }}
              onSubmit={(values) => {
                let procedimentosSeparacaoGuia = _.filter(
                  this.state.procedimentosSeparacaoGuia,
                  { checked: true }
                );
                if (
                  procedimentosSeparacaoGuia.length ==
                  this.state.procedimentosSeparacaoGuia.length
                ) {
                  this.props.error({
                    message: `Não é possivel separar todos atendimentos da guia.`,
                  });
                  return;
                }
                if (procedimentosSeparacaoGuia.length == 0) {
                  this.props.error({
                    message: `É necessario selecionar pelo menos um procedimento para separação.`,
                  });
                  return;
                }
                this.props.loading(true);
                let atendimento = _.cloneDeep(initData);
                atendimento.cooperado = values.cooperado;
                atendimento.observacaoSeparacaoGuia =
                  values.observacaoSeparacaoGuia;
                atendimentoService
                  .separarProcedimentos(procedimentosSeparacaoGuia, atendimento)
                  .then((response) => {
                    this.props.success({
                      message: `Procedimentos separados com sucesso! Foi criado o atendimento ${response.data} com os procedimentos separados.`,
                    });
                    this.props.loading(false);
                    this.setState({ isSubmitting: false });
                    this.props.addAtendimentoView({ id: response.data });
                    this.toogleModalSepararProcedimentos();
                  })
                  .catch(() => {
                    this.props.error({
                      message: `Não foi possivel separar os procedimentos.`,
                    });
                    this.props.loading(false);
                    this.setState({ isSubmitting: false });
                  });
              }}
              ref={(form) => {
                this.formRef = form;
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
                validateForm,
                setValues,
                touched,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modalCooperado">
                      <div className="row">
                        <div className="col-11 text-center my-2">
                          <h2 className="font-weight-bold">
                            Selecione os procedimentos que deseja separar da
                            guia:
                          </h2>
                        </div>
                      </div>

                      <div className="row">
                        <div className={"col-12 row"}>
                          <label for={this.props.name} className="col-12">
                            Procedimentos
                          </label>{" "}
                          {this.state.procedimentosSeparacaoGuia.map(
                            (procedimento, index) => {
                              return (
                                <div className=" col-4 d-flex align-items-center">
                                  <React.Fragment>
                                    <div className={"form-group"}>
                                      <label className="checkbox d-inline-flex">
                                        <input
                                          type="checkbox"
                                          checked={procedimento.checked}
                                          onChange={() => {
                                            let procedimentosSeparacaoGuia =
                                              _.cloneDeep(
                                                this.state
                                                  .procedimentosSeparacaoGuia
                                              );
                                            procedimentosSeparacaoGuia[
                                              index
                                            ].checked =
                                              !procedimentosSeparacaoGuia[index]
                                                .checked;
                                            this.setState({
                                              procedimentosSeparacaoGuia,
                                            });
                                          }}
                                          id={"procedimento.id"}
                                          name={
                                            "procedimento.codigoProcedimento"
                                          }
                                        />
                                        <i className="icon-checkbox" />
                                      </label>
                                      <span className={"label"}>
                                        {procedimento.codigoProcedimento}
                                      </span>
                                    </div>
                                  </React.Fragment>
                                </div>
                              );
                            }
                          )}
                        </div>
                      </div>
                      {_.uniqBy(
                        initData.identificacaoAtendimentoExecucao.map((iae) => {
                          return iae.cooperado;
                        }),
                        "id"
                      ).length > 1 && (
                          <div className={"row"}>
                            <SelectInput
                              isMulti={false}
                              label={"Cooperado principal da guia"}
                              onChange={(name, value) => {
                                setFieldValue(name, value);
                              }}
                              name={"cooperado"}
                              id={"cooperado"}
                              style={"col-12"}
                              value={values.cooperado}
                              valueKey={"id"}
                              labelKey={"nome"}
                              erroMensagem={errors.cooperado}
                              options={_.uniqBy(
                                initData.identificacaoAtendimentoExecucao.map(
                                  (iae) => {
                                    return iae.cooperado;
                                  }
                                ),
                                "id"
                              )}
                              returnFullObject={true}
                              required={true}
                              clearable={true}
                              placeholder={"Cooperado principal da guia"}
                            />
                          </div>
                        )}
                      <div className={"row"}>
                        <FormTextArea
                          label={"Observação"}
                          id={"obs"}
                          className={"textArea col-12"}
                          type={"textarea"}
                          disabled={false}
                          onChange={(e) => {
                            setFieldValue(
                              "observacaoSeparacaoGuia",
                              e.target.value
                            );
                          }}
                          erroMensagem={errors.observacaoSeparacaoGuia}
                          name={"observacaoSeparacaoGuia"}
                          placeholder=""
                          value={values.observacaoSeparacaoGuia}
                        />
                      </div>
                      <div className="row">
                        <div className="flex-grow px-1 m-auto justify-content-center">
                          <button
                            className="btn btn-secondary white mt-3 mb-4 mx-2"
                            type={"button"}
                            onClick={() => {
                              this.toogleModalSepararProcedimentos();
                            }}
                          >
                            Cancelar
                          </button>
                          <button
                            className="btn btn-primary white mt-3 mb-4 mx-2"
                            type={"submit"}
                          >
                            Separar
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.showModalTrocarGuia}
          toggle={this.toogleTipoGuia}
          backdrop="static"
          modalClassName=""
          className=""
          centered={true}
        >
          <ModalBody>
            <Formik
              validationSchema={Yup.object().shape({
                justificativa: Yup.string().required("Obrigatorio"),
              })}
              validateOnBlur={false}
              validateOnChange={false}
              enableReinitialize={true}
              initialValues={{}}
              onSubmit={(values, actions) => {
                this.deleteAtendimento(values.justificativa);
              }}
              ref={(form) => {
                this.formRef = form;
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
                validateForm,
                setValues,
                touched,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modalCooperado">
                      <div className="row">
                        <div className="col-11 text-center my-2">
                          <h2 className="font-weight-bold">
                            Informe o tipo de guia para qual deseja alterar:
                          </h2>
                        </div>
                      </div>
                      <div className="row">
                        <div className="flex-fill px-5 m-auto justify-content-center">
                          <FormGroup className="col-12">
                            <SelectInput
                              required={true}
                              label={"Tipo de guia"}
                              className=""
                              name={"tipoGuia"}
                              value={this.state.typeEditGuia}
                              options={
                                userService.isConsultorio(this.props.user) ||
                                  userService.isCooperado(this.props.user)
                                  ? [objectsConstants.TIPOS_GUIA_CONSULTORIO[1]]
                                  : _.orderBy(
                                    objectsConstants.TIPOS_GUIA.filter(
                                      (tipo) => {
                                        if (
                                          tipo &&
                                          tipo.label !==
                                          this.props.atendimento.type
                                        ) {
                                          return tipo;
                                        }
                                      }
                                    ),
                                    ["name"],
                                    ["asc"]
                                  ).filter((array1) => {
                                    return (
                                      this.props.atendimento.convenio.listRegrasEstabelecimentoTipoGuia.findIndex(
                                        (array2) => {
                                          return (
                                            array1.id == array2.tipoGuia.id ||
                                            array1.name ==
                                            array2.tipoGuia.nome
                                          );
                                        }
                                      ) === -1
                                    );
                                  })
                              }
                              onChange={(name, value) => {
                                this.changeTypeGuiaEdicao(value);
                              }}
                              returnFullObject={true}
                              labelKey={"name"}
                              valueKey={"id"}
                              placeholder="Tipo de guia de atendimento"
                            />
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="flex-grow px-1 m-auto justify-content-center">
                          <button
                            className="btn btn-secondary white mt-3 mb-4 mx-2"
                            onClick={() => {
                              this.toogleTipoGuia();
                            }}
                          >
                            Cancelar
                          </button>
                          <button
                            className="btn btn-primary white mt-3 mb-4 mx-2"
                            onClick={() => {
                              this.changeTypeGuiaSubmit();
                            }}
                          >
                            Alterar
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.showModalJustificativaGlosa}
          toggle={this.toggleModalJustificativaGlosa}
          backdrop="static"
          modalClassName=""
          className=""
          centered={true}
        >
          <ModalBody>
            <Formik
              validationSchema={Yup.object().shape({
                justificativa: Yup.string().required("Obrigatorio"),
              })}
              validateOnBlur={false}
              validateOnChange={false}
              enableReinitialize={true}
              initialValues={{}}
              onSubmit={(values, actions) => {
                this.salvarJustificativaGlosa(values.justificativa);
              }}
              ref={(form) => {
                this.formRef = form;
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
                validateForm,
                setValues,
                touched,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modalCooperado">
                      <div className="row">
                        <div className="col-11 text-center my-2">
                          <h2 className="font-weight-bold">
                            Informe o motivo de glosa do atendimento:
                          </h2>
                        </div>
                      </div>

                      <div className="row">
                        <div className="flex-fill px-5 m-auto justify-content-center">
                          <FormGroup className="col-12">
                            <FormTextArea
                              label={"Justificativa"}
                              id={"justificativa"}
                              className={"textArea"}
                              type={"textarea"}
                              disabled={false}
                              onChange={handleChange}
                              name={"justificativa"}
                              erroMensagem={errors.justificativa}
                              placeholder=""
                              value={values.justificativa}
                            />
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="flex-grow px-1 m-auto justify-content-center">
                          <button
                            className="btn btn-secondary white mt-3 mb-4 mx-2"
                            type={"button"}
                            onClick={(e) => {
                              e.preventDefault();
                              this.toggleModalJustificativaGlosa();
                            }}
                          >
                            Cancelar
                          </button>
                          <button
                            className="btn btn-primary white mt-3 mb-4 mx-2"
                            type={"submit"}
                          >
                            Salvar
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.showModalExames}
          toggle={this.toogleModalExames}
          backdrop="static"
          modalClassName=""
          centered={true}
          size={"lg"}
        >
          <ModalBody>
            <Formik
              validationSchema={Yup.object().shape({
                exames: Yup.string().required("Obrigatorio").nullable(),
              })}
              validateOnBlur={false}
              validateOnChange={false}
              enableReinitialize={true}
              initialValues={initData}
              onSubmit={(values, actions) => {
                this.confirmExames(false);
              }}
              ref={(form) => {
                this.formRef = form;
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
                validateForm,
                setValues,
                touched,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modalExames">
                      <div className="row">
                        <div className="col-11 text-center my-2">
                          <h2 className="font-weight-bold">
                            Exames solicitados:
                          </h2>
                        </div>
                      </div>

                      <div className="row">
                        <div className="flex-fill px-5 m-auto justify-content-center overflow-auto">
                          <FormGroup className="">
                            <InputViewEdit
                              label={"Pacote(s) de exame(s)"}
                              component={FormSelectInput}
                              multi={true}
                              isMulti={true}
                              onChange={(name, date) => {
                                setFieldValue(name, date);

                                let examesList = [];
                                let oldExames = values.exames
                                  ? values.exames
                                  : [];
                                let oldPacotes = values.pacotes
                                  ? values.pacotes
                                  : [];

                                if (!values.pacotes) {
                                  let ve = this.props.atendimento.exames;

                                  ve &&
                                    ve.map((item) => {
                                      examesList.push(item);
                                    });
                                }

                                oldPacotes &&
                                  oldPacotes.map((oldP) => {
                                    oldP.exames.map((eOld) => {
                                      oldExames = oldExames.filter(
                                        (objeto) => objeto.id !== eOld.id
                                      );
                                    });
                                  });

                                date &&
                                  date.map((e) => {
                                    e.exames.map((item) => {
                                      examesList.push(item);
                                    });
                                  });

                                oldExames &&
                                  oldExames.map((item) => {
                                    examesList.push(item);
                                  });

                                const arrUniq = [
                                  ...new Map(
                                    examesList.map((v) => [v.id, v])
                                  ).values(),
                                ];

                                setFieldValue(`exames`, arrUniq);
                              }}
                              required={false}
                              name={"pacotes"}
                              placeholder="Pacote(s) de exame(s)"
                              value={values.pacotes}
                              clearable={true}
                              labelKey={"nomePacote"}
                              valueKey={"nomePacote"}
                              returnFullObject={true}
                              parent={this.IdsCooperadosAtendimento(values)}
                              service={pacoteExameService.findByCooperado}
                              components={{
                                Control,
                                MultiValueRemove,
                                ClearIndicator,
                              }}
                            />
                          </FormGroup>
                          <FormGroup className="">
                            <InputViewEdit
                              label={"Descrição do(s) exame(s)"}
                              component={SelectInput}
                              multi={true}
                              isMulti={true}
                              onChange={(name, date) => {
                                setFieldValue(name, date);

                                let exames = date;
                                if (values.pacotes) {
                                  values.pacotes.map((pacote) => {
                                    let containsAll = true;
                                    if (exames) {
                                      containsAll = exames.every(
                                        (obj) =>
                                          !pacote.exames.some(
                                            (obj2) => obj2.id === obj.id
                                          )
                                      );
                                    }
                                    if (containsAll) {
                                      let newPacotes = values.pacotes.filter(
                                        (obj) => obj.id !== pacote.id
                                      );
                                      setFieldValue(`pacotes`, newPacotes);
                                    }
                                  });
                                }
                              }}
                              required={false}
                              name={"exames"}
                              type={"text"}
                              value={values.exames}
                              defaultValue={
                                values.exames
                                  ? values.exames.map((e, index) => {
                                    let value = e.descricao;
                                    if (index > 0) {
                                      value = ", " + value;
                                    }
                                    return value;
                                  })
                                  : ""
                              }
                              clearable={true}
                              labelKey={"descricao"}
                              valueKey={"descricao"}
                              returnFullObject={true}
                              onFetchData={procedimentoService.find}
                              components={{
                                Control,
                                MultiValueRemove,
                                ClearIndicator,
                              }}
                            />
                          </FormGroup>
                          <FormGroup className="">
                            <InputViewEdit
                              label={"Código(s) de exame(s)"}
                              component={SelectInput}
                              multi={true}
                              isMulti={true}
                              onChange={(name, date) => {
                                setFieldValue(name, date);

                                let exames = date;
                                if (values.pacotes) {
                                  values.pacotes.map((pacote) => {
                                    let containsAll = true;
                                    if (exames) {
                                      containsAll = exames.every(
                                        (obj) =>
                                          !pacote.exames.some(
                                            (obj2) => obj2.id === obj.id
                                          )
                                      );
                                    }
                                    if (containsAll) {
                                      let newPacotes = values.pacotes.filter(
                                        (obj) => obj.id !== pacote.id
                                      );
                                      setFieldValue(`pacotes`, newPacotes);
                                    }
                                  });
                                }
                              }}
                              required={false}
                              name={"exames"}
                              type={"text"}
                              value={values.exames}
                              clearable={true}
                              labelKey={"codigo"}
                              valueKey={"codigo"}
                              returnFullObject={true}
                              onFetchData={procedimentoService.find}
                              erroMensagem={errors.exames}
                              components={{
                                Control,
                                MultiValueRemove,
                                ClearIndicator,
                              }}
                            />
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="flex-grow px-1 m-auto justify-content-center">
                          <button
                            className="btn btn-secondary white mt-3 mb-4 mx-2"
                            type={"button"}
                            onClick={(e) => {
                              e.preventDefault();
                              this.toogleModalExames();
                            }}
                          >
                            Cancelar
                          </button>
                          <button
                            className="btn btn-primary white mt-3 mb-4 mx-2"
                            type={"submit"}
                          >
                            Salvar
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.showModalEnvioPendencia}
          toggle={this.toogleModalEnvioPendencia}
          backdrop="static"
          modalClassName=""
          centered={true}
        >
          <ModalBody>
            <Formik
              validateOnBlur={false}
              validateOnChange={false}
              enableReinitialize={true}
              initialValues={{}}
              onSubmit={(values, actions) => {
                this.enviarNotificacaoPendenciaWpp()
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
                validateForm,
                setValues,
                touched,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modalCooperado">
                      <div className="row">
                        <div className="col-11 text-center my-2">
                          <h2 className="font-weight-bold">
                            Deseja enviar uma notificação via WhatsApp para o cooperado?
                          </h2>
                        </div>
                      </div>

                      {this.props.atendimento.canSendPendenciaWppHasCelular ?
                        <div className="col-11 text-center my-2">
                          <p>
                            Selecione abaixo todos os cooperados ou perfil consultório do atendimento que precisam
                            ser notificados com esta pendência
                          </p>{" "}
                          <hr className={"quebra-linha"} />
                          <div className={"box-list-usuario"}>
                            <React.Fragment>
                              <div className={"list-usuarios"}>
                                {this.props.atendimento.canSendPendenciaWppEstabelecimento && <div className={"list-usuario-item d-inline-flex"}>
                                  <CheckBoxInput
                                    label={"Consultório"}
                                    name={"sendWppConsultorio"}
                                    onChange={this.handleEnvioWppCooperado}
                                    checked={this.state.sendWppConsultorio}
                                    labelClass={"label-checkbox"}
                                    style={"mb-0"}
                                  />
                                </div>}
                                {this.props.atendimento.canSendPendenciaWppAtendimento && this.props.atendimento.usuariosToSendPendenciaWpp.map((user, index) => {
                                  return (
                                    <div className={"list-usuario-item d-inline-flex"}>
                                      <CheckBoxInput
                                        label={user.nome}
                                        name={`cooperados[${index}].checked`}
                                        onChange={this.handleEnvioWppCooperado}
                                        checked={user.checked}
                                        labelClass={"label-checkbox"}
                                        style={"mb-0"}
                                      />
                                    </div>
                                  );
                                })}
                              </div>
                            </React.Fragment>
                          </div>
                        </div>
                        :
                        <div className="col-11 text-center my-2">
                          <p>
                            Não existem telefones cadastrados para esse atendimento.
                          </p>{" "}
                        </div>}
                      <div className="row">
                        <div className="flex-grow px-1 m-auto justify-content-center">
                          <button
                            className="btn btn-secondary white mt-3 mb-4 mx-2"
                            type={"button"}
                            onClick={() => {
                              this.toogleModalEnvioPendencia();
                            }}
                          >
                            Cancelar
                          </button>
                          <button
                            className="btn btn-primary white mt-3 mb-4 mx-2"
                            type={"submit"}
                            disabled={!this.state.cooperados.some(cooperado => cooperado.checked) && !this.state.sendWppConsultorio}
                          >
                            Confirmar
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.showModalImprimirExames}
          toggle={this.toogleModalImprimirExames}
          backdrop="static"
          modalClassName=""
          centered={true}
        >
          <ModalBody>
            <Formik
              validationSchema={Yup.object().shape({
                exames: Yup.string().required("Obrigatorio").nullable(),
              })}
              validateOnBlur={false}
              validateOnChange={false}
              enableReinitialize={true}
              initialValues={initData}
              onSubmit={(values, actions) => {
                this.imprimirExames();
              }}
              ref={(form) => {
                this.formRef = form;
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
                validateForm,
                setValues,
                touched,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modalCooperado">
                      <div className="row">
                        <div className="col-11 text-center my-2">
                          <h2 className="font-weight-bold">Enviar exames:</h2>
                        </div>
                      </div>

                      <div className="row">
                        <div className="flex-fill px-5 m-auto justify-content-center">
                          <FormGroup className="">
                            <CheckBoxInput
                              label={"Email"}
                              name={"enviarEmail"}
                              id={"enviarEmail"}
                              value={values.enviarEmail}
                              onChange={(name, date) => {
                                setFieldValue(name, date);
                              }}
                            />
                            <CheckBoxInput
                              label={
                                "Celular (PDF será gerado para ser anexado manualmente)"
                              }
                              name={"enviarCelular"}
                              id={"enviarCelular"}
                              value={values.enviarCelular}
                              onChange={(name, date) => {
                                setFieldValue(name, date);
                                setFieldValue("enviarPDF", date);
                              }}
                            />
                            <CheckBoxInput
                              label={"Gerar PDF"}
                              name={"enviarPDF"}
                              id={"enviarPDF"}
                              checked={values.enviarCelular ? true : false}
                              value={values.enviarPDF}
                              onChange={(name, date) => {
                                setFieldValue(name, date);
                              }}
                            />
                          </FormGroup>
                          {(values.enviarEmail || values.enviarCelular) && (
                            <FormGroup>
                              {values.enviarEmail && (
                                <InputViewEdit
                                  component={FormInput}
                                  label={"Email"}
                                  type="text"
                                  id="email"
                                  name="email"
                                  placeholder="Digite o email para enviar"
                                  value={values.email}
                                  onChange={(name, value) =>
                                    setFieldValue(name, value)
                                  }
                                  noSize={true}
                                  required={true}
                                />
                              )}
                              {values.enviarCelular && (
                                <InputViewEdit
                                  component={PhoneInput}
                                  type="number"
                                  label="Celular"
                                  name="celular"
                                  placeholder="Digite o celular"
                                  erroMensagem={errors.celular}
                                  id={"celular"}
                                  onChange={handleChange}
                                  value={values.celular}
                                  noSize={true}
                                  required={true}
                                />
                              )}
                              <InputViewEdit
                                component={FormSelectInput}
                                label={"Mensagem padrão"}
                                onChange={(name, date) => {
                                  setFieldValue(name, date);
                                }}
                                required={false}
                                name={"mensagem"}
                                placeholder="Selecione a mensagem para o envio"
                                value={values.mensagem}
                                searchable={true}
                                labelKey={"nome"}
                                valueKey={"nome"}
                                returnFullObject={true}
                                parent={this.props.atendimento.cooperado.id}
                                service={mensagemService.findByCooperado}
                              />
                            </FormGroup>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="flex-grow px-1 m-auto justify-content-center">
                          <button
                            className="btn btn-secondary white mt-3 mb-4 mx-2"
                            type={"button"}
                            onClick={(e) => {
                              e.preventDefault();
                              this.toogleModalImprimirExames();
                            }}
                          >
                            Cancelar
                          </button>
                          <button
                            className="btn btn-primary white mt-3 mb-4 mx-2"
                            type={"submit"}
                          >
                            Enviar
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                );
              }}
            </Formik>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.showModalEvolucaoClinica}
          toggle={this.toogleModalEvolucaoClinica}
          backdrop="static"
          modalClassName=""
          className="modal-lg"
          centered={true}
        >
          <ModalBody className={""}>
            <div>
              <div className="row">
                <div className="col-11 text-center my-2">
                  <div className="iconeFaturamentoOFF"></div>
                  <h2 className="font-weight-bold">Evolução Clínica</h2>
                </div>
                <div className="col-1">
                  <a
                    onClick={() => {
                      this.toogleModalEvolucaoClinica();
                    }}
                    className="btn-fechar d-flex justify-content-end"
                  >
                    <img src={fechar} alt="fechar" className="pointer" />
                  </a>
                </div>
              </div>
              <br />
              {this.state.listaEvolucoes &&
                this.state.listaEvolucoes.length > 0 ? (
                this.state.listaEvolucoes.map((evolucao, index) => (
                  <div key={index}>
                    <div className="row">
                      <div className="col-3">
                        <p className="font-weight-bold">Código prescrição:</p>
                        <p>{evolucao.CODIGOPRESCRICAO}</p>
                      </div>
                      <div className="col-3">
                        <p className="font-weight-bold">Data prescrição:</p>
                        <p>{evolucao.DATAPRESCRICAO}</p>
                      </div>
                      <div className="col-4">
                        <p className="font-weight-bold">Nome prestador:</p>
                        <p>{evolucao.NOMEPRESTADOR}</p>
                      </div>
                      <div className="col-2">
                        <p className="font-weight-bold">Emergência:</p>
                        <p>{evolucao.SNEMERGENCIA === "S" ? "SIM" : "NÃO"}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-3">
                        <p className="font-weight-bold">Tipo:</p>
                        <p>{evolucao.DESCTIPO}</p>
                      </div>
                      <div className="col-3">
                        <p className="font-weight-bold">Atividade Médica:</p>
                        <p>{evolucao.ATIVIDADEMEDICA}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <p className="font-weight-bold">
                          Descrição da Evolução:
                        </p>
                        <p>
                          {evolucao.DESCRICAOEVOLUCAO != null &&
                            evolucao.DESCRICAOEVOLUCAO.split("\n").map(
                              (line, index) => (
                                <React.Fragment key={index}>
                                  {line}
                                  <br />
                                </React.Fragment>
                              )
                            )}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        {evolucao.listaProcedimentos != null && evolucao.listaProcedimentos.length > 0 ? (
                          <>
                            <p className="font-weight-bold">
                              Procedimentos:
                            </p>
                            <p>
                              <React.Fragment>
                                <table>
                                  <thead>
                                    <tr>
                                      <th style={{ padding: '10px' }}>Código AMB</th>
                                      <th style={{ padding: '10px' }}>Descrição</th>
                                      <th style={{ padding: '10px' }}>Quantidade</th>
                                      <th style={{ padding: '10px' }}>Código CBHPM</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {evolucao.listaProcedimentos != null &&
                                      evolucao.listaProcedimentos.map(
                                        (procedimento, index) => (
                                          <tr>
                                            <td style={{ padding: '10px' }}>{procedimento.codigo}</td>
                                            <td style={{ padding: '10px' }}>{procedimento.descricao}</td>
                                            <td style={{ padding: '10px' }} align="center">{procedimento.quantidade}</td>
                                            <td style={{ padding: '10px' }}>{procedimento.codigoCBHPM}</td>
                                          </tr>))}
                                  </tbody>
                                </table>
                              </React.Fragment>
                            </p>
                          </>
                        ) : null}
                      </div>
                    </div>
                    <br />
                    <br />
                  </div>
                ))
              ) : (
                <p className="text-center my-2">
                  Este atendimento não possui evolução clínica.
                </p>
              )}
              {this.state.listaEvolucoes &&
                this.state.listaEvolucoes.length > 0 && (
                  <div className="text-center">
                    <button
                      className="btn btn-primary white mt-3 mb-4 mx-2"
                      type={"button"}
                      onClick={(e) => {
                        e.preventDefault();
                        this.imprimirEvolucaoClinica();
                      }}
                    >
                      Imprimir
                    </button>
                  </div>
                )}
            </div>
          </ModalBody>
        </Modal>
        <ModalAnexosLote
          atendimento={this.props.atendimento}
          name="atendimentoAnexos"
          toogle={this.toggleAnexos}
          isOpen={this.state.openAnexo}
          target={"anexos"}
          itens={
            this.props.atendimento.atendimentoAnexos
              ? this.props.atendimento.atendimentoAnexos
              : []
          }
          onChange={(name, anexos) => {
            let atendimentoAnexos = [];
            for (let index = 0; index < anexos.length; index++) {
              if (!anexos[index].midia) {
                atendimentoAnexos.push({
                  midia: anexos[index],
                  criador: this.props.user,
                });
              } else {
                atendimentoAnexos.push(anexos[index]);
              }
            }

            this.props.onChange(name, atendimentoAnexos);
          }}
          viewMode={this.props.modeView}
          user={this.props.user}
          loading={this.props.loading}
          error={this.props.error}
        />


        <Modal
          isOpen={this.state.showModalFaturamentoParticular}
          toggle={this.faturamentoParticular}
          backdrop="static"
          centered={true}
        >
          <ModalBody>
            <Formik
              initialValues={this.state.initialValuesFaturamentoParticular}
              validationSchema={Yup.object().shape({
                formaPagamento: Yup.string().required("Obrigatorio"),
                valorAtendimento: Yup.string().required("Obrigatório"),
                recebedor: Yup.string().required("Obrigatório"),
              })}
              onSubmit={async (values, actions) => {
                try {
                  values.recebedor = values.recebedor.id;
                  values.cooperativaId = this.props.atendimento.cooperativa.id;
                  values.convenioId = this.props.atendimento.convenio.id;
                  values.atendimentoId = this.props.atendimento.id;
                  values.statusAtual = this.props.atendimento.status.nome;
                  await faturamentoParticularService.salvar(values);
                  this.setState({ toggleEditarFaturamentoParticular: true });
                  this.faturamentoParticular();
                  this.props.success({
                    message: `Dados ${values.id ? "alterados" : "inseridos"} com sucesso!`,
                  });
                } catch (error) {
                  this.props.error({
                    message: "Não foi possível inserir os dados, verifique os valores digitados.",
                  });
                } finally {
                  actions.setSubmitting(false);
                }
              }}
              validateOnBlur={true}
              validateOnChange={true}
              enableReinitialize={true}
              ref={(form) => {
                this.formRef = form;
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                setFieldValue,
                resetForm,

              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="modalCooperado">
                      <div className="">
                        <div className=" text-center my-2">
                          <img src={iconAddLote} alt="Icon" width="55" />
                          <h2 className="font-weight-bold">
                            Dados de pagamento particular:
                          </h2>
                        </div>
                      </div>
                      <div className="row">
                        <div className="flex-fill px-5 m-auto justify-content-center">
                          <FormGroup className="col-12">
                            <SelectInput
                              label={"Forma de pagamento:"}
                              name={"formaPagamento"}
                              value={values.formaPagamento}
                              options={this.state.formaPagamentoOptions}
                              onChange={(name, value) => {
                                setFieldValue("formaPagamento", value)
                              }}
                              labelKey={"nome"}
                              valueKey={"id"}
                              placeholder="Escolha uma forma de pagamento"
                              erroMensagem={touched.formaPagamento && errors.formaPagamento}
                              required={true}
                              searchable={false}
                              disabled={this.state.toggleEditarFaturamentoParticular}
                            />
                            {touched.formaPagamento && errors.formaPagamento && (
                              <div className="text-danger">{errors.formaPagamento}</div>
                            )}
                            <InputViewEdit
                              label="Valor Total do Atendimento:"
                              component={MoneyInput}
                              name={"valorAtendimento"}
                              searchable={true}
                              returnFullObject={true}
                              defaultValue={values.valorAtendimento}
                              value={values.valorAtendimento}
                              onChange={(name, value) => {
                                setFieldValue(name, value);
                              }}
                              apendRoot={true}
                              placeholder="R$ 0,00"
                              disabled={this.state.toggleEditarFaturamentoParticular}
                            />
                            {touched.valorAtendimento && errors.valorAtendimento && (
                              <div className="text-danger">{errors.valorAtendimento}</div>
                            )}
                            <SelectInput
                              label={"Responsável pelo recebimento do pagamento:"}
                              name={"recebedor"}
                              id={"recebedor"}
                              value={values.recebedor}
                              onFetchData={pessoaService.findByNome}
                              onChange={(name, value) => {
                                setFieldValue("recebedor", value);
                              }}
                              returnFullObject={true}
                              labelKey={"nome"}
                              valueKey={"id"}
                              required={true}
                              placeholder="Por favor, digite pelo menos 3 letras"
                              erroMensagem={touched.recebedor && errors.recebedor}
                              disabled={this.state.toggleEditarFaturamentoParticular}
                            />
                            {touched.recebedor && errors.recebedor && (
                              <div className="text-danger">{errors.recebedor}</div>
                            )}
                          </FormGroup>
                        </div>
                      </div>
                      <div className="row">
                        <div className="flex-grow px-1 m-auto justify-content-center">
                          <button
                            className="btn btn-secondary white mt-3 mb-4 mx-2"
                            type="button"
                            onClick={() => {
                              if (this.state.toggleEditarFaturamentoParticular) {
                                this.faturamentoParticular();
                              } else {
                                this.setState({ toggleEditarFaturamentoParticular: true });
                                resetForm();
                              }
                            }}
                          >
                            Cancelar
                          </button>
                          {!this.state.toggleEditarFaturamentoParticular && (
                            <button
                              className="btn btn-primary white mt-3 mb-4 mx-2"
                              type="submit"
                            >
                              Salvar
                            </button>
                          )}
                          {this.state.toggleEditarFaturamentoParticular && (
                            <button
                              className="btn btn-warning white mt-3 mb-4 mx-2"
                              type="button"
                              onClick={() => {
                                this.setState({ toggleEditarFaturamentoParticular: false })
                              }}
                            >
                              {values ? "Editar" : "Criar"}
                            </button>
                          )}
                        </div>
                      </div>

                    </div>
                  </form>
                );
              }}

            </Formik>
          </ModalBody>
        </Modal>

      </React.Fragment>
    );
  }
}

const mapDispatch = ({
  alert: { success, error, clear, warning },
  load: { loading },
  pendencia: { togglePendencia },
  edicaoEmMassa: { toggleEdicaoEmMassa, atendimentoParaEditar },
  selectableInput: { atendimentoAtual },
  notification: { addAllNotificacoes },
}) => ({
  success: (msg) => success(msg),
  loading: (load: boolean) => loading({ load }),
  error: (msg) => error(msg),
  togglePendencia: () => togglePendencia(),
  atendimentoAtual: (atendimento) => atendimentoAtual(atendimento),
  toggleEdicaoEmMassa: () => toggleEdicaoEmMassa(),
  addAllNotificacoes: (notifications) => addAllNotificacoes(notifications),
  atendimentoParaEditar: (atendimento) => atendimentoParaEditar(atendimento),
  warning: (msg) => warning(msg),
});

function mapStateToProps(state) {
  const { pendenciaOpen, idAtendimentoAtual } = state.pendencia;
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;
  const { filtroAtendimento } = state.filtro;
  const { edicaoEmMassaOpen, idAtendimentoParaEditar } = state.edicaoEmMassa;

  return {
    pendenciaOpen,
    idAtendimentoAtual,
    loggedIn,
    alert,
    user,
    permissions,
    filtroAtendimento,
    edicaoEmMassaOpen,
    idAtendimentoParaEditar,
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(GuiaAtendimentoForm));
