import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import DatePickerInput from "../../../../components/Inputs/DatePickerInput";
import FormInput from "../../../../components/Inputs/FormInput";
import FormSelectInput from "../../../../components/Inputs/FormSelectInput";
import InputViewEdit from "../../../../components/Inputs/InputViewEdit";
import SelectableInput from "../../../../sascComponents/inputs/SelectableInput";
import { procedimentoService } from "../../../../services/procedimento.service";
import MoneyInput from "../../../../components/Inputs/MoneyInput";
import SelectInput from "../../../../components/Inputs/SelectInput";
import { enumTipoAvisos, objectsConstants } from "../../../../constants/objects.constants";
import { dateHelper } from "../../../../helpers/date.helper";
import { atendimentoService } from "../../../../services/atendimento.service";
import { enumService } from "../../../../services/enum.service";
import _ from "lodash";
import { regraProcedimnetoBloqueadosGuiaHospitalService } from "../../../../services/RegrasProcedimentosBloqueadosGuiaHospital.service";

class DadosAtendimentoGuiaConsultaForm extends PureComponent<Props, State> {
  constructor(props) {
    super(props);
  }

  verificaRetorno = (name,value) => {

    this.props.verificaRetorno(`atendimentoExecucaos[0].tipoConsulta`,name,value)
  };

  verificaData = (values) =>{
    if(values.atendimentoExecucaos && values.atendimentoExecucaos[0] && values.atendimentoExecucaos[0].dataAtendimento){
      return values.atendimentoExecucaos[0].dataAtendimento 
    }else{
      return values.atendimentoExecucaos[0].dataAtendimento = new Date();
    }
  }

    getGuiaPadrao = (convenio,tipoGuia,codigo, values) => {
        const {setValues} = this.props;

        atendimentoService.findGuiaPadrao(tipoGuia, convenio,codigo).then(response => {
            const guiaPadrao = response.data;
            const newValue = _.cloneDeep(values);
            Object.keys(newValue).map(v => {
                if (newValue[v] === null) {
                    Object.keys(guiaPadrao).map(g => {
                        if (g === v && guiaPadrao[g] != null) {
                            newValue[v] = guiaPadrao[g];
                        }

                    })
                }
            })
            setValues(newValue);
        })
    }

    verificaBloqueioProcedimentoGuiaHospital = (codigo) =>{
      let codigoGuia;
      objectsConstants.TIPOS_GUIA.forEach(element => {
        if(this.props.values.type == element.label){
          codigoGuia = element.id;
        }
      });
      return regraProcedimnetoBloqueadosGuiaHospitalService.getRegraProcedimnetoBloqueadosGuiaHospital(this.props.values.convenio.id, 
        codigoGuia, this.props.values.hospitalLocal.id, codigo);
    }

  render() {
      if(this.props.atendimento) {
      }


      const {
      setFieldValue,
      values,
      viewMode,
        setValues,
      errors
    } = this.props;
    const {convenio} = values;

    if(values.atendimentoExecucaos==null||values.atendimentoExecucaos.length==0){
    _.set(values,`atendimentoExecucaos[0]`,
      {
        tabela: "22",
        //codigoProcedimento: "10101012",
        codigoProcedimento: values.atendimentoProcedimentos[0].codigoProcedimento,
        tipoConsulta: objectsConstants.TIPOS_CONSULTA[0],
        dataAtendimento: null,
        valorTotal: values.convenio.valorConsulta
      }
    )
    _.set(values,'indicacaoAcidente', objectsConstants.INDICACAO_ACIDENTE[0])
  }else if (values.atendimentoExecucaos && values.atendimentoExecucaos.length > 1) {
    values.atendimentoExecucaos.splice(1);
  }
  
    return (
      <div className="row section-form">
        <h6 className="w-100">Dados do Atendimento</h6>
        <SelectableInput viewMode={viewMode} atendimento={values} className="fixo mobile-100 d-none"
          name={"acomodacao"}
          label={"Acomodação"}
          value={values.acomodacao && values.acomodacao.description}
        >
          <InputViewEdit
            label="Acomodação"
            component={FormSelectInput}
            service={enumService.findAcomodacoes}
            className={"destaque"}
            name={"acomodacao"}
            defaultValue={
              values.acomodacao ? values.acomodacao.description : "--"
            }
            returnFullObject={true}
            searchable={true}
            labelKey={"description"}
            valueKey={"name"}
            value={values.acomodacao}
            viewMode={viewMode}
            onChange={(name, value) => {
              setFieldValue(name, value);
            }}
            disabled={true}
          />
        </SelectableInput>

        <SelectableInput viewMode={viewMode}
          atendimento={values}
          className="full"
          name={"indicacaoAcidente"}
          label={"Indicação de Acidente (acidente ou doença relacionada)"}
          value={values.indicacaoAcidente}
        >
          <InputViewEdit
            label="Indicação de Acidente (acidente ou doença relacionada)"
            placeholder=""
            erroMensagem={errors.indicacaoAcidente}
            component={FormSelectInput}
            service={atendimentoService.findIndicacaoAcidente}
            labelKey={"description"}
            valueKey={"name"}
            returnFullObject={true}
            defaultValue={
              values.indicacaoAcidente
                ? values.indicacaoAcidente.description
                : "--"
            }
            className={""}
            required={false}
            name={"indicacaoAcidente"}
            id={"indicacaoAcidente"}
            type={"text"}
            value={values.indicacaoAcidente}
            viewMode={viewMode}
            onChange={(name, value) => {
              setFieldValue(name, value);
            }}
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode}
          atendimento={values}
          className="fixo"
          name={"atendimentoExecucaos[0].tipoConsulta"}
          label={"Tipo de consulta"}
          value={
            values.atendimentoExecucaos[0].tipoConsulta &&
            values.atendimentoExecucaos[0].tipoConsulta.description
          }
        >
          <InputViewEdit
            label={`Tipo de consulta`}
            component={SelectInput}
            required={true}
            options={_.filter(objectsConstants.TIPOS_CONSULTA, (o) => {
                if (this.props.user.cooperativa.tipoConsultaNaoDisponiveis) {
                    for (var i = 0; i < this.props.user.cooperativa.tipoConsultaNaoDisponiveis.length; i++) {
                        if (this.props.user.cooperativa.tipoConsultaNaoDisponiveis[i].name == o.name) {
                            return false;
                        }
                    }
                }
                return true
            })
            }
            disabled={values.cooperativa.reconsultaAutomatica}
            className={"destaque"}
            name={"atendimentoExecucaos[0].tipoConsulta"}
            id={"tipoConsulta"}
            erroMensagem={
              errors && errors.tipoConsulta && errors.tipoConsulta.description
            }
            returnFullObject={true}
            searchable={true}
            defaultValue={
              values.atendimentoExecucaos[0].tipoConsulta &&
              values.atendimentoExecucaos[0].tipoConsulta.description
            }
            labelKey={"description"}
            valueKey={"name"}
            value={values.atendimentoExecucaos[0].tipoConsulta}
            viewMode={viewMode}
            onChange={(name, value) => {
              setFieldValue(name, value);
            }}
            onBlur={this.verificaRetorno}
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode} atendimento={values} className="fixo"
          name={`atendimentoExecucaos[0].dataAtendimento`}
          label={"Data atendimento"}
          value={
            values.atendimentoExecucaos[0] &&
            dateHelper.format(values.atendimentoExecucaos[0].dataAtendimento, {
              mode: "DATE"
            })
          }
        >
          <InputViewEdit
            label="Data atendimento"
            component={DatePickerInput}
            onChange={(name, date) => {
              setFieldValue(name, date);
              this.verificaRetorno(name,date);
            }}
            defaultValue={
              values.atendimentoExecucaos[0] &&
              values.atendimentoExecucaos[0].dataAtendimento != null
                ? dateHelper.format(
                  values.atendimentoExecucaos[0].dataAtendimento,
                  { mode: "DATE" }
                )
                : "--"
            }
            disabled={values.bloquearDataAtendimento}
            id={`atendimentoExecucaos0dataAtendimento`}
            mask={"99/99/9999"}
            name={`atendimentoExecucaos[0].dataAtendimento`}
            placeholder=""
            viewMode={viewMode}
            type={"text"}
            value={this.verificaData(values)}
            erroMensagem={
              errors &&
                errors.atendimentoExecucaos &&
                errors.atendimentoExecucaos.length > 0 &&
                errors.atendimentoExecucaos[0] &&
                errors.atendimentoExecucaos[0].dataAtendimento
                ? errors.atendimentoExecucaos[0].dataAtendimento
                : null
            }
            required={true}
            onBlur={this.verificaRetorno}
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode}
          atendimento={values}
          className="central fixo3"
          name={`atendimentoExecucaos[0].tabela`}
          label={"Tabela"}
          value={values.atendimentoExecucaos[0].tabela}
          id={values.id}
        >
          <InputViewEdit
            label={"Tabela"}
            required={true}
            component={FormInput}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            viewMode={viewMode}
            name={`atendimentoExecucaos[0].tabela`}
            id={`atendimentoExecucaos0tabela`}
            erroMensagem={
              errors &&
                errors.atendimentoExecucaos &&
                errors.atendimentoExecucaos.length > 0 &&
                errors.atendimentoExecucaos[0] &&
                errors.atendimentoExecucaos[0].tabela
                ? errors.atendimentoExecucaos[0].tabela
                : null
            }
            placeholder=""
            type={"text"}
            value={values.atendimentoExecucaos[0].tabela}
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode} atendimento={values}
          className=""
          name={`atendimentoExecucaos[0].codigoProcedimento`}
          label={"Código do procedimento"}
          value={values.atendimentoExecucaos[0].codigoProcedimento}
          id={values.id}
        >
          <InputViewEdit
            label={"Código do procedimento"}
            component={SelectInput}
            alertMensagem={values.convenio? values.convenio.listRegraMensagemTipoGuia : null}
            onChange={ async (name, date) => {
              if(values.hospitalLocal){
                await this.verificaBloqueioProcedimentoGuiaHospital(date.codigo).then((response) => {
                  if(response.data > 0){
                    this.props.warning({ message: enumTipoAvisos.PROCEDIMENTO_BLOQUEADO_GUIA_HOSPITAL });
                  }})
              }
                if(convenio != null && values.type != null && date.codigo != null){
                    let newValue = _.cloneDeep(values);
                    _.set(newValue,`atendimentoExecucaos[0].procedimento`,date);
                    _.set(newValue,`atendimentoExecucaos[0].codigoProcedimento`,date.codigo);
                    _.set(newValue,`atendimentoExecucaos[0].descricao`, date.descricao);
                    _.set(newValue,`identificacaoAtendimentoExecucao[0].grau`, null);
                    atendimentoService.findGuiaPadrao(values.type, convenio,date.codigo).then(response => {
                        const guiaPadrao = response.data;
                        _.forOwn(guiaPadrao, function(value, key) {
                            if(value) {
                                if(_.isArray(value)) {
                                    _.forOwn(value[0],function(valueArray, keyArray) {
                                        _.set(newValue,`${key}[0].${keyArray}`,valueArray);
                                    })
                                }else{
                                    if(!newValue[key]){
                                        _.set(newValue,key, value);
                                    }
                                }
                            }
                        });
                        setValues(newValue);
                    }).catch(() => {
                        setValues(newValue);
                    })
                }else {
                    setFieldValue(`atendimentoExecucaos[0].procedimento`, date);
                    setFieldValue(
                        `atendimentoExecucaos[0].codigoProcedimento`,
                        date.codigo
                    );
                    setFieldValue(
                        `atendimentoExecucaos[0].descricao`,
                        date.descricao
                    );

                }
              if (date.codigo === objectsConstants.CODIGO_PROCEDIMENTO_CONSULTA) {
                setFieldValue(`atendimentoExecucaos[0].valorTotal`, 100)
              }



            }}
            viewMode={viewMode}
            required={true}
            name={`atendimentoExecucaos[0].codigoProcedimento`}
            placeholder="Digite o código"
            type={"text"}
            value={{
              codigo: values.atendimentoExecucaos[0].codigoProcedimento
            }}
            defaultValue={values.atendimentoExecucaos[0].codigoProcedimento}
            id={`atendimentoExecucaos0codigoProcedimento`}
            erroMensagem={
              errors &&
                errors.atendimentoExecucaos &&
                errors.atendimentoExecucaos.length > 0 &&
                errors.atendimentoExecucaos[0] &&
                errors.atendimentoExecucaos[0].codigoProcedimento
                ? errors.atendimentoExecucaos[0].codigoProcedimento
                : null
            }
            labelKey={"codigo"}
            valueKey={"codigo"}
            returnFullObject={true}
            parent={{convenio:values.convenio,tipoGuia:values.type}}
            onFetchData={procedimentoService.findByCodigoConvenioTipoGuia}
            noResultsText={"Cooperativa não possui credenciamento para cobrança. Convênio possui locais próprios."}
          />
        </SelectableInput>
        <SelectableInput viewMode={viewMode}
          atendimento={values}
          className="fixo mobile-100"
          name={`atendimentoExecucaos[0].valorTotal`}
          label={"Valor do procedimento"}
          value={values.atendimentoExecucaos[0].valorTotal}
        >
          <InputViewEdit
            label="Valor do procedimento"
            component={MoneyInput}
            onChange={(name, date) => {
              setFieldValue(name, date);
            }}
            name={`atendimentoExecucaos[0].valorTotal`}
            placeholder="R$0,00"
            viewMode={viewMode}
            disabled={true}
            value={
              values.atendimentoExecucaos[0].valorTotal
            }
          />
        </SelectableInput>
      </div>
    );
  }
}

const mapDispatch = ({ menu: { showMenu, hideMenu } }) => ({
  showMenu: () => showMenu(),
  hideMenu: () => hideMenu()
});

function mapStateToProps(state) {
  const { loggedIn, user, permissions } = state.authentication;
  const { alert } = state;

  return {
    loggedIn,
    alert,
    user,
    permissions
  };
}

export default connect(
  mapStateToProps,
  mapDispatch
)(withRouter(DadosAtendimentoGuiaConsultaForm));
