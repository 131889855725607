import { defaultService } from "./defaultService";
import { urlsConstants } from "../constants/url.constant";

export const pessoaService = {
    findByNome,
    findNomeById
};


function findByNome(input) {
    return defaultService.doGet(`${urlsConstants.PESSOA}findByNome/${input}`);
}

function findNomeById(id) {
    return defaultService.doGet(`${urlsConstants.PESSOA}findNomeById/${id}`);
}
