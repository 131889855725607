import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormGroup } from "../../../components/FormGroup";
import DatePickerInput from "../../../components/Inputs/DatePickerInput";
import * as _ from "lodash";
import { dateHelper } from "../../../helpers/date.helper";
import InputViewEdit from "../../../components/Inputs/InputViewEdit";
import ConvenioSelectInput from "../../../sascComponents/convenios/ConvenioSelectInput";
import ConvenioFormSelect from "../../../sascComponents/convenios/ConvenioFormSelect";
import { convenioService } from "../../../services/convenio.service";
const moment = require('moment');

class FiltroMapaCirurgico extends PureComponent<Props, State> {
    constructor(props) {
        super(props);

    }

    render() {
        const { values, errors, setFieldValue, viewMode } = this.props;
        const dataLimiteInicio = moment().subtract(90, 'days').format('DD/MM/YYYY');
        const dataAtual = moment().format('DD/MM/YYYY');
        return (
            <React.Fragment>
                <div className={'d-flex flex-fill flex-row align-items-center justify-content-around borda-separacao'}>
                    <FormGroup className="col-3">
                        <InputViewEdit
                            label={"Data início"}
                            component={DatePickerInput}
                            onChange={(name, date) => {
                                setFieldValue(name, date);
                            }}
                            erroMensagem={
                                _.get(errors, `dataInicio`)
                            }
                            defaultValue={
                                values.dataInicio
                                    ? dateHelper.format(values.dataInicio)
                                    : dateHelper.format(dataLimiteInicio)
                            }
                            mask={"99/99/9999"}
                            name={"dataInicio"}
                            id={"dataInicio"}
                            placeholder={dataLimiteInicio}
                            required={false}
                            type={"text"}
                            viewMode={false}
                            value={values.dataInicio}
                        />
                    </FormGroup>
                    <FormGroup className="col-3">
                        <InputViewEdit
                            label={"Data fim"}
                            component={DatePickerInput}
                            onChange={(name, date) => {
                                setFieldValue(name, date);
                            }}
                            erroMensagem={
                                _.get(errors, `dataFim`)
                            }
                            defaultValue={
                                values.dataFim
                                    ? dateHelper.format(values.dataFim)
                                    : dateHelper.format(dataAtual)
                            }
                            mask={"99/99/9999"}
                            name={"dataFim"}
                            id={"dataFim"}
                            placeholder={dataAtual}
                            required={false}
                            type={"text"}
                            viewMode={false}
                            value={values.dataFim}
                        />
                    </FormGroup>
                    <FormGroup className="col-3">
                        <ConvenioSelectInput
                            label={"Convênios"}
                            name="convenios"
                            id="convenios"
                            bloquearConsultorio={false}
                            isMulti={true}
                            placeholder="Selecione os convênios"
                            onChange={(name, value) => {
                                setFieldValue(name, value);
                            }}
                            erroMensagem={errors.convenios}
                            returnFullObject={true}
                            value={values.convenios}
                            className="sasc-select"
                            disabled={viewMode}
                            required={true}
                        />
                    </FormGroup>
                </div>
            </React.Fragment>
        );
    }
}

const mapDispatch = ({ filtro: { toogleFiltro, removeFilter }, alert: { success, error }, load: { loading } }) => ({
    loading: (load: boolean) => loading({ load }),
    error: msg => error(msg),
    success: msg => success(msg),
    toogleFiltro: () => toogleFiltro(),
    removeFilter: (filterRemove) => removeFilter({ filterRemove })
});

function mapStateToProps(state) {
    const { showMenu } = state.filtro;
    const { permissions } = state.authentication;
    const { filterRemove } = state.filtro;

    return {
        showMenu,
        permissions,
        filterRemove
    };
}

export default connect(mapStateToProps, mapDispatch)(withRouter(FiltroMapaCirurgico));
