import React, {PureComponent} from 'react';
import _ from "lodash";
import {ToolTipFeedBack} from "../Utils/ToolTipFeedBack";
import {ModalAnexos} from "../Utils/ModalAnexos";
import { cooperativa, objectsConstants } from "../../constants/objects.constants";

export default class AtendimentoAnexos extends PureComponent<Props> {
    constructor() {
        super();
        this.state = {openAnexo:false};
    }
    toggleAnexos = () => {
            this.setState(({openAnexo}) => ({openAnexo: !openAnexo}));
    }; 

    render() {
        const {errors,values,user} = this.props;
        
        let bordaAnexo = "borda-input-anexo";
        let boldText = "";
        let textArquivosAnexados = "verde-destaque"
        if(user.cooperativa.sigla === cooperativa.COOPMEDRS && values.type == "guiaHonorarios" && !this.props.dataAntesImplTipoAnexo){
            bordaAnexo = "borda-input-anexo-coopmed";
            boldText = "font-weight-bold";
            textArquivosAnexados = "text-preto";
        }
        
        return (
            <div
                className={`bg-branco px-sm-3 d-flex flex-column align-items-center justify-content-center ${bordaAnexo}`}>
                <button
                    type="button"
                    className="btn btn-link"
                    onClick={this.toggleAnexos}
                    disabled={this.props.viewMode}
                >
                    <span className="icon-btn-anexar"></span>
                    <span className={`${boldText}`}>Anexar</span>
                </button>
                <div id="anexos" className="pointer text-center" onClick={this.toggleAnexos}>
                    <React.Fragment>
                        <div id={'guiaFisicaLabel'}
                             className={`font-10px ${textArquivosAnexados} ${errors.atendimentoAnexos ? 'cor-destaque-alerta' : ''} font-bold`}>
                            {_.size(values.atendimentoAnexos)} arquivo
                        </div>
                        {errors.atendimentoAnexos && (
                            <ToolTipFeedBack
                                target={'guiaFisicaLabel'}
                                messageValidate={errors.atendimentoAnexos}
                            />
                        )}
                        <div
                            className={`font-10px ${textArquivosAnexados} ${errors.atendimentoAnexos ? 'cor-destaque-alerta' : ''} `}>anexado
                        </div>
                    </React.Fragment>
                </div>
                <ModalAnexos
                    values={values}
                    ref={this.anexosInputRefCallback}
                    name="atendimentoAnexos"
                    toogle={this.toggleAnexos}
                    isOpen={this.state.openAnexo}
                    updateAnexos={() =>
                        this.props.atualizaAnexos(values)
                    }
                    target={"anexos"}
                    itens={
                        values.atendimentoAnexos
                            ? values.atendimentoAnexos
                            : []
                    }
                    onChange={(name, anexos)=>{
                        let atendimentoAnexos = [];
                        for (let index = 0; index < anexos.length; index++) {
                            if (!anexos[index].midia) {
                                atendimentoAnexos.push({
                                    midia: anexos[index],
                                    criador: this.props.user,
                                    tipoAnexo: []
                                });
                            } else {
                                atendimentoAnexos.push(anexos[index]);
                            }
                        }

                        this.props.onChange(name,atendimentoAnexos)}}
                    viewMode={this.props.viewModeCustom}
                    user={this.props.user}
                    guiaType={this.props.values.type}
                    dataAntesImplTipoAnexo={this.props.dataAntesImplTipoAnexo}
                />
            </div>)
    }
}
